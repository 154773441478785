<template>
  <div class="content text-left ma-auto mr-0">
    <div id="section1" class="Overview sectionContainer">
      <h1 class="section-title">Overview</h1>
      <hr class="line-break">
      <h5 class="sub-title">API design principles</h5>
      <p>All requests and responses, with exception of download and upload methods, are designed using JSON-pure
        principles.</p>
      <ul class="mb-4">
        <li>All character sets are UTF-8.</li>
        <li>All requests use HTTP POST.</li>
        <li>All requests have MIME type application/json, except document uploads, which have MIME type
          multipart/form-data in API v3 and application/pdf in API v4.
        </li>
        <li>All responses are JSON objects with MIME type application/json, except document downloads,
          which are binary streams with MIME type application/pdf.
        </li>
      </ul>
      <p>For example:</p>
      <v-card class="mb-5">
        <div class="card-body">
<pre><code>curl -X POST https://api.signhero.io/signatures \
 -H 'Content-Type: application/json' \
 -d '{
    "action_str": "retrieve",
    "data_type": "signature_flows",
    "trans_map": {
        "access_token": "YYWMtGeygaKCEeahbgX148SD2wAAAVipR17t2Xygd71iJDLWO"
    },
    "request_map": {
        "status": "pending"
    }
 }'
</code></pre>
        </div>
      </v-card>
      <h5 class="sub-title">API versioning</h5>
      <p>Currently, two versions of the API are available: <i>v3.0.0</i> and <i>v4.0.0</i>. To specify which
        version you want to use, put respective major version number in endpoint path:</p>
      <v-card class="mb-4">
        <div class="card-body">
<pre><code>curl -X POST https://api.signhero.io/3/signatures  # POST to the API v3 signatures endpoint
 curl -X POST https://api.signhero.io/4/documents  # POST to the API v4 documents endpoint
</code></pre>
        </div>
      </v-card>
      <p>When you don't specify an API version in the path, the default version (<i>v3</i>) is used: </p>
      <v-card>
        <div class="card-body">
<pre><code>curl -X POST https://api.signhero.io/3/signatures
 curl -X POST https://api.signhero.io/signatures  # The two lines refer to the same API version
</code></pre>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
    export default {
        name : "Overview",
        data()
        {
            return {
                drawer : true
            }
        }
    }
</script>

<style lang="sass" scoped>

</style>
