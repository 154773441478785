<template>
  <v-container fluid class="fill-height pa-0">
    <div class="content text-left ma-auto mr-0">
      <div id="section4" class="ClientCredentials sectionContainer">
        <h1 class="section-title">Request an access token</h1>
        <div class="version-switch d-flex mt-4">
          <span>v3</span>
          <v-switch v-model="isV4" class="mx-2"></v-switch>
          <span>v4</span>
        </div>
        <hr class="line-break">
        <v-alert class="api-info">
          <span>
            In client credentials grant use your email as client_id and your password as client_secret.
          </span>
        </v-alert>
        <p>To obtain an access token, present your credentials at the authentication endpoint:</p>
        <v-card class="mb-4">
          <div class="card-body">
<pre><code>POST <span v-if="isV4">{{ versionDifferenceSlash4 }}</span>/auth HTTP/1.1
 Host: api.signhero.io
 Content-Type: application/json

 {
     "action_str": "create",
     "data_type": "access_token",
     "request_map": {
         "client_id": "signhero.tester@signhero.io",
         "client_secret": "1498b03d760b233673953a11268bb486ab29"
     }
 }
</code></pre>
          </div>
        </v-card>
        <v-card class="mb-4">
          <div class="card-body">
<pre><code>{
    "action_str": "created",
    "data_type": "access_token",
    "log_list": [
        {
            "code_key": <span v-if="isV4">{{ versionDifference200 }}</span><span v-if="!isV4">{{ versionDifferenceQuotationMark200 }}</span>,
            "code_str": "ok",
            "user_msg": "access_token created",
            "level_int": 1,
            "level_str": "info"
        }
    ],
    "trans_map": {
        "<span v-if="!isV4">{{ versionDifferenceAuthKey }}</span><span v-if="isV4">{{ versionDifferenceAccessToken }}</span>": "YWMtGeygaKCEeahbgX148SD2wAAAVipR17t2Xygd71iJDLWO"
    }
}
</code></pre>
          </div>
        </v-card>
        <p>Access tokens expire in 7 days. To make SignHero API calls, add the obtained access token to the
          <b><span v-if="isV4">{{ versionDifferenceAccessToken }}</span><span v-if="!isV4">{{ versionDifferenceAuthKey }}</span></b>
          field in the request:</p>
        <v-card>
          <div class="card-body">
<pre><code>POST <span v-if="isV4">{{ versionDifferenceSlash4 }}</span>/signatures HTTP/1.1
 Host: api.signhero.io
 Content-Type: application/json

 {
     "action_str": "retrieve",
     "data_type": "signature_flow",
     "trans_map": {
         "<span v-if="isV4">{{ versionDifferenceAccessToken }}</span>": "YWMtGeygaKCEeahbgX148SD2wAAAVipR17t2Xygd71iJDLWO"
     },
     "request_map": {
         "process_id": "ae3b5221-0272-11e7-a154-46787cfede40"
     }
 }
</code></pre>
          </div>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
    export default {
        name : "RequestAnAccessToken",
        data()
        {
            return {
                isV4 : true,
                versionDifferenceSlash4 : '/4',
                versionDifference200 : '200',
                versionDifferenceQuotationMark200 : '"200"',
                versionDifferenceAuthKey : 'auth_key',
                versionDifferenceAccessToken : 'access_token',
            }
        }
    }
</script>

<style lang="sass" scoped>

</style>
