import Vue from "vue";
import i18n from "../../i18n/i18n";
import vuetify from '../../plugins/vuetify';
import "../../plugins/vuetify-dialog-promise";
import "../../plugins/cloudcore-vue-widgets";
import App from "./App";
import "@fortawesome/fontawesome-free/css/all.css";

Vue.config.productionTip = false;

new Vue( {
    el : "#app",
    render : h => h( App ),
    vuetify,
    i18n
} ).$mount( "#app" );
