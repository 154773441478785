<template>
  <div class="content text-left ma-auto mr-0">
    <div id="section7" class="Authentication sectionContainer ">
      <h1 class="section-title">Create an application</h1>
      <hr class="line-break">
      <p>Before using authorisation code grant, you need to create an OAuth2 application.</p>
      <p>Log into SignHero portal, open the side menu and select <b>Integrations</b>.</p>
      <div class="screenshotContainer">
        <img class="img-fluid" src="/api-document-images/createApp1.png">
      </div>
      <p>In the integrations list, select <b>Add application</b>.</p>
      <div class="screenshotContainer">
        <img class="img-fluid" src="/api-document-images/createApp2.png">
      </div>
      <p>
        Fill in application name and optionally description both of which will be present on the user consent
        page. If you choose to provide a description, explain what your application does and how it is going to
        use the access granted by the user.
      </p>
      <div class="screenshotContainer">
        <img class="img-fluid" src="/api-document-images/createApp3.png">
      </div>
      <p>
        In the following section provide your servers's domain name and at least one redirect URI to which users
        will be redirected after giving consent. Authorisation code will be added as a query parameter in the
        redirect URL.
      </p>
      <div class="screenshotContainer">
        <img class="img-fluid" src="/api-document-images/createApp4.png">
      </div>
      <v-alert class="api-info">
        <span>
          It won't be possible to change your application's domain name after creating the app. To use a different domain name you will have to create a new app.
        </span>
      </v-alert>
      <p>After creating an application you'll see it in the integrations list. The status of the app will be
        <b>Inactive</b>.</p>
      <div class="screenshotContainer">
        <img class="img-fluid" src="/api-document-images/createApp5.png">
      </div>
      <p>
        <b>Inactive</b> means that you haven't yet verified the ownership of app's domain. Select your app
        in the list to proceed.</p>
      <p>
        On the bottom of the application page you will find app's client ID and secret. This is the moment when
        you should copy the secret and safely store it somewhere. After refreshing the page, the secret won't be
        visible anymore. If you lost your secret, click on <b>Change secret</b> to generate a new one.
      </p>
      <div class="screenshotContainer">
        <img class="img-fluid" src="/api-document-images/createApp6.png">
      </div>
      <p>
        In the <b>Manage redirect URI</b> section you should see your domain name with a warning sign, which means
        that your domain is not verified. Below the domain name text field you will see instructions on how to
        verify the domain.
      </p>
      <div class="screenshotContainer">
        <img class="img-fluid" src="/api-document-images/createApp7.png">
      </div>
      <p>
        After you've successfully verified your domain, the waring will disappear. In the integrations list, your
        application will become <b>Active</b>
      </p>
      <div class="screenshotContainer">
        <img class="img-fluid" src="/api-document-images/createApp8.png">
      </div>
      <p>
        Congratulations! Now your OAuth2 application can be used to create access tokens using authorisation code
        grant.
      </p>
    </div>
  </div>
</template>

<script>
    export default {
        name : "CreateAnApplication"
    }
</script>

<style lang="sass" scoped>
  @import "../../../styles/style"
</style>
