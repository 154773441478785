/**
 * A utility class for rendering advanced signature images.
 */
export default class SignatureImageRenderer
{
    constructor( /* Object */ props )
    {
        this._state = {
            /**
             * Fonts.
             *
             * @public Object
             */
            FONTS : {
                "name" : {
                    name : "LiberationSans",
                    size : 12,
                    color : "#ff507a"
                },
                "email" : {
                    name : "LiberationSans",
                    size : 10,
                    color : "#666666"
                },
                "timestamp" : {
                    name : "LiberationSans",
                    size : 10,
                    color : "#333333"
                },
                "signatureMethod" : {
                    name : "LiberationSans",
                    size : 10,
                    color : "#333333"
                },
                "signatureLevel" : {
                    name : "LiberationSans",
                    size : 10,
                    color : "#333333"
                }
            },
            /**
             * X-origin of the properties.
             *
             * @public int
             */
            x : 0,
            /**
             * Y-origin of the properties.
             *
             * @public int
             */
            y : 0,
            /**
             * Maximum width of the properties. When not set, defaults to canvas width.
             *
             * @public int
             */
            maxWidth : 0,
            /**
             * Font-size multiplier.
             *
             * @public int
             */
            scale : 1,
            /**
             * Value by which the image is scaled.
             *
             * @public number
             */
            zoom : 1,
            /**
             * Text align within canvas.
             *
             * @public string
             */
            textAlign : "left",
        }
        Object.assign( this._state, props );
    }

    /**
     * Render a signature image on given canvas with provided properties. Returns the actual dimensions
     * of the properties.
     *
     * @param canvas {canvas}
     * @param properties
     * @returns {Object} - {
     *     width: int - Actual width of the signature,
     *     height: int - Actual height of the signature
     * }
     */
    renderSignatureImage( canvas, properties )
    {
        this._context = canvas.getContext( "2d" );
        this._context.save();
        this._context.scale( this._state.zoom, this._state.zoom );
        this._context.textAlign = this._state.textAlign;
        if( this._state.maxWidth === 0 )
        {
            this._state.maxWidth = canvas.width;
        }
        let maxLineWidth = 0;
        //Fill background
        if( properties.shouldFillBackground )
        {
            this._context.fillStyle = "white";
            this._context.fillRect( 0, 0, canvas.width, canvas.height );
        }
        //Draw name
        properties.name = properties.name.trim();
        const nameFontSize = this._setFont( "name" );
        const nameTop = this._state.y + nameFontSize - 1;
        let currentChar = 0;
        let nameLinesCount = 0;
        while( nameLinesCount < 3 && currentChar < properties.name.length )
        {
            let line = "";
            while( this._context.measureText( line + properties.name[ currentChar ] ).width
                   < this._state.maxWidth
                   && currentChar
                   < properties.name.length )
            {
                line += properties.name[ currentChar ];
                currentChar++;
            }
            let lineWidth = this._context.measureText( line ).width;
            maxLineWidth = Math.max( maxLineWidth, lineWidth );
            this._context.fillText( line, this._state.x, nameTop + nameLinesCount * nameFontSize );
            nameLinesCount++;
        }
        //Draw timestamp
        const timestampFontSize = this._setFont( "timestamp" );
        const timestampTop = nameTop + nameFontSize + timestampFontSize / 3;
        const timestampLineWidth = this._context.measureText( properties.timestamp ).width;
        maxLineWidth = Math.max( maxLineWidth, timestampLineWidth );
        this._context.fillText( properties.timestamp, this._state.x, timestampTop );
        //Draw signature method
        const signatureMethodFontSize = this._setFont( "signatureMethod" );
        const signatureMethodTop = timestampTop + timestampFontSize + signatureMethodFontSize / 3;
        const signatureMethodLineWidth = this._context.measureText( properties.signatureMethod ).width;
        maxLineWidth = Math.max( maxLineWidth, signatureMethodLineWidth );
        this._context.fillText( properties.signatureMethod, this._state.x, signatureMethodTop );
        //Draw signature level
        const signatureLevelFontSize = this._setFont( "signatureLevel" );
        const signatureLevelTop = signatureMethodTop + signatureMethodFontSize + signatureLevelFontSize / 3;
        const signatureLevelLineWidth = this._context.measureText( properties.signatureLevel ).width;
        maxLineWidth = Math.max( maxLineWidth, signatureLevelLineWidth );
        this._context.fillText( properties.signatureLevel, this._state.x, signatureLevelTop );
        this._context.restore();
        return {
            // width : maxLineWidth * 1.15,
            // height : ( signatureLevelTop + signatureLevelFontSize - this._state.y ) * 1.05
            width : maxLineWidth * 1.3,
            height : ( signatureLevelTop + signatureLevelFontSize - this._state.y ) * 1.3
        };
    }

    /**
     * Sets a font specified by key. Returns font size.
     *
     * @param key {string}
     * @private
     * @returns {number}
     */
    _setFont( key )
    {
        const font = this._state.FONTS[ key ];
        this._context.fillStyle = font.color;
        const fontSize = font.size * this._state.scale;
        this._context.font = font.size * this._state.scale + "px " + font.name;
        return fontSize;
    }
};