<template>
  <div class="content text-left ma-auto mr-0">
    <div id="section11" class="RevokeTokens sectionContainer">
      <h1 class="section-title">Revoke tokens</h1>
      <div class="d-flex version-switch mt-4">
        <span>v3</span>
        <v-switch v-model="isV4" class="mx-2"></v-switch>
        <span>v4</span>
      </div>
      <hr class="line-break">
      <p>To revoke a token, send the following request to the token endpoint:</p>
      <v-card>
        <div class="card-body mb-4">
<pre><code>POST <span v-if="isV4">{{ versionDifferenceSlash4 }}</span>/token HTTP/1.1
 Host: api.signhero.io
 Content-Type: application/json

 {
     "action_str": "delete",
     "data_type": "token",
     "request_map": {
         "client_id": "5e5b2260-d3a7-11e8-a608-92030a9483f4",
         "client_secret": "qSHofvOzKlAI7kYCNW3CmleZxmYF5nUiG1RZayDJsM",
         "token": "7gzokMZKMbcJtJIe2XULggUAIiEFDIRJuHwFU8cGE"
     }
 }
</code></pre>
        </div>
      </v-card>
      <p>The parameters in the request map:</p>
      <v-simple-table light class="mb-4">
        <template v-slot:default>
          <tbody>
          <tr>
            <td>client_id</td>
            <td>Your application's client identifier.</td>
          </tr>
          <tr>
            <td>client_secret</td>
            <td>Your application's client secret.</td>
          </tr>
          <tr>
            <td>token</td>
            <td>The access or refresh token your applications wants to revoke.</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-card>
        <div class="card-body mb-4">
<pre><code>{
    "action_str": "deleted",
    "data_type": "token",
    "log_list": [
        {
            "code_key": <span v-if="!isV4">{{ versionDifferenceQuotationMark200 }}</span><span v-if="isV4">{{ versionDifference200 }}</span>,
            "code_str": "ok",
            "user_msg": "token deleted",
            "level_int": 1,
            "level_str": "info"
        }
    ],
    "response_map": {},
    "trans_map": {}
}
</code></pre>
        </div>
      </v-card>
      <v-alert class="api-info">
        <span>
          Your application must be prepared to handle unexpected token invalidation. Users may revoke authorisation
        grants, or SignHero server may invalidate tokens in order to mitigate security threats.
        </span>
      </v-alert>
    </div>
  </div>
</template>

<script>
    export default {
        name : "AuthorisationCodeRevokeTokens",
        data()
        {
            return {
                isV4 : true,
                versionDifferenceSlash4 : '/4',
                versionDifference200 : '200',
                versionDifferenceQuotationMark200 : '"200"',
            }
        }
    }
</script>

<style scoped>

</style>
