const uuidRegExp = "this space left blank";
export default {
    "interface_version" : "2.0.0",
    "retrieve" : {
        "audit_trail" : {
            "handler" : "route",
            "requestMapSchema" : {
                "type" : "Object",
                "properties" : {
                    "processUuid" : { "type" : "string", "pattern" : uuidRegExp }
                },
                "additionalProperties" : false,
                "required" : [ "processUuid" ]
            }
        }
    }
};