<template>
  <div class="content text-left ma-auto mr-0">
    <div id="section12" class="ErrorResponses sectionContainer">
      <h1 class="section-title">Error responses</h1>
      <div class="d-flex version-switch mt-4">
        <span>v3</span>
        <v-switch v-model="isV4" class="mx-2"></v-switch>
        <span>v4</span>
      </div>
      <hr class="line-break">
      <p>On error, the server returns the <b>code_key</b> value 400 (bad_request) and one of the following
        errors:</p>
      <v-simple-table light class="mb-4">
        <template v-slot:default>
          <tbody>
          <tr>
            <td>access_denied</td>
            <td>The user or SignHero server denied the request.</td>
          </tr>
          <tr>
            <td>invalid_request</td>
            <td>The request is missing a required parameter, includes an
              unsupported parameter value or is otherwise malformed.
            </td>
          </tr>
          <tr>
            <td>invalid_client</td>
            <td>Client authentication failed.</td>
          </tr>
          <tr>
            <td>invalid_grant</td>
            <td>The provided authorisation grant or token is invalid, expired or revoked.</td>
          </tr>
          <tr>
            <td>unauthorized_client</td>
            <td>The authenticated client is not authorised to use this authorisation grant type.</td>
          </tr>
          <tr>
            <td>server_error</td>
            <td>The server encountered an unexpected condition which prevented it from fulfilling the request.</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-card>
        <div class="card-body">
<pre><code>{
    "action_str": "create_fail",
    "data_type": "access_token",
    "log_list": [
        {
            "code_key": <span v-if="!isV4">{{ versionDifferenceQuotationMark400 }}</span><span v-if="isV4">{{ versionDifference400 }}</span>,
            "code_str": "bad_request",
            "error": "invalid_client",
            "user_msg": "Invalid client id or secret",
            "level_int": 3,
            "level_str": "error"
        }
    ],
    "response_map": {},
    "trans_map": {}
}
</code></pre>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
    export default {
        name : "ErrorResponses",
        data()
        {
            return {
                isV4 : true,
                versionDifference400 : '400',
                versionDifferenceQuotationMark400 : '"400"',
            }
        }
    }
</script>

<style scoped>

</style>
