/**
 * Service schema for write (signed-in) client. From cloudcore-boilerplate/lib/interface/signed-in-service-mapper.
 *
 * @module
 */
const uuidRegExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-4][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
const hyperlinkRegExp = /^((?!((([A-Za-z]{3,9}:(?:\/){2,})[A-Za-z0-9.-]+[A-Za-z0-9.-]+))).)*$/gi;

export default {
    interface_version : "0.1.0",
    create : {
        activation_reminder : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp },
                    email : { type : "string", format : "email" }
                },
                required : [ "group_id", "email" ],
                additionalProperties : false
            },
            scope : "administer",
            handler : "route"
        },
        user : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp },
                    payload : {
                        type : "object",
                        properties : {
                            email : {
                                type : "string",
                                format : "email"
                            },
                            name : {
                                type : "string"
                            },
                            is_admin : {
                                type : "boolean"
                            },
                            is_subscription_admin : {
                                type : "boolean"
                            },
                            locale : {
                                type : "string",
                            }
                        },
                        required : [ "email", "name", "is_admin" ],
                        additionalProperties : false
                    }
                },
                required : [ "group_id", "payload" ],
                additionalProperties : false
            },
            scope : "administer",
            handler : "route"
        },
        payment_method : {
            requestMapSchema : {
                type : "object",
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp },
                    method_id : { type : "string" },
                    tax_type : { type : "string" },
                    tax_id : { type : "string" },
                    address : {
                        type : "object",
                        properties : {
                            country : { type : "string" },
                            postal_code : { type : "string" },
                            city : { type : "string" },
                            line1 : { type : "string" },
                            line2 : { type : "string" },
                            state : { type : "string" }
                        }
                    }
                },
                required : [ "method_id", "group_id", "tax_type", "tax_id", "address" ],
                additionalProperties : false
            },
            scope : "subadminister",
            handler : "route"
        },
        card : {
            requestMapSchema : {
                type : "object",
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp },
                    method_id : { type : "string" },
                    card_name : { type : "string" }
                },
                required : [ "method_id", "group_id" ],
                additionalProperties : false
            },
            scope : "subadminister",
            handler : "route"
        },
        merge_request : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp },
                    email : { type : "string", "format" : "email" },
                    password : { type : "string" }
                },
                required : [ "group_id", "email", "password" ],
                additionalProperties : false
            },
            scope : "administer",
            handler : "route"
        },
        google_access_token : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp },
                    code : { type : "string" }
                },
                required : [ "group_id", "code" ],
                additionalProperties : false
            },
            scope : "access",
            handler : "route"
        },
        payment_intent : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp },
                    amount : { type : "number" },
                    top_up_method : { type : "string", enum : [ "bank_transfer", "online_payment" ] }
                },
                required : [ "group_id", "amount" ],
                additionalProperties : false
            },
            scope : "access",
            handler : "route"
        }
    },
    retrieve : {
        contract : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp }
                },
                required : [ "group_id" ],
                additionalProperties : false
            },
            scope : "access",
            handler : "route"
        },
        users : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp }
                },
                required : [ "group_id" ],
                additionalProperties : false
            },
            scope : "access",
            handler : "route"
        },
        user : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp },
                    username : { type : "string" }
                },
                required : [ "group_id", "username" ],
                additionalProperties : false
            },
            scope : "access",
            handler : "route"
        },
        admins : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp }
                },
                required : [ "group_id" ],
                additionalProperties : false
            },
            scope : "access",
            handler : "route"
        },
        subscription_admins : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp }
                },
                required : [ "group_id" ],
                additionalProperties : false
            },
            scope : "access",
            handler : "route"
        },
        subscription : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp },
                    group_name : { type : "string" }
                },
                required : [ "group_id" ],
                additionalProperties : false
            },
            scope : "access",
            handler : "route"
        },
        payment_method : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string" }
                },
                required : [ "group_id" ],
                additionalProperties : false
            },
            scope : "subadminister",
            handler : "route"
        },
        billing_history : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp },
                    cursor : { type : "string" }
                },
                required : [ "group_id", "cursor" ],
                additionalProperties : false
            },
            scope : "subadminister",
            handler : "route"
        },
        appAccesses : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp }
                },
                required : [ "group_id" ],
                additionalProperties : false
            },
            scope : "access",
            handler : "route"
        },
        upcoming_invoice : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp }
                },
                required : [ "group_id" ],
                additionalProperties : false
            },
            scope : "subadminister",
            handler : "route"
        },
        merge_requests : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp }
                },
                required : [ "group_id" ],
                additionalProperties : false
            },
            scope : "administer",
            handler : "route"
        },
        google_access_token : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp }
                },
                required : [ "group_id" ],
                additionalProperties : false
            },
            scope : "access",
            handler : "route"
        },
        wallet : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp }
                },
                required : [ "group_id" ],
                additionalProperties : false
            },
            scope : "access",
            handler : "route"
        },
        wallet_events : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp },
                    eventProperties : {
                        type : "object",
                        properties : {
                            limit : { type : "integer", minimum : 1, maximum : 100 },
                            paginationFrom : { type : "integer", minimum: 0 },
                            from : { type : "integer", minimum : 0 },
                            to : { type : "integer", minimum : 0 },
                            transaction_search : { type : "string" },
                            incoming : { type : "boolean", enum : [ true, false ] },
                            outgoing : { type : "boolean", enum : [ true, false ] }
                        },
                        additionalProperties : false
                    }
                },
                required : [ "group_id" ],
                additionalProperties : false
            },
            scope : "access",
            handler : "route"
        },
        wallet_invoice : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp },
                    event_id : { type : "string", pattern : uuidRegExp },
                },
                required : [ "group_id", "event_id" ],
                additionalProperties : false
            },
            scope : "access",
            handler : "route"
        },
        incomplete_payments : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp }
                },
                required : [ "group_id" ],
                additionalProperties : false
            },
            scope : "access",
            handler : "route"
        },
        available_cash_balance : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp }
                },
                required : [ "group_id" ],
                additionalProperties : false
            },
            scope : "access",
            handler : "route"
        }
    },
    update : {
        contract : {
            requestMapSchema : {
                properties : {
                    group_id : {
                        type : "string",
                        pattern : uuidRegExp
                    },
                    title : {
                        type : "string",
                        pattern : hyperlinkRegExp
                    }
                },
                required : [ "group_id", "title" ],
                additionalProperties : false
            },
            scope : "administer",
            handler : "route"
        },
        org_signature_types : {
            requestMapSchema : {
                properties : {
                    group_id : {
                        type : "string",
                        pattern : uuidRegExp
                    },
                    org_signature_types : { type : "array", items: { type: "string" } }
                },
                required : [ "group_id", "org_signature_types" ],
                additionalProperties : false
            },
            scope : "administer",
            handler : "route"
        },
        profile : {
            requestMapSchema : {
                properties : {
                    group_id : {
                        type : "string",
                        pattern : uuidRegExp
                    },
                    name : {
                        type : "string",
                        pattern : hyperlinkRegExp
                    }
                },
                required : [ "group_id", "name" ],
                additionalProperties : false
            },
            scope : "access",
            handler : "route"
        },
        user_admin : {
            requestMapSchema : {
                properties : {
                    group_id : {
                        type : "string",
                        pattern : uuidRegExp
                    },
                    email : {
                        type : "string",
                        format : "email"
                    },
                    is_admin : {
                        type : "boolean"
                    }
                },
                required : [ "group_id", "email", "is_admin" ],
                additionalProperties : false
            },
            scope : "administer",
            handler : "route"
        },
        user_subscription_admin : {
            requestMapSchema : {
                properties : {
                    group_id : {
                        type : "string",
                        pattern : uuidRegExp
                    },
                    email : {
                        type : "string",
                        format : "email"
                    },
                    is_subscription_admin : {
                        type : "boolean"
                    }
                },
                required : [ "group_id", "email", "is_subscription_admin" ],
                additionalProperties : false
            },
            scope : "administer",
            handler : "route"
        },
        user_disabled : {
            requestMapSchema : {
                properties : {
                    group_id : {
                        type : "string",
                        pattern : uuidRegExp
                    },
                    email : {
                        type : "string",
                        format : "email"
                    },
                    disabled : {
                        type : "boolean"
                    }
                },
                required : [ "group_id", "email" ],
                additionalProperties : false
            },
            scope : "administer",
            handler : "route"
        },
        billing_information : {
            requestMapSchema : {
                type : "object",
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp },
                    billInfo : {
                        type : "object",
                        properties : {
                            email : { type : "string" },
                            name : { type : "string" },
                            tax_object_id : { type : "string" },
                            tax_type : { type : "string" },
                            tax_id : { type : "string" },
                            old_tax_id : { type : "string" },
                            old_tax_type : { type : "string" },
                            preferred_locales : { type : "string" }
                        }
                    },
                    address : {
                        type : "object",
                        properties : {
                            country : { type : "string" },
                            postal_code : { type : "string" },
                            city : { type : "string" },
                            line1 : { type : "string" },
                            line2 : { type : "string" },
                            state : { type : "string" },
                        }
                    }
                },
                required : [ "group_id", "billInfo", "address" ],
                additionalProperties : false
            },
            scope : "subadminister",
            handler : "route"
        },
        subscription_plan : {
            requestMapSchema : {
                properties : {
                    group_id : {
                        type : "string",
                        pattern : uuidRegExp
                    },
                    active : {
                        type : "boolean"
                    }
                },
                required : [ "group_id", "active" ],
                additionalProperties : false
            },
            scope : "subadminister",
            handler : "route"
        }
    },
    delete : {
        group_by_user : {
            requestMapSchema : {
                properties : {
                    group_id : {
                        type : "string",
                        pattern : uuidRegExp
                    },
                    password : { type : "string" }
                },
                required : [ "group_id", "password" ],
                additionalProperties : false
            },
            scope : "administer",
            handler : "route"
        },
        payment_method : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string" },
                    method_ids : { type : "array", items: { type: "string" } },
                    tax_object_id : { type : "string" }
                },
                required : [ "group_id", "tax_object_id" ],
                additionalProperties : false
            },
            scope : "subadminister",
            handler : "route"
        },
        card : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string" },
                    method_ids : { type : "array",items: { type: "string" } },
                },
                required : [ "group_id", "method_ids" ],
                additionalProperties : false
            },
            scope : "subadminister",
            handler : "route"
        },
        appAccess : {
            requestMapSchema : {
                properties : {
                    group_id : {
                        type : "string",
                        pattern : uuidRegExp
                    },
                    token_id : {
                        type : "string"
                    }
                },
                required : [ "group_id", "token_id" ],
                additionalProperties : false
            },
            scope : "access",
            handler : "route"
        },
        own_merge_request : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp },
                    email : { type : "string", "format" : "email" }
                },
                required : [ "group_id", "email" ],
                additionalProperties : false
            },
            scope : "administer",
            handler : "route"
        },
        merge_request : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp },
                    requested_group_id : { type : "string", pattern : uuidRegExp }
                },
                required : [ "group_id", "requested_group_id" ],
                additionalProperties : false
            },
            scope : "administer",
            handler : "route"
        },
        access_tokens : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp },
                    revoke_all : { type : "boolean" }
                },
                required : [ "group_id" ],
                additionalProperties : false
            },
            scope : "access",
            handler : "route"
        },
        payment_intent : {
            requestMapSchema : {
                properties : {
                    group_id : { type : "string", pattern : uuidRegExp },
                    payment_intent_id : { type : "string" }
                },
                required : [ "group_id", "payment_intent_id" ],
                additionalProperties : false
            },
            scope : "access",
            handler : "route"
        }
    }
};
