<template>
  <div class="content text-left ma-auto mr-0">
    <div id="section14" class="SearchUnattachedDocuments sectionContainer">
      <h1 class="section-title">Search unattached documents</h1>
      <p class="my-4"><b>This method is only available in API v4.</b></p>
      <p><b>Scope:</b><i> basic</i></p>
      <hr class="line-break">
      <p>To retrieve documents, which were upload to SignHero but have not beet attached to a signing process yet,
        send a request to the <i>documents</i> endpoint:</p>
      <v-card>
        <div class="card-body mb-4">
<pre><code>POST /4/documents HTTP/1.1
Host: api.signhero.io
Content-Type: application/json

{
    "action_str": "retrieve",
    "data_type": "unattached_documents",
    "trans_map": {
        "access_token": "YYWMtGeygaKCEeahbgX148SD2wAAAVipR17t2Xygd71iJDLWO"
    },
    "request_map": {
        "scope": "group"
    }
}
</code></pre>
        </div>
      </v-card>
      <p>The parameters in the request map:</p>
      <v-simple-table light>
        <template v-slot:default>
          <tbody>
          <tr>
            <td>scope</td>
            <td>Constrain search results to only documents uploaded by the user. Accepted
              values are <b>user</b> and <b>group</b>. The user has to have admin rights to use the
              scope <b>group</b>. Defaults to <b>user</b>.
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <p class="mt-4">The successful response includes identifiers, names and creation date of documents:</p>
      <v-card>
        <div class="card-body">
<pre><code>{
    "action_str": "retrieved",
    "data_type": "unattached_documents",
    "log_list": [
        {
            "code_key": 200,
            "code_str": "ok",
            "user_msg": "unattached_documents retrieved",
            "level_int": 1,
            "level_str": "info"
        }
    ],
    "trans_map": {},
    "response_map": {
        "documents": [
            {
              "uuid": "f0de9faa-b0a7-11ea-8ac3-76ad82d12dd4",
              "created": 1592404593537,
              "file_name": "document_1.pdf"
            },
            {
              "uuid": "bd3140e5-b0a7-11ea-8ac3-76ad82d12dd4",
              "created": 1592404506837,
              "file_name": "document_2.pdf"
            }
        ]
    }
}
</code></pre>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
    export default {
        name : "SearchUnattachedDocuments"
    }
</script>

<style scoped>

</style>
