import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import VueI18n from 'vue-i18n'
import util from "../util/util";

const locale = util.getLocale();
Vue.use( Vuetify );
Vue.use( VueI18n );
import th from 'vuetify/src/locale/th.ts'
import es from 'vuetify/src/locale/es.ts'
import fr from 'vuetify/src/locale/fr.ts'
import nl from 'vuetify/src/locale/nl.ts'
import sv from 'vuetify/src/locale/sv.ts'
import de from 'vuetify/src/locale/de.ts'
import pl from 'vuetify/src/locale/pl.ts'
import fi from '../i18n/fi/vuetify.ts'
import en from 'vuetify/src/locale/en.ts'


const i18n = new VueI18n( {
    locale : locale,
    fallbackLocale : en,
    messages : {
        fi : { $vuetify : fi },
        sv : { $vuetify : sv },
        en : { $vuetify : en },
        pl : { $vuetify : pl },
        de : { $vuetify : de },
        nl : { $vuetify : nl },
        fr : { $vuetify : fr },
        es : { $vuetify : es },
        th : { $vuetify : th },
    },
} );
export default new Vuetify( {
    icons : {
        iconfont : 'mdi',
    },
    lang : {
        t : ( key, ...params ) => i18n.t( key, params ),
    },
    theme : {
        themes : {
            light : {
                primary: '#ff507a',
                secondary: '#1a1a1a',
                info: '#666666',
                error: '#e34c74',
                warning: '#e34c74',
                textBlack: '#333333',
                cardGrey: '#8d8d8d'
            }
        }
    }
} );
