<template>
  <div class="content text-left ma-auto mr-0">
    <div id="section17" class="CreateSigningURL sectionContainer ">
      <h1 class="section-title">Create a signing URL</h1>
      <p class="mt-4"><b>This method is only available in API v4.</b></p>
      <p><b>Scope:</b><i> signing_url</i></p>
      <hr class="line-break">
      <p>It is possible to create a URL that opens the signing page for a <b>me</b> signer. This will allow you to
        streamline signing of documents directly from your app. To create such URL, send a request to the
        <i>signatures</i> endpoint:</p>
      <v-card>
        <div class="card-body mb-4">
<pre><code>POST /4/signatures HTTP/1.1
 Host: api.signhero.io
 Content-Type: application/json

 {
     "action_str": "create",
     "data_type": "signing_url",
     "trans_map": {
         "access_token": "YYWMtGeygaKCEeahbgX148SD2wAAAVipR17t2Xygd71iJDLWO"
     },
     "request_map": {
         "process_id": "f0de9faa-b0a7-11ea-8ac3-76ad82d12dd4"
     }
 }
</code></pre>
        </div>
      </v-card>
      <p>The parameters in the request map:</p>

      <v-simple-table light>
        <template v-slot:default>
          <tbody>
          <tr>
            <td>process_id</td>
            <td>Identifier of a signing process that has a <b>me</b> signer.
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>

      <p class="mt-4">The successful response includes the signing URL:</p>
      <v-card>
        <div class="card-body">
<pre><code>{
    "action_str": "created",
    "data_type": "signing_url",
    "log_list": [
        {
            "code_key": 200,
            "code_str": "ok",
            "user_msg": "signing_url created",
            "level_int": 1,
            "level_str": "info"
        }
    ],
    "trans_map": {},
    "response_map": {
        "signing_url": "https://signhero.io/signing.html?signer=14ae10f6-ec74-11ea-836c-0e4c72c52e02
  &token=KFcWKX3JwUV0-hs7R8jDNf45Rl3H_v9f40RrfG2jM2e61ijeNq1bx2SzdLtMfU97&default_locale=en"
    }
}
</code></pre>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
    export default {
        name : "CreateASigningURL"
    }
</script>

<style scoped>

</style>
