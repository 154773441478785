<template>
  <div class="content text-left ma-auto mr-0">
    <div id="section0" class="RoadMap sectionContainer">
      <h1 class="section-title">What's new in SignHero API</h1>
      <hr class="line-break">
      <v-alert class="api-info">
            <span>By using SignHero API, you accept our
              <v-btn text class="api-positive-link" href="/terms/api" target="_blank">API terms</v-btn>.
            </span>
      </v-alert>
      <div class="versionContainer">
        <h3 class="version-title">Version 4.1</h3>
        <i>Released: 15.02.2022</i>
        <v-expansion-panels focusable class="py-6">
          <v-expansion-panel>
            <v-expansion-panel-header class="card-header">Reminder e-mail
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="card-body py-4">
                <h5 class="card-title pb-2">What's new?</h5>
                <p class="card-text">
                  In this version the long awaited function to send email-reminders to signers was added. You can now remind forgetful signers to sign documents also via the API.<br><br>
                  Download our latest
                  <v-btn text target="_blank" class="api-positive-link" href="/SignHero_Postman_Collection.zip">
                    Postman collection
                  </v-btn>
                  and give the API a spin!
                </p>
                <hr class="inner-line-break">
                <h5 class="card-title pb-2">Changes from version 4.0:</h5>
                <ul>
                  <li>
                    E-mail reminder function added, see
                    <v-btn text class="api-positive-link" to="/docs/api/create-sign-reminders">
                      Create sign reminders
                    </v-btn>
                    section to find out more.
                  </li>
                </ul>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <hr>
      </div>
      <div class="versionContainer">
        <h3 class="version-title">Version 4.0</h3>
        <i>Released: 24.09.2020</i>
        <v-expansion-panels focusable class="py-6">
          <v-expansion-panel>
            <v-expansion-panel-header class="card-header">Document management and access to "Me" signers
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="card-body py-4">
                <h5 class="card-title pb-2">What's new?</h5>
                <p class="card-text">
                  SignHero API v4 is a brand new version that introduces new API methods for document management
                  and
                  interaction with account owners. Additionally, we've taken into consideration a lot of feedback
                  to
                  make the API more pleasant to work with.<br><br>
                  API v4 is available in parallel with v3, read about API versioning in
                  <v-btn text class="api-positive-link" to="/docs/api/overview">Overview</v-btn>
                  to find
                  out more.<br><br>
                  Download our latest
                  <v-btn text target="_blank" class="api-positive-link" href="/SignHero_Postman_Collection.zip">
                    Postman collection
                  </v-btn>
                  and give the API a spin!
                </p>
                <hr class="inner-line-break">
                <h5 class="card-title pb-2">Main changes from version 3:</h5>
                <ul>
                  <li>
                    OAuth2 scopes added. Check
                    <v-btn text class="api-positive-link" to="/docs/api/request-an-authorisation-code">
                      Request an authorisation code
                    </v-btn>
                    section to find out more
                  </li>
                  <li>
                    A brand new <v-btn text class="api-positive-link" to="/docs/api/upload-a-document">
                    Document upload</v-btn> request added
                  </li>
                  <li>
                    <v-btn text class="api-positive-link" to="/docs/api/search-unattached-documents">Search</v-btn> and
                    <v-btn text class="api-positive-link" to="/docs/api/delete-an-unattached-document">Delete</v-btn>
                    unattached documents with new document management requests
                  </li>
                  <li>
                    <v-btn text class="api-positive-link" to="/docs/api/create-a-signing-process">Signing process creation</v-btn>
                    request changed to allow creating processes with account owner as a participant
                  </li>
                  <li>
                    <v-btn text class="api-positive-link" to="/docs/api/create-a-signing-url">Create signing URLs</v-btn>
                    to complete signing processes without forcing users to leave your application
                  </li>
                  <li>
                    A brand new
                    <v-btn text class="api-positive-link" to="/docs/api/download-a-document">Document download</v-btn>
                    request added
                  </li>
                  <li>
                    <v-btn text class="api-positive-link" to="/docs/api/retrieve-profile-info">Retrieve user profile</v-btn>
                    info
                  </li>
                  <li>
                    <i>code_key</i> values in <i>log_list</i> entries are now numbers instead of strings:<br>
                    <i>"code_key": "200"</i> => <i>"code_key": 200</i>
                  </li>
                  <li>
                    Access tokens are no longer replicated in each API response. Additionally, <i>auth_key</i> key
                    was dropped for client-credentials tokens. From now on, both types of access tokens are passed
                    into requests with the <i>access_token</i> key:<br>
                    <i>"trans_map": { "access_token": "9YWx0ivNwUtLSHld3H0XfduxdEoPVH0NlmoBM" }</i>
                  </li>
                </ul>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <hr>
      </div>
      <div class="versionContainer">
        <h3 class="version-title">Version 3.0</h3>
        <i>Released: 27.03.2019</i><br>
        <!--<i>Will deprecate: 03.2021</i>-->
        <v-expansion-panels focusable class="py-6">
          <v-expansion-panel>
            <v-expansion-panel-header class="card-header">OAuth2 release
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="card-body py-4">
                <h5 class="card-title pb-2">What's new?</h5>
                <p class="card-text">
                  SignHero API v3 features OAuth2 functionalities by enabling the authorisation code grant
                  type. Now, depending on your needs, it’s possible to manage signature processes
                  through your own SignHero account or do that on behalf of any other user.
                </p>
                <hr class="inner-line-break">
                <h5 class="card-title pb-2">Main changes from version 2:</h5>
                <ul>
                  <li><v-btn text class="api-positive-link" to="/docs/api/authorisation-code">
                    Authorisation code grant</v-btn> added
                  </li>
                  <li>
                    The SignHero API URL changed to <v-btn text class="api-positive-link" href="https://api.signhero.io">api.signhero.io</v-btn>
                    – the old one (<v-btn text class="api-positive-link" href="https://signhero.io/api">signhero.io/api</v-btn>)
                    will be
                    deprecated with API v2
                  </li>
                </ul>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <hr>
      </div>
      <div class="versionContainer">
        <h3 class="version-title">Version 2.0</h3>
        <i>Deprecated</i>
        <v-expansion-panels focusable class="py-6">
          <v-expansion-panel>
            <v-expansion-panel-header class="card-header">More stability and control over signature processes
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="card-body py-4">
                <h5 class="card-title pb-2">What's new?</h5>
                <p class="card-text">
                  SignHero API v2 is an improvement release fixing known issues and adding new
                  features to searching through signature processes.
                </p>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <hr>
      </div>
      <div class="versionContainer">
        <h3 class="version-title">Version 1.0</h3>
        <i>Deprecated</i>
        <v-expansion-panels focusable class="py-6">
          <v-expansion-panel>
            <v-expansion-panel-header class="card-header">The first release
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="card-body py-4">
                <p class="card-text">
                  The first release of SignHero API, allowing users to automate signature process
                  management within their own accounts.
                </p>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <hr>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name : "Roadmap"
    }
</script>

<style lang="sass" scoped>
  @import "../../../styles/style"

  .versionContainer
    margin: 25px 0 50px

  .card-header
    font-size: 20px
    color: $vivid-pink
    cursor: pointer
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out
    &:hover
      color: white
      background: $dark-grey

  .card-title
    font-size: 20px
</style>
