<template>
  <div class="content text-left ma-auto mr-0">
    <div id="section21" class="DeleteSigningProcess sectionContainer">
      <h1 class="section-title">Delete a signing process</h1>
      <div class="d-flex version-switch mt-4">
        <span>v3</span>
        <v-switch v-model="isV4" class="mx-2"></v-switch>
        <span>v4</span>
      </div>
      <p><b>Scope:</b><i> basic</i></p>
      <hr class="line-break">
      <p>A signing process that has been completed or canceled can be deleted from search results.</p>
      <v-card>
        <div class="card-body mb-4">
<pre><code>POST <span v-if="isV4">{{ versionDifferenceSlash4 }}</span>/signatures HTTP/1.1
 Host: api.signhero.io
 Content-Type: application/json

 {
     "action_str": "delete",
     "data_type": "signature_flow",
     "trans_map": {
         "<span v-if="isV4">{{ versionDifferenceAccessToken }}</span><span
      v-if="!isV4">{{ versionDifferenceAuthKey }}</span>": "YWMtGeygaKCEeahbgX148SD2wAAAVipR17t2Xygd71iJDLWO"
     },
     "request_map": {
         "process_id": "ae3b5221-0272-11e7-a154-46787cfede40"
     }
 }
</code></pre>
        </div>
      </v-card>
      <v-card>
        <div class="card-body">
<pre><code>{
    "action_str": "deleted",
    "data_type": "signature_flow",
    "log_list": [
        {
            "code_key": <span v-if="!isV4">{{ versionDifferenceQuotationMark200 }}</span><span v-if="isV4">{{ versionDifference200 }}</span>,
            "code_str": "ok",
            "user_msg": "signature_flow deleted",
            "level_int": 1,
            "level_str": "info"
        }
    ],
    "response_map": {},
    "trans_map": {<span v-if="!isV4">
        "auth_key": "YYWMtGeygaKCEeahbgX148SD2wAAAVipR17t2Xygd71iJDLWO"
    </span>}
}
</code></pre>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
    export default {
        name : "DeleteASigningProcess",
        data()
        {
            return {
                isV4 : true,
                versionDifferenceSlash4 : '/4',
                versionDifference200 : '200',
                versionDifferenceQuotationMark200 : '"200"',
                versionDifferenceAuthKey : 'auth_key',
                versionDifferenceAccessToken : 'access_token',
            }
        }
    }
</script>

<style scoped>

</style>
