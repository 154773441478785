const uuidRegExp = "this space left blank";
export default {
    "interface_version" : "2.0.0",
    "create" : {
        "signature" : {
            "scope" : "sign",
            "handler" : "route",
            "requestMapSchema" : {
                "type" : "Object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "processUuid" : { "type" : "string", "pattern" : uuidRegExp },
                    "eventUuid" : { "type" : "string", "pattern" : uuidRegExp }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "processUuid", "eventUuid" ]
            }
        },
        "unsigned_digest" : {
            "handler" : "route",
            "scope" : "download",
            "requestMapSchema" : {
                "type" : "Object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "processUuid" : { "type" : "string", "pattern" : uuidRegExp },
                    "eventUuid" : { "type" : "string", "pattern" : uuidRegExp },
                    "docUuid" : { "type" : "string", "pattern" : uuidRegExp }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "processUuid", "eventUuid", "docUuid" ]
            }
        }
    },
    "retrieve" : {
        "documents" : {
            "scope" : "download",
            "handler" : "route",
            "requestMapSchema" : {
                "type" : "Object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "processUuid" : { "type" : "string", "pattern" : uuidRegExp }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "processUuid" ]
            }
        },
        "audit_trail_obj" : {
            "scope" : "download",
            "handler" : "route",
            "requestMapSchema" : {
                "type" : "Object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "processUuid" : { "type" : "string", "pattern" : uuidRegExp },
                    "eventUuid" : { "type" : "string", "pattern" : uuidRegExp }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "processUuid", "eventUuid" ]
            }
        },
        "sign_flow" : {
            "scope" : "sign",
            "handler" : "route",
            "requestMapSchema" : {
                "type" : "Object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "processUuid" : { "type" : "string", "pattern" : uuidRegExp },
                    "eventUuid" : { "type" : "string", "pattern" : uuidRegExp }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "processUuid", "eventUuid" ]
            }
        },
        "status" : {
            "scope" : "sign",
            "handler" : "route",
            "requestMapSchema" : {
                "type" : "Object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "processUuid" : { "type" : "string", "pattern" : uuidRegExp },
                    "eventUuid" : { "type" : "string", "pattern" : uuidRegExp }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "processUuid", "eventUuid" ]
            }
        }
    },
    "abort" : {
        "signature" : {
            "scope" : "sign",
            "handler" : "route",
            "requestMapSchema" : {
                "type" : "Object",
                "properties" : {
                    "groupId" : { "type" : "string", "pattern" : uuidRegExp },
                    "processUuid" : { "type" : "string", "pattern" : uuidRegExp },
                    "eventUuid" : { "type" : "string", "pattern" : uuidRegExp },
                    "declineReasons" : { "type" : "string" }
                },
                "additionalProperties" : false,
                "required" : [ "groupId", "processUuid", "eventUuid" ]
            }
        }
    }
};
