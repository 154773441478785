<template>
  <v-footer
      color="grey darken-4"
      class="white--text generic-footer"
      padless
      app
  >
    <v-row
        align="center"
        justify="center">
      <v-col cols="12" class="col-sm-3 col-lg-3 py-0">
        <router-link to="/start">
          <img class="footer-logo" src="/signherologoW.svg" height="50"/>
        </router-link>
      </v-col>
      <v-col cols="12" class="col-sm-3 col-lg-3 footer-links">
        <a href="/terms-of-use.html" target="_blank">{{ $t( 'message.TermsOfUse' ) }}</a>
      </v-col>
      <v-col cols="12" class="col-sm-3 col-lg-3 footer-links">
        <a href="/privacy-policy.html" target="_blank">{{ $t( 'message.PrivacyPolicy' ) }}</a>
      </v-col>
      <v-col cols="12" class="col-sm-3 col-lg-3 footer-links">
        <a href="/contact.html" target="_blank">{{ $t( 'message.Contact' ) }}</a>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
    export default {
        name : "sh-app-footer",
        data()
        {
            return {
                links : []
            }
        }
    }
</script>

<style lang="sass" scoped>
  a
    color: white !important
    text-decoration: none

  a:hover
    text-decoration: underline

  .generic-footer
    width: 100%
    height: 70px
    position: sticky

  .footer-logo
    vertical-align: middle

  @media (max-width: 599px)
    .generic-footer
      height: 170px

    .footer-logo
      padding-top: 5px
      padding-bottom: 5px

    .footer-links
      padding-top: 5px
      padding-bottom: 5px
</style>
