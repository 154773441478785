<template>
  <div class="content text-left ma-auto mr-0">
    <div id="section9" class="ExchangeAuthorisationCodeToAccessToken sectionContainer ">
      <h1 class="section-title">Exchange the authorisation code to an access token</h1>
      <div class="d-flex version-switch mt-4">
        <span>v3</span>
        <v-switch v-model="isV4" class="mx-2"></v-switch>
        <span>v4</span>
      </div>
      <hr class="line-break">
      <div>
        <p>Next the authorisation code must be exchanged for an access token that can be used to call
          SignHero API. To exchange the authorisation code, send a request to the token endpoint:</p>
        <v-card>
          <div class="card-body mb-4">
  <pre><code>POST <span v-if="isV4">{{ versionDifferenceSlash4 }}</span>/token HTTP/1.1
  Host: api.signhero.io
  Content-Type: application/json

  {
      "action_str": "create",
      "data_type": "access_token",
      "request_map" : {
          "client_id": "5e5b2260-d3a7-11e8-a608-92030a9483f4",
          "client_secret": "qSHofvOzKlAI7kYCNW3CmleZxmYF5nUiG1RZayDJsM",
          "grant_type": "authorization_code",
          "code": "obweYepSsNTvPTXLlS44umklgq2eArlKm3qPQe0GlXg",
          "redirect_uri": "https://example.io/oauth2/callback"
      }
  }
  </code></pre>
          </div>
        </v-card>
        <p>The parameters in the request map:</p>
        <v-simple-table light>
          <template v-slot:default>
            <tbody>
            <tr>
              <td>client_id</td>
              <td>Your application's client identifier.</td>
            </tr>
            <tr>
              <td>client_secret</td>
              <td>Your application's client secret.</td>
            </tr>
            <tr>
              <td>grant_type</td>
              <td>The value must be "authorization_code".</td>
            </tr>
            <tr>
              <td>code</td>
              <td>The authorisation code.</td>
            </tr>
            <tr>
              <td>redirect_uri</td>
              <td>The URI to which SignHero will redirect the user after authorisation.
                This URI must be specified as a valid redirect URI in your application
                configurations.
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <p class="mt-4">If the request is valid and authorised, the response includes an access token and refresh
          token.</p>
        <v-card>
          <div class="card-body mb-4">
<pre><code>{
    "action_str": "created",
    "data_type": "access_token",
    "log_list": [
        {
            "code_key": <span v-if="!isV4">{{ versionDifferenceQuotationMark200 }}</span><span v-if="isV4">{{ versionDifference200 }}</span>,
            "code_str": "ok",
            "user_msg": "access_token created",
            "level_int": 1,
            "level_str": "info"
        }
    ],
    "response_map": {},
    "trans_map": {
        "access_token": "9YWx0ivNwUtLSHld3H0XfduxdEoPVH0NlmoBM",
        "expires": 1538994375247,
        "refresh_token": "j06Bv5oRTByT5GYiZOuUH4hqm8ZQzaXMdI4XYj1vY"
    }
}
</code></pre>
          </div>
        </v-card>
        <p>Access tokens expire in 7 days. Refresh tokens expire only after usage. New refresh token is sent with the updated access token.</p>
        <p>To make SignHero API calls, add the obtained access token to the <b>access_token</b> field in the
          request:</p>
        <v-card>
          <div class="card-body">
            <pre><code>POST <span v-if="isV4">{{ versionDifferenceSlash4 }}</span>/signatures HTTP/1.1
  Host: api.signhero.io
  Content-Type: application/json

  {
      "action_str": "retrieve",
      "data_type": "signature_flow",
      "trans_map": {
          "access_token": "9YWx0ivNwUtLSHld3H0XfduxdEoPVH0NlmoBM"
      },
      "request_map": {
          "process_id": "ae3b5221-0272-11e7-a154-46787cfede40"
      }
  }
  </code></pre>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name : "ExchangeTheAuthorisationCodeToAnAccessToken",
        data()
        {
            return {
                isV4 : true,
                versionDifferenceSlash4 : '/4',
                versionDifference200 : '200',
                versionDifferenceQuotationMark200 : '"200"',
            }
        }
    }
</script>

<style lang="sass" scoped>

</style>
