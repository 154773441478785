export default {
    "pdfsign": {
        "Error!Unexpected": "Tapahtui odottamaton virhe. Yritä myöhemmin uudestaan.",
        "SelectSigner": "Valitse allekirjoittaja",
        "SignatureTimestampFormat": "Allekirjoitettu pp.kk.vvvv",
        "SignatureTypeFormat": "Tyyppi: {Signature-type-xxxxxxxx}",
        "SignatureAdvancedLevelFormat": "Vahva sähköinen allekirjoitus",
        "DeclinationPlaceHolder": "Syy (vapaaehtoinen)",
        "SignerGreetings": "Hei ",
        "WhoSigns": "Kuka allekirjoittaa:",
        "Sign": "Allekirjoita",
        "WhatSigned": "Mitä allekirjoitetaan: <span class='sh-nowOrNeverText'>(tarkista nyt ennen kuin allekirjoitat)</span>",
        "SignConfirmationMsg": "Tarkistin mitä allekirjoitan, ja minulla on oikeus allekirjoittaa.",
        "UsagePolicyInfoMsg": "Käyttämällä SignHeroa, hyväksyt <a href='terms-of-use.html' target='_blank'>käyttöehdot</a> ja <a href='privacy-policy.html' target='_blank'>tietosuojaselosteen</a>.",
        "Decline": "Kieltäydy",
        "Cancel": "Peru",
        "ThankUsingService": "Kiitos kuitenkin!",
        "FindMoreInformation": "Valitse helppous, <a class='elem-pink' href='https://www.signhero.io'>SignHero.io</a>",
        "SignEventCancelled": "Prosessi on peruttu",
        "CompleteProcessInfo": "Et voi allekirjoittaa asiakirjoja, koska allekirjoituspyyntö on suljettu. Olet saattanut allekirjoittaa asiakirjat jo, tai allekirjoituskutsun lähettäjä on peruuttanut pyynnön.",
        "DeclineToSignTitle": "Peruuta allekirjoittaminen",
        "SignedAndSent": "Asiakirja(t) on allekirjoitettu onnistuneesti.",
        "SorryUnexpectedError": "Jokin meni pieleen",
        "SessionNeedRefreshError": "Istuntosi on päivitettävä. Yritä vielä uudelleen.",
        "ReasonExpiredSession": "Linkki voi olla rikki tai kutsu on voitu poistaa.",
        "InvalidLoginUrl": "Sivua ei löydy. Ole hyvä ja tarkista sisäänkirjautumisosoitteesi.<br><br>Jos saat tämän viestin uudelleen, hakemasi sivu on saattanut vanhentua tai se on poistettu.",
        "You": "sinä",
        "AdvancedSign": "Allekirjoita",
        "chooseMethodDialogTitle": "Tarkista asiakirjat huolellisesti ennen allekirjoittamista"
    },
    "signedin": {
        "locale!fi": "Suomi",
        "locale!en": "English",
        "locale!pl": "Polski",
        "locale!de" : "Deutsch",
        "locale!sv" : "Svenska",
        "locale!es" : "Español",
        "locale!fr" : "Français",
        "locale!nl" : "Nederlands",
        "locale!th" : "ภาษาไทย",
        "Applications": "Sovellukset",
        "DateTimeFormat": "dd.MM.yyyy, HH:mm:ss",
        "Create": "Luo sovellus",
        "UserDeleted": "Käyttäjätili on estetty ja se poistetaan SignHerosta pysyvästi 30 päivän kuluessa.",
        "UserRestored": "Käyttäjätili on palautettu",
        "UserAlreadyActive" : "Käyttäjä on jo aktiivinen.",
        "Error!UserExists": "Valitsemasi sähköposti tai käyttäjätunnus on jo käytössä. Jos olet unohtanut salasanasi, klikkaa \"Kirjaudu\" ja käytä \"Vaihda salasana\" -linkkiä.",
        "TheService": "Palvelu",
        "is": "on",
        "Save": "Tallenna",
        "MyOrganization": "Organisaationi",
        "OrganizationName": "Organisaation nimi",
        "ManageAccounts": "Hallinnoi tilejä",
        "ManageSignatureTypes" : "Hallinnoi allekirjoitustyyppejä",
        "ManageSignatureTypesHelp" : "Valitse allekirjoitustyypit, joita organisaatiosi voi käyttää. Yksi on oltava oletuksena.",
        "AdminStatusChanged": "Käyttäjän tiedot päivitetty.",
        "ConfirmDeleteEverything": "Oletko varma, että haluat poistaa organisaation tilin (${groupTitle})?\n",
        "ConfirmCancelOngoingProcesses" : "Organisaatiollasi on ${processNum} allekirjoitusprosessia kesken tällä hetkellä. Ne tulisi peruuttaa ennen organisaatiotilin poistamisen jatkamista.\n "
                                          + "\nOletko varma, että haluat peruuttaa kaikki käynnissä olevat prosessit ja poistaa tämän tilin (${groupTitle})?\n",
        "IncorrectConfirmationCode" : "Virheellinen vahvistuskoodi.",
        "DownloadOrganizationDataMessage": "Lataa käyttäjä- ja allekirjoitusprosessitiedot JSON-tiedostona.",
        "DownloadOrganizationDataLink": "Lataa organisaatiotiedot",
        "Subscription": "Tilaus",
        "InviteUserHeader": "Kutsu uusi käyttäjä organisaatioosi",
        "AccountStatus" : "Tilin tila:",
        "OwnAccount" : "Sinun tilisi",
        "StatusActive" : "Aktiivinen, käyttäjä on hyväksynyt kutsun.",
        "StatusInactive" : "Epäaktiivinen, käyttäjä ei ole vielä hyväksynyt kutsua.",
        "StatusDeleted" : "Merkitty poistettavaksi. Tilaus ei ole aktiivinen, sitä ei uusita tälle käyttäjälle.",
        "AccountRights" : "Oikeudet:",
        "DownloadOrganizationData": "Lataa JSON",
        "DeleteOrganizationMessage": "Poista tämä organisaatio, oma tilisi sekä kaikki muut admin- ja käyttäjätilit",
        "DeleteEverything": "Poista kaikki",
        "OrganizationHasActiveProcesses" : "Organisaatiota ei voitu poistaa koska sillä on aktiivisia allekirjoitusprosesseja tai yhdistämispyyntöjä.",
        "OrganizationDeleteMessage": "Organisaation tili on poistettu.\nHarmi että lähditte, toivottavasti nähdään vielä.",
        "OrganizationHasBeenDeleted": "Administraattori on poistanut tilisi.\nHarmi että lähditte, toivottavasti nähdään vielä.",
        "MergeOrganizationMessage": "Organisaatio voi pyytää yhdistämistä toiseen organisaatioon.",
        "RequestMergeDetails": "Organisaation administraattori voi pyytää organisaation yhdistämistä toiseen organisaatioon. Pyyntö on voimassa 24 tuntia.\n\n"
            + "Pyynnön hyväksymisen jälkeen allekirjoitusprosessit, mukaan lukien asiakirjat ja käyttäjät, siirretään uuteen organisaatioon, ja tämä organisaatio poistetaan. "
            + "Tämän organisaation käyttäjillä ei ole administraattorin oikeuksia yhdistämisen jälkeen. Uuden organisaation administraattorin on lisättävä oikeudet. "
            + "Jos tilaus oli aktiivinen, jäljellä oleva tilausjakso peruuntuu ja siirretyt käyttäjät lasketaan mukaan uuden organisaation tilaukseen.",
        "MergeOrganizationDetails": "Organisaatio ${groupName} on pyytänyt organisaation tilin yhdistämistä "
            + "sinun organisaation tiliin. Pyyntö on voimassa 24 tuntia.\n\n"
            + "Pyynnön hyväksymisen jälkeen allekirjoitusprosessit, mukaan lukien asiakirjat ja käyttäjät, siirretään organisaatioosi, "
            + "ja siirretty organisaatio poistetaan. Siirretyn organisaation käyttäjillä ei ole administraattorin oikeuksia yhdistämisen jälkeen. "
            + "Organisaatiosi administraattorin on lisättävä oikeudet. Jos aktiivinen tilaus oli voimassa, "
            + "siirretyt käyttäjät lasketaan mukaan organisaatiosi tilaukseen.",
        "RequestMerge": "Pyydä yhdistämistä",
        "ConfirmMergeOrganisation": "Oletko varma, että haluat yhdistää organisaation tilin? Tilien jakamista takaisin ei tueta.",
        "EnterAdminEmail": "Syötä toisen organisaation administraattorin sähköpostiosoite:",
        "EnterPassword": "Jatkaaksesi syötä salasanasi:",
        "InvalidPassword" : "Salasana oli virheellinen. Ole hyvä ja yritä uudestaan.",
        "MergeRequestSent": "Organisaation yhdistämispyyntö lähetetty",
        "MergeRequestCanceled": "Organisaation yhdistämispyyntö peruttu",
        "MergeRequestRejected": "Organisaation yhdistämispyyntö hylätty",
        "ReceivedMergeRequests": "Vastaanotetut yhdistämispyynnöt",
        "SentMergeRequest": "Lähetetty yhdistämispyyntö",
        "Recipient": "Vastaanottaja",
        "Reject": "Hylkää",
        "Merge": "Yhdistä",
        "OrganisationMerged": "Yhdistäminen alkoi.",
        "UserNotDifferentOrganizationAdmin": "Käyttäjä ei ole toisen organisaation administraattori",
        "UserNotFound": "Käyttäjää ei löydy!",
        "OrganizationHasActiveMergeRequest": "Organisaatiolla on jo aktiivinen yhdistämispyyntö",
        "InvalidMergeRequest": "Yhdistämispyyntöä ei löytynyt tai se on vanhentunut",
        "SearchUsers": "Hae käyttäjiä",
        "UserName": "Etunimi Sukunimi",
        "Locales": "Kieli",
        "Sessions": "Istunnot",
        "Devices": "Laitteet",
        "LogOutAllDevicesMessage": "Voit tarvittaessa kirjautua ulos kaikista istunnoista ja kaikista laitteista.",
        "SessionType!password": "Salasana",
        "SessionType!auth_code": "Sovellus",
        "NoApplicationsMessage": "Et ole vielä valtuuttanut yhtään sovellusta.",
        "Domain" : "Domain",
        "AccessGiven" : "Valtuutettu",
        "LastAccess" : "Viimeisin yhteys",
        "RemoveAccess": "Poista pääsy",
        "isAdmin": "Admin",
        "SendReminder": "Lähetä tilin aktivointimuistutus",
        "EmailReminderSuccess": "Aktivointimuistutus lähetetty.",
        "DeleteUser": "Poista käyttäjätili",
        "RestoreAccount" : "Palauta käyttäjätili",
        "InviteUser": "Luo käyttäjätili",
        "UserInvited" : "Käyttäjä on kutsuttu",
        "Error!Unexpected": "Tapahtui odottamaton virhe. Yritä myöhemmin uudestaan.",
        "Error!InvitedUserExists": "Käyttäjä on jo tämän organisaation jäsen tai kuuluu johonkin toiseen organisaatioon. Jälkimmäisessä tapauksessa kannattaa olla yhteydessä ${email}",
        "Sign": "Allekirjoita",
        "Send": "Lähetä",
        "Ongoing": "Kesken",
        "Complete": "Arkisto",
        "Start": "Aloita",
        "MyAccount": "SignHero-tili",
        "Email": "Sähköposti",
        "Logout": "Kirjaudu ulos",
        "SignOut": "Kirjaudu ulos kaikista istunnoista",
        "Confirm": "Jatka",
        "Reset": "Peru",
        "Profile": "Profiilini",
        "Organization": "Organisaatio",
        "Device": "Laite",
        "MyIntegrations": "Integraatiot",
        "Statistics" : "Tilastot",
        "Update" : "Tallenna",
        "Delete" : "Poista",
        "AcceptApiTerms" : "Käyttämällä SignHero-APIa, hyväksyt <a href='/terms/api' target='_blank'>APIn käyttöehdot</a>.",
        "CannotDeleteActiveFlows" : "Organisaatiollasi on allekirjoitusprosesseja kesken. Ole hyvä ja peruuta prosessit ennen kuin poistat organisaation tilin.",
        "MySubscription" : "Minun tilaukseni",
        "FreeTrialLeft" : "Kokeilujaksoa jäljellä: ",
        "Days" : "Päivää.",
        "SubscriptionPeriod" : "Nykyinen tilauskausi:",
        "NoSubscription" : "Tilaus ei ole aktiivinen.",
        "PleaseActivateSubscription" : "Aktivoi tilauksesi.",
        "CurrentBill" : "Nykyinen kuukausilaskutus:",
        "Tab1" : "Maksutiedot",
        "Tab2" : "Laskutushistoria",
        "Tab3" : "Info",
        "NoPaymentNotification" : "Huomautus: Sinulla ei ole kelvollista maksutapaa nyt.",
        "AddCreditCard" : "Lisää luottokortti SignHeron kuukausilaskutusta varten",
        "CreditCardEmail" : "Sähköposti",
        "BillingDate" : "Laskutuspäivä",
        "Amount" : "Summa",
        "UsersAmount" : "Käyttäjiä",
        "Duration" : "Kesto",
        "ReferNumber" : "Viitenumero",
        "PDF" : "PDF",
        "Invoice" : "Lasku",
        "Action" : "Toiminta",
        "LoadMore" : "Lataa lisää",
        "SalesChannel" : "Myyntikanava",
        "ResellerName" : "Jälleenmyyjä: ",
        "ResellerContact" : "Yhteyshenkilö: ",
        "ResellerMaxUsers" : "Enimmäiskäyttäjämäärä: ",
        "ResellerCurrentInvitedUsers" : "Nykyiset kutsutut käyttäjät: ",
        "MaxResellerUsersWarning" : "Käyttäjien määrä on saavuttanut jälleenmyyjäsi asettaman enimmäisrajan (${resellerMaxUsers}).",
        "Status" : "Tila",
        "StartOfSubscription" : "Tilauksen aloituspäivämäärä:",
        "EstimatedBilling" : "Seuraava lasku ${nextBillingDate} ${nextBillingValue}€",
        "SubInactive1" : "Tilaus ei ole aktiivinen",
        "SubInactive2" : ", sitä ei uusita tälle poistetulle käyttäjälle",
        "isSubscriptionAdmin" : "Tilauksen hallinnoija",
        "CardInfo" : "Korttitiedot",
        "CardNumber" : "Kortin numero",
        "NameOnCard" : "Kortilla oleva nimi",
        "SelectCountry" : "Maa tai alue",
        "SelectLocale" : "Valitse kieli",
        "TaxInfoTitle" : "Verotiedot",
        "TaxIdExplanation" : "Ole hyvä ja valitse veroalueesi ja syötä  yrityksesi ALV-tunniste.",
        "SelectIdType" : "Valitse verotunnustyyppi",
        "TaxId" : "Verotunnus",
        "AddCardTooltip1" : "Lisää ensin voimassaolevat laskutustiedot.",
        "AddCardTooltip2" : "Lisää voimassa olevat luottokorttitiedot. Kaikki kentät ovat pakollisia.",
        "AddCardTooltip3" : "Luottokorttitiedot ovat kunnossa.",
        "BillingInformation" : "Laskutustiedot",
        "Edit" : "Muokkaa",
        "EditBillingInformation" : "Muokkaa laskutustietoja",
        "AddBillingTooltip" : "Täytä kaikki pakolliset kentät ja tarkista, että verotunnus vastaa maatietoja.",
        "Street" : "Osoite",
        "Street2" : "Osoite2",
        "City" : "Kaupunki",
        "State" : "Maakunta",
        "Zip" : "Postinumero",
        "SaveBillingInfo" : "Tallenna laskutustiedot",
        "SaveCard" : "Tallenna kortin tiedot",
        "Expires" : "Vanhenee",
        "DeleteCardOnly" : "Poista kortti",
        "DeletePaymentInfo" : "Poista laskutustiedot ja luottokorttitiedot.",
        "RemoveWholePaymentMethod" : "Poista maksutiedot",
        "AustralianVAT" : "Australialaiset yritysnumerot",
        "EuVAT" : "EU ALV numerot",
        "UkVAT" : "UK ALV numerot",
        "OtherBusinessId" : "Muu yritystunnus",
        "PayOnline" : "Vahvista maksu",
        "PaymentStatusPaid" : "Maksettu",
        "PaymentStatusPending" : "Kesken",
        "PaymentInfo" : "Maksuista",
        "PaymentExplanation1" : "SignHeron käyttö maksaa 5€ kuukaudessa per aktiivinen käyttäjä (hinta ei sisällä arvonlisäveroa/alv). Kulloinkin voimassa oleva arvonlisävero/alv lisätään hintaan. Passiivisista käyttäjistä ei peritä maksua. Jos lisäät tai poistat käyttäjiä laskutusjakson aikana, seuraava lasku mukautetaan automaattisesti jäljellä olevan tilausjakson perusteella.\n"
                                + "Kun rekisteröidyt palveluun, saat 14 päivän ilmaisen kokeilujakson. Tämän jälkeen sinun tulee rekisteröidä luottokorttisi kuukausittaisten toistuvien maksujen aktivoimiseksi, mikäli haluat jatkaa palvelun käyttöä. Luottokorttiasi veloitetaan automaattisesti joka kuukausi sen mukaan, kuinka monta käyttäjää olet rekisteröinyt palveluun. Saat kuukausittaisesta maksustasi kuitin siihen sähköpostiosoitteeseen, jonka ilmoitit luottokorttia rekisteröidessäsi.",
        "PaymentExplanation2" : "Jos käytät SignHeroa yrityksenä etkä yksityishenkilönä, sinun tulee ilmoittaa yrityksesi alv-tunnus. SignHero kerää arvonlisäveroa asiakkailta Suomen ja EU:n verolainsäädännön mukaisesti. EU:n alueella toimivien yritysten tulee ilmoittaa voimassa oleva alv-tunnus. Mikäli maa tai alue jossa olet ei ole valittavissa rekisteröinnin yhteydessä, voit ottaa yhteyttä meihin vaihtoehtoisia maksutapoja varten.",
        "PaymentExplanation3" : "Jos sinulla ei ole mahdollisuutta lisätä luottokorttia automaattista laskutusta varten, ota yhteyttä SignHeroon osoitteessa <a class=='elem-pink' href='mailto:hello@signhero.io'>hello@signhero.io</a>!",
        "PaymentExplanation4" : "Onko ongelmia laskusi kanssa? Tai kysyttävää palvelusta? Ota meihin yhteyttä osoitteessa <a class=='elem-pink' href='mailto:hello@signhero.io'>hello@signhero.io</a>!",
        "CountersPending" : "Tilastojen laskenta on kesken. Yritä uudestaan muutaman minuutin kuluttua.",
        "WholeOrganisation" : "Koko organisaatio",
        "OrganisationHas" : "Organisaatiollasi on tällä hetkellä",
        "ActiveUsers" : "aktiivista käyttäjää ja",
        "InactiveUsers" : "ei-aktiivista käyttäjää.",
        "ChartDescriptionOrg" : "Allaoleva kaavio näyttää koko organisaation luomat allekirjoitusprosessit ja asiakirjat.",
        "ChartDescriptionUser" : "Allaoleva kaavio näyttää valittujen käyttäjien luomat allekirjoitusprosessit.",
        "SignaturesCreated":"luotuja allekirjoituksia",
        "AllSignatureHas":"Valitulla ajanjaksolla kaikkien allekirjoitusten määrä on ",
        "AdvancedSignatureHas":"ja vahvojen allekirjoitusten määrä on ",
        "AllSignature" : "Kaikki allekirjoitukset",
        "ChartDescriptionSignature" : "Alla oleva kaavio näyttää koko organisaatiossa luodut allekirjoitukset.",
        "SelectSignatureTypes" : " Valitse allekirjoitustyypit",
        "AllSignatureInPeriod" : " Kaikki allekirjoitukset ajanjaksolla",
        "SelectUsers" : "Valitse käyttäjät",
        "From" : "Alkaen",
        "To" : "Päättyen",
        "AmountOfDocuments" : "Valitulla ajanjaksolla uusien asiakirjojen määrä on",
        "AmountOfSignatureProcesses" : "ja uusien allekirjoitusprosessien määrä on",
        "AmountOfUsers" : "ja uusien käyttäjien määrä on",
        "Processes" : "Prosesseja",
        "Documents" : "Asiakirjoja",
        "Users" : "Käyttäjiä",
        "UserActivity" : "Käyttäjien aktiivisuus",
        "Signatures": "Allekirjoitukset",
        "User" : "Käyttäjä",
        "Inactive" : "Ei-aktiivinen",
        "SignatureProcesses" : "Allekirjoitusprosesseja ajanjaksolla",
        "AllSignatureProcesses" : "Yhteensä allekirjoitusprosesseja",
        "DownloadSignatureStatsMessage": "Lataa allekirjoitustilastot CSV-tiedostona.",
        "DownloadSignatureStatsLink": "Lataa allekirjoitustilastot",
        "Cancel" : "Peruuta",
        "FreeTrialAlert1" : "Organisaatiollasi on",
        "FreeTrialAlert2" : "päivää ilmaista kokeilujaksoa jäljellä!",
        "ContactAdmin" : "Ei aktiivista tilausta, ole hyvä ja ota yhteyttä järjestelmänvalvojaasi!",
        "PaymentOverdueAlert" : "Maksu myöhässä, lisää voimassa oleva kortti",
        "PaymentOverdueAlertIgnore" : "Jos olet jo lisännyt voimassa olevan kortin, jätä tämä viesti huomioimatta",
        "PleaseAddValidCPayment" : "Ei aktiivista tilausta, lisää kelvollinen maksutapa!",
        "PleaseConfirmPayment" : "Kortin todennus vaaditaan. Tarkista \"Laskutushistoria\"!",
        "PleaseConfirmPaymentMainPage" : "Ei vaadi kelvollista maksutapaa tai korttitunnistusta. Tarkista \"Minun tilaukseni\"!",
        "ConfirmDeletePaymentMethod": "Haluatko varmasti poistaa laskutus- ja korttitietosi??",
        "ConsentDialogTitle": "Tilaa SignHero säännöllisellä laskutuksella?",
        "ConsentText1": "SignHero on nopea ja helppo sähköisen allekirjoituksen palvelu. Lähetä ja allekirjoita PDF-tiedostoja, seuraa prosesseja, muistuta allekirjoittajia, seuraa aukotonta kirjausketjua, työskentele ryhmissä ja arkistoi allekirjoitettuja asiakirjoja.",
        "ConsentText2": "SignHero maksaa 5 € kuukaudessa per aktiivinen käyttäjä + ALV. Epäaktiivisia käyttäjiä ei lasketa mukaan laskuusi. Jos lisäät tai poistat käyttäjiä laskutusjakson aikana, laskemme automaattisesti kuinka paljon vähemmän tai enemmän seuraavan laskun pitäisi olla perustua siihen, kuinka paljon laskutusjaksosta on jäljellä muutoksen tekohetkellä. Rekisteröitymisen yhteydessä annamme sinulle 14 päivän ilmaisen kokeilujakson, jonka jälkeen sinun on rekisteröitävä luottokorttitiedot kuukausittain toistuvia maksuja varten. Jos haluat jatkaa palvelun käyttöä, luottokorttiasi veloitetaan automaattisesti kuukausittain samana päivänä sen mukaan, kuinka monta käyttäjää olet rekisteröitynyt palveluun. Luottokorttisi rekisteröinnin jälkeen tilauksesi on voimassa onnistuneen maksun jälkeen ja pysyy voimassa, ellei maksu epäonnistunut. Saat tilaukseesi liittyen seuraavanlaisia sähköposteja:",
        "ConsentText3": "Sinun on syötettävä yrityksesi ALV-numero, koska SignHero kerää asiakkailta arvonlisäveron Suomen ja EU:n verolainsäädännön mukaisesti. EU:n alueella sijaitseville yrityksille tulee olla voimassa oleva ALV-tunnus. Jos maasi ei ole mukana luettelossa, voit ottaa meihin yhteyttä osoitteessa hello@signhero.io vaihtoehtoisista maksutavoista sopimiseksi. Kerrothan meille viestissäsi jos et löytänyt maatasi ja päätät olla käyttämättä palvelua tästä syystä. Saatamme lisätä maasi tulevaisuudessa ja voimme silloin kertoa tästä sinulle!",
        "ConsentText4": "Voit peruuttaa tilauksen poistamalla luottokorttisi ja nykyinen tilaus peruuntuu tilausjakson lopussa. Voit myös peruuttaa tilauksen suoraan klikkaamalla Peruuta tilaus -painiketta.",
        "ConsentEmailBullet1": "muistutussähköposti ennen kokeilujakson päättymistä",
        "ConsentEmailBullet2": "kuitti kuukausimaksusta määrittämääsi sähköpostiosoitteeseen",
        "ConsentEmailBullet3": "ilmoitus 7 päivää ennen jokaista maksutapahtumaa",
        "ConsentCheckBoxLabel1": "Olen lukenut yllä olevat tiedot",
        "ConsentCheckBoxLabel2": "Suostun tilaamaan SignHero-palvelun säännöllisellä laskutuksella",
        "Agree": "Hyväksyn",
        "CurrentPlan" : "Nykyinen tilaus",
        "SignHeroSubscription" : "SignHero-tilaus",
        "CancelPlan" : "Peruuta tilaus",
        "CreatePlan" : "Luo tilaus",
        "PriceInfo" : "5€ per kuukausi per aktiivinen käyttäjä",
        "TaxTooltip" : "Huomioithan, että hinta ei sisällä arvonlisäveroa/alv.",
        "PleaseAddValidCard" : "Lisää kelvollinen maksutapa",
        "CancelPlanConfirm" : "Haluatko varmasti peruuttaa nykyisen tilauksesi?\nMenetät jäljellä olevan kokeilujakson tai maksullisen tilauksen jäljellä olevan ajan. Jäljellä olevaa aikaa ei korvata.",
        "CreatePlanConfirm" : "Haluatko varmasti luoda uuden tilauksen?",
        "numbersDidNotMatch": "Koodit eivät täsmää. Yritä uudelleen.",
        "toProceedEnterCode": "Jatka kirjoittamalla koodi: ",
        "subStatus!trialing" : "Kokeilu päättyy ",
        "subStatus!active" : "Aktiivinen",
        "subStatus!canceled" : "Peruutettu",
        "subStatus!incomplete" : "Epätäydellinen",
        "subStatus!incomplete_expired" : "Vanhentunut",
        "subStatus!past_due" : "Myöhässä",
        "ConfirmCreateSubNow" : "Voit luoda uuden tilauksen nyt tai myöhemmin ‘Minun tilaukseni’ sivulta.",
        "CreateSubscriptionNow" : "Luo nyt",
        "CreateSubscriptionLater" : "Myöhemmin",
        "Country!Australia" : "Australia",
        "Country!Austria" : "Itävalta",
        "Country!Belgium" : "Belgia",
        "Country!Brazil" : "Brasilia",
        "Country!Bulgaria" : "Bulgaria",
        "Country!Canada" : "Kanada",
        "Country!Chile" : "Chile",
        "Country!Croatia" : "Kroatia",
        "Country!Cyprus" : "Kypros",
        "Country!CzechRepublic" : "Tšekki",
        "Country!Denmark" : "Tanska",
        "Country!Estonia" : "Viro",
        "Country!Finland" : "Suomi",
        "Country!France" : "Ranska",
        "Country!Germany" : "Saksa",
        "Country!Greece" : "Kreikka",
        "Country!HongKong" : "Hongkong",
        "Country!Hungary" : "Unkari",
        "Country!India" : "Intia",
        "Country!Indonesia" : "Indonesia",
        "Country!Ireland" : "Irlanti",
        "Country!Israel" : "Israel",
        "Country!Italy" : "Italia",
        "Country!Japan" : "Japani",
        "Country!Korea" : "Korea",
        "Country!Latvia" : "Latvia",
        "Country!Liechtenstein" : "Liechtenstein",
        "Country!Lithuania" : "Liettua",
        "Country!Luxembourg" : "Luxemburg",
        "Country!Malaysia" : "Malesia",
        "Country!Malta" : "Malta",
        "Country!Mexico" : "Meksiko",
        "Country!Netherlands" : "Alankomaat",
        "Country!NewZealand" : "Uusi-Seelanti",
        "Country!Norway" : "Norja",
        "Country!Poland" : "Puola",
        "Country!Portugal" : "Portugali",
        "Country!Romania" : "Romania",
        "Country!RussianFederation" : "Venäjän federaatio",
        "Country!SaudiArabia" : "Saudi-Arabia",
        "Country!Singapore" : "Singapore",
        "Country!Slovakia" : "Slovakia",
        "Country!Slovenia" : "Slovenia",
        "Country!SouthAfrica": "Etelä-Afrikka",
        "Country!Spain" : "Espanja",
        "Country!Sweden" : "Ruotsi",
        "Country!Switzerland" : "Sveitsi",
        "Country!Taiwan" : "Taiwan",
        "Country!Thailand" : "Thaimaa",
        "Country!UnitedArabEmirates" : "Arabiemiirikunnat",
        "Country!UnitedKingdom" : "Iso-Britannia",
        "Country!UnitedStates" : "Yhdysvallat",
        "TaxType!au_abn" : "Australian yritysnumero (AU ABN)",
        "TaxType!au_arn" : "Australian veroviraston viitenumero",
        "TaxType!eu_vat" : "Eurooppalainen ALV-numero",
        "TaxType!br_cnpj" : "Brasilian CNPJ-numero",
        "TaxType!br_cpf" : "Brasilian CPF-numero",
        "TaxType!ca_bn" : "Kanadalainen BN",
        "TaxType!ca_gst_hst" : "Kanadan GST/HST-numero",
        "TaxType!ca_pst_bc" : "Kanadan PST-numero (British Columbia)",
        "TaxType!ca_pst_mb" : "Kanadan PST-numero (Manitoba)",
        "TaxType!ca_pst_sk" : "Kanadan PST-numero (Saskatchewan)",
        "TaxType!ca_qst" : "Kanadalainen QST-numero (Québec)",
        "TaxType!cl_tin" : "Chilen verotunnus",
        "TaxType!hk_br" : "Hongkongin BR-numero",
        "TaxType!in_gst" : "Intian GST-numero",
        "TaxType!id_npwp" : "Indonesian NPWP-numero",
        "TaxType!il_vat" : "Israelin ALV-numero",
        "TaxType!jp_cn" : "Japanilainen yritysnumero",
        "TaxType!jp_rn" : "Japanissa rekisteröityjen ulkomaisten yritysten rekisterinumero",
        "TaxType!kr_brn" : "Korean BRN",
        "TaxType!li_uid" : "Liechtensteinin UID-numero",
        "TaxType!my_frp" : "Malesialainen FRP-numero",
        "TaxType!my_itn" : "Malesialainen ITN",
        "TaxType!my_sst" : "Malesialainen SST-numero",
        "TaxType!mx_rfc" : "Meksikolainen RFC-numero",
        "TaxType!nz_gst" : "Uuden-Seelannin GST-numero",
        "TaxType!no_vat" : "Norjan ALV-numero",
        "TaxType!ru_inn" : "Venäjän INN",
        "TaxType!ru_kpp" : "Venäjän KPP",
        "TaxType!sa_vat" : "Saudi-Arabian ALV-numero",
        "TaxType!sg_gst" : "Singaporean GST",
        "TaxType!sg_uen" : "Singaporean UEN",
        "TaxType!za_vat" : "Etelä-Afrikan ALV-numero",
        "TaxType!es_cif" : "Espanjan CIF-numero",
        "TaxType!ch_vat" : "Sveitsin ALV-numero",
        "TaxType!tw_vat" : "Taiwanin ALV",
        "TaxType!th_vat" : "Thaimaan ALV-numero",
        "TaxType!ae_trn" : "Yhdistyneet arabiemiirikunnat TRN",
        "TaxType!gb_vat" : "Iso-Britannian ALV-numero",
        "TaxType!us_ein" : "Yhdysvallat EIN",
        "SignatureTypes" : "Allekirjoitustyypit",
        "SignatureDefault" : "Oletus",
        "SignatureSaved": "Muutokset on tallennettu!",
        "SignatureType!email-based-signature" : "Sähköpostipohjainen SignHero-allekirjoitus",
        "SignatureType!smart-id-signature" : "Virolainen Smart-ID",
        "SignatureType!fi-ftn-advanced-signature" : "Suomen verkkopankki ja mobiilivarmenne",
        "SignatureType!fi-ftn-intesi-adv-signature" : "Suomen verkkopankki - Intesi",
        "SignatureTypeCountInPeriod!email-based-signature" : "Sähköpostipohjainen SignHero-allekirjoitus ajanjaksolla",
        "SignatureTypeCountInPeriod!smart-id-signature" : "Virolainen Smart-ID-allekirjoitus ajanjaksolla",
        "SignatureTypeCountInPeriod!fi-ftn-advanced-signature" : "Suomalaisen verkkopankin ja mobiilivarmenteen allekirjoitus ajanjaksolla",
        "SignatureTypeCountInPeriod!fi-ftn-intesi-adv-signature" : "Suomalaisen verkkopankin - Intesi allekirjoitus ajanjaksolla",
        "BusinessAccount" : "Yritystili",
        "OnlyBusinessAccountsSupported" : "Vain yritystilejä tuetaan valitussa maassa.",
        "MyWallet" : "Lompakkoni"
    },
    "wallet" : {
        "EstimatedAdvancedSignatureCost" : "Allekirjoituksen arvioitu hinta: €",
        "Error!InsufficientWalletBalance" : "Lompakon saldo ei riitä",
        "AvailableBalance" : "Käytettävissä oleva saldo (euroa)",
        "TopUp" : "Täytä",
        "Transactions" : "Maksutapahtumat",
        "DownloadTransactionDesc" : "Lataa maksutapahtumatiedot csv-tiedostona",
        "SearchTransactions" : "Hae sähköpostilla tai prosessitunnuksella",
        "SelectTransactionTypes" : "Valitse maksutapahtumatyypit",
        "TransactionDate" : "Päivämäärä",
        "TransactionTitle" : "Otsikko",
        "TransactionAmount" : "Määrä",
        "TransactionBalance" : "Saldo",
        "TransactionInvoice" : "Lasku",
        "InvoicePDF" : "Lataa",
        "ChooseRechargeMethod" : "Valitse maksutapa",
        "BankTransfer" : "Pankkisiirto",
        "OnlinePayment" : "Verkkomaksu",
        "TopUpInfo" : "Info",
        "TopUpExplanation1" : "SignHero-lompakkoa käytetään vahvojen allekirjoitusten maksuihin. Vaikka SignHeron sähköpostiin perustuvat allekirjoitukset sisältyvät tilausmaksuun, vahvoista allekirjoituksista peritään aina erillinen maksu.",
        "TopUpExplanation2" : "Voit lisätä varoja lompakkoosi kolmella tavalla: pankkisiirrolla, luottokorttimaksulla tai MobilePaylla.",
        "TopUpExplanation3" : "Kun olet täyttänyt lompakkosi, varat käytetään automaattisesti vahvojen allekirjoitusten maksuihin. Kun käynnistät allekirjoitusprosessin vahvalla allekirjoituksella, tapahtuu seuraavasti:",
        "TopUpExplanation3Bullet1" : "Arvioitu hinta näytetään Lähetä-painikkeen alla.",
        "TopUpExplanation3Bullet2" : "SignHero tarkistaa, että sinulla on riittävästi varoja lompakossasi allekirjoitusprosessin loppuunsaattamiseksi. Jos lompakossasi ei ole tarpeeksi varoja, sinun on laitettava sinne lisää ennen jatkamista.",
        "TopUpExplanation3Bullet3" : "Allekirjoitusprosessissa käytetty maksu vähennetään automaattisesti lompakostasi, kun kokonaissumma voidaan laskea. Kokonaissumma lasketaan, kun prosessi on valmis, peruutettu tai hylätty.",
        "TopUpExplanation3Bullet4" : "Tapahtumat kirjataan Lompakkoni -osioon.",
        "TopUpExplanation4" : "Allekirjoitushinnasto:",
        "TopUpExplanation4Bullet1" : "Suomalainen pankkitunnus ja mobiilivarmenne: 1 € + ALV.",
        "TopUpExplanation5" : "Maksu lasketaan jokaisesta suoritetusta allekirjoituksesta.",
        "TopUpExplanation6" : "Pitämällä saldoa lompakossasi varmistat sujuvan ja nopean käytön aina, kun tarvitset vahvaa allekirjoitusta. Jos sinulla on kysyttävää tai tarvitset apua, ota yhteyttä tukeemme osoitteessa <a class=='elem-pink' href='mailto:hello@signhero.io'>hello@signhero.io</a>",
        "PleaseCompleteBankTransfer" : "Sinulla on keskeneräinen pankkisiirtomaksu",
        "PleaseCompleteOnlinePayment" : "Sinulla on keskeneräinen verkkomaksu",
        "PAYMENT" : "MAKSU",
        "BankTransferDesc" : "Maksa SignHerolle pankkisiirrolla käyttämällä alla olevia IBAN-tietoja. Päivitämme saldosi, kun olemme vastaanottaneet maksutapahtumasi. Voi kestää n. vuorokauden ennen kuin saldo päivittyy.",
        "BankTransferPartiallyPaidDesc" : "Olet maksanut ${amountPaid} pankkisiirrolla. Suorita maksu loppuun siirtämällä jäljellä oleva ${amountRemaining}. Voi kestää n. vuorokauden ennen kuin saldo päivittyy.",
        "AvailableCashBalanceNotice" : "Huomautus: Sinulla on käyttämätön saldo ${availableCashBalance} aiemmista pankkisiirroista.",
        "IBAN" : "IBAN",
        "AccountHolder" : "Tilin haltija",
        "BICCode" : "BIC-koodi",
        "Country" : "Maa",
        "AvailableBalanceTitle" : "Käytettävissä oleva saldo (euroa): ",
        "BankTransferMinTopUpAmount" : "Maksa (vähintään ${bankTransferMinTopUpValue})",
        "OnlineMinTopUpAmount" : "Maksa (vähintään ${onlineMinTopUpValue})",
        "EnterANumber" : "Syötä summa ",
        "YouArePaying" : "Maksat: ",
        "CancelPayment" : "Peruuta maksu",
        "Proceed" : "Jatka",
        "PayNow" : "Maksa nyt",
        "Done" : "Valmis",
        "TransactionDetails" : "Tapahtuman tiedot",
        "TransactionDescription" : "Tapahtuman kuvaus",
        "Title" : "Otsikko",
        "Datetime" : "Päivämäärä ja aika",
        "Balance" : "Saldo",
        "Invoice" : "Laata lasku",
        "Amount" : "Määrä",
        "ProcessUuid" : "Prosessitunnus",
        "LastUpdatedBy" : "Viimeksi päivittänyt",
        "Prepayment" : "Ennakkomaksu",
        "FinalCost" : "Lopullinen hinta",
        "TransactionType!incoming" : "Saapuva",
        "TransactionType!outgoing" : "Lähtevä",
        "TransactionTitle!process-prepayment" : "Allekirjoitusprosessin ennakkomaksu",
        "TransactionTitle!finalise-cost-process-completed" : "Prosessi valmis",
        "TransactionTitle!finalise-cost-process-cancelled" : "Prosessi keskeytettiin",
        "TransactionTitle!finalise-cost-process-declined" : "Prosessi hylättiin",
        "TransactionTitle!initialization" : "Lompakon luonti",
        "TransactionTitle!top-up-bank-transfer" : "Asiakas siirsi saldo",
        "TransactionTitle!top-up-online-payment" : "Asiakas maksoi",
        "TransactionDes!process-prepayment" : "Ennakkomaksu vahvan allekirjoitusprosessin luomisesta",
        "TransactionDes!finalise-cost-process-completed" : "Prosessi valmis. Hyvitetty saldo vahvasta allekirjoitusprosessista",
        "TransactionDes!finalise-cost-process-cancelled" : "Prosessi keskeytettiin. Hyvitetty saldo vahvasta allekirjoitusprosessista",
        "TransactionDes!finalise-cost-process-declined" : "Prosessi hylättiin. Hyvitetty saldo vahvasta allekirjoitusprosessista",
        "TransactionDes!initialization" : "Lompakon luonti",
        "TransactionDes!top-up-bank-transfer" : "Asiakas siirsi ",
        "TransactionDes!top-up-online-payment" : "Asiakas maksoi ",
        "paymentStatus!requires_action" : "Keskeneräinen",
        "paymentStatus!requires_payment_method" : "Keskeneräinen",
        "paymentStatus!partially_paid" : "Osittain maksettu",
        "NoteInvalidAddress" : "Huomautus: Sinulla ei ole kelvollista laskutusosoitetta. Lisää laskutusosoite ennen kuin täytät lompakkosi.",
        "NoteValidAddress" : "Laskutustiedot",
    },
    "signflow": {
        "Create": "Luo",
        "Error!UserExists": "Valitsemasi sähköposti tai käyttäjätunnus on jo käytössä. Jos olet unohtanut salasanasi, klikkaa \"Kirjaudu\" ja käytä \"Vaihda salasana\" -linkkiä.",
        "ButtonBack": "Takaisin",
        "ButtonGoBack": "Takaisin",
        "ButtonNext": "Seuraava",
        "SearchSignFlows": "Hae allekirjoittajalla, dokumentilla tai otsikolla",
        "AllFlows": "Kaikki",
        "PendingMeFlows": "Odottaa allekirjoitustani",
        "PendingOthersFlows": "Odottaa muiden allekirjoituksia",
        "CanceledFlows": "Peruutetut",
        "CompletedFlows": "Valmiit",
        "ShowGroupProcesses": "Organisaation kaikki prosessit",
        "CancellationReasons": "Syy: ${cancellationReasons}",
        "Documents" : "Asiakirjat:",
        "SignedDocuments" : "Allekirjoitetut asiakirjat:",
        "UnsignedDocuments" : "Allekirjoittamattomat asiakirjat",
        "GenerateDigests" : "Luo tiivisteet",
        "DigestTitle" : "Tiiviste:",
        "FtnSignatureTitle" : "XML-DSig allekirjoitus (Suomen verkkopankki ja mobiilivarmenne):",
        "FtnSignatureXmlFile" : "Asiakirjan-allekirjoitus.xml",
        "SignatureValidationFilesDesc" : "Tarvittaessa käytä näitä tiedostoja todistamaan että allekirjoittaneella oli aikomus allekirjoittaa.",
        "SignatureValidationFiles" : "Allekirjoituksen vahvistustiedostot",
        "CreatedByTitle": "Luonut:",
        "CanceledByTitle": "Peruuttanut:",
        "PendingSignaturesTitle": "Odottaa allekirjoituksia:",
        "PendingMeAlert": "<span class='elem-pink'>Sinä</span> et ole allekirjoittanut.",
        "PendingOthersAlert": "Odottaa <span class='elem-pink'>${count}</span> henkilön allekirjoitusta",
        "SignerStatusTitle": "Tila:",
        "ReminderHistory": "Muistutushistoria",
        "SignerStatus1": "Odottaa, kutsu avattiin ensimmäisen kerran ",
        "SignerStatus2": "Odottaa, kutsua ei avattu.",
        "SignerStatus3": "Allekirjoitettu ",
        "SignerStatus4": "Odottaa tekijää.",
        "SignerStatus5": "Odottaa.",
        "SendReminders": "Muistuta sähköpostilla",
        "NoProcessesFound": "Yhtään prosessia ei löytynyt",
        "LastEmailReminder": "Viimeisin muistutus: <b>${timestamp}</b>",
        "NoRemindersYet": "Ei lähetettyjä muistutuksia",
        "EmailReminderSuccess": "Sähköpostimuistutukset lähetetty onnistuneesti.",
        "ReminderSentBy": "lähettaja oli",
        "CancelProcessDialogTitle": "Peruuta prosessi",
        "CancellationReasonsPlaceHolder": "Peruuttamisen syyt",
        "CancelProcess": "Peruuta",
        "DeleteProcess": "Poista prosessi",
        "RestartProcess": "Aloita uudelleen",
        "ConfirmDeleteProcess": "Oletko varma, että haluat poistaa prosessin?",
        "ResendDownloadInvitation": "Lähetä uusi latauslinkki",
        "DownloadInvitationEmailSuccess": "Sähköposti lähetetty.",
        "DownloadInvitationEmailFail": "Lähetys epäonnistui",
        "Me": "Minä",
        "SignFlowDocumentsHeading": "Mitä allekirjoitetaan?",
        "FlowSettings": " Ekstrat",
        "FlowTitlePlaceholder": "Nimeä prosessi, niin löydät sen vielä helpommin",
        "Signers": "Allekirjoittajat *",
        "AddSigner": "Lisää allekirjoittajia",
        "AddSignerGroup": "Lisää allekirjoittajia tiedostosta",
        "AddressBookChooseFile": "Valitse tiedosto",
        "AddressBookInfoHeader": "Kuinka lisäät allekirjoittajia tiedostosta",
        "AddressBookInfo": "Lataa .txt tai .csv päätteinen tiedosto, jossa jokainen allekirjoittaja on erillisellä rivillä ja kentät on erotettu pilkulla.",
        "AddressBookInfoExampleHeader": "Esimerkiksi tiedosto nimeltä HallituksenJäsenet.txt, jossa on seuraavanlainen sisältö:",
        "AddressBookInfoExampleContent": "Antero Korhonen, antero.korhonen@esimerkki.com<br>Maria Heikkinen, maria.heikkinen@esimerkki.com<br>Matti Virtanen, matti.virtanen@esimerkki.com<br>Liisa Mäkelä, liisa.makela@esimerkki.com",
        "NotAnAddressBook": "Tiedosto ei sisältänyt nimiä ja sähköpostiosoitteita.",
        "CheckFileEncoding": "Tiedosto sisältää merkkejä, joita ei tueta. Varmista, että tiedosto on UTF-8-muodossa.",
        "SignersEmail": "Sähköposti",
        "EmailSubject": "Aihe:",
        "EmailMessage": "Viesti:",
        "EditInvitation": "Muokkaa allekirjoituskutsua",
        "UseDragAndDropSignature": "Aseta allekirjoituspaikat asiakirjoihin",
        "NoPrintedEmail": "Piilota sähköpostiosoitteet",
        "StartNow": "Allekirjoita nyt",
        "StartSigningNow": "Voit allekirjoittaa asiakirjat nyt tai myöhemmin Kesken-välilehdestä.",
        "RequestEmailSendSigners": "Allekirjoituspyyntö on lähetetty allekirjoittajille. ",
        "InformProgressNotifications": "Ilmoitamme sinulle sähköpostitse allekirjoitusprosessin etenemisestä.",
        "Later": "Myöhemmin",
        "Ok" : "Ok",
        "DefaultEmailSubject": "${$cc_user_info.name} lähetti allekirjoituskutsun",
        "DefaultEmailMessage": "${$cc_user_info.name}, ${$cc_user_info.organization.display_name}, (${$cc_user_info.email}) kutsui sinut allekirjoittamaan SignHeron avulla.",
        "Signer": "Allekirjoittaja",
        "Created": "Luotu",
        "Canceled": "Peruuntunut",
        "Error!Unexpected": "Tapahtui odottamaton virhe. Yritä myöhemmin uudestaan.",
        "Sign": "Allekirjoita",
        "Send": "Lähetä",
        "GoToSign": "Siirry allekirjoittamaan",
        "SignersName": "Etu- ja sukunimi",
        "Download": "Lataa",
        "SignedBy": "Allekirjoittaja(t):",
        "Complete": "Arkisto",
        "WhoIsSigning": "Kuka allekirjoittaa?",
        "OnlyMe": "Vain minä",
        "OnlyOthers": "Vain muut",
        "MeAndOthers": "Minä ja muut",
        "Start": "Aloita",
        "Email": "Sähköposti",
        "Add": "Add",
        "numbersDidNotMatch": "Numerot eivät täsmää. Yritä uudestaan.",
        "toProceedEnterCode": "Jatkaaksesi syötä luku: ",
        "Confirm": "Jatka",
        "Continue": "Jatka",
        "Cancel": "Peru",
        "Reset": "Peru",
        "selectFromDisk": "Tiedostosta",
        "fileTooLarge": "Suurin sallittu tiedostokoko ${maxSize}.",
        "allFilesTooLarge": "Kaikkien tiedostojen enimmäiskoko on ${maxSize}. Yrität nyt ladata ${uploadedSize}.",
        "fileNumberTooLarge": "Liitä enintään ${maxFileNumber} tiedostoa.",
        "uploadError": "Voi ei, ongelma asiakirjan kanssa. Joko väärä tiedostomuoto tai se on jo allekirjoitettu.",
        "select": "Valitse",
        "OngoingProcess" : "Keskeneräiset prosessit",
        "Error!FilesOutdated": "Istunto ehti vanhentua. Lataa tiedostot ja tarvittaessa täytä tiedot uudelleen",
        "Error!DocumentError" : "Istunto ehti vanhentua tai asiakirjojen kanssa tapahtui jokin virhe, ehkä tiedostojen muoto on väärä. Lataa tiedostot ja täytä tiedot tarvittaessa uudelleen.",
        "OnlyPdf": "Sallitut tiedostomuodot pdf",
        "OnlyCancelledProcessAllowed": "You can only restart cancelled process.",
        "CreateNewTags" : "Luo uusi tunniste",
        "CreateNewTagsHint" : "Paina enter lisätäksesi uusi tunniste: enintään 20 merkkiä",
        "ManageTags" : "Hallitse tunnisteita",
        "Tags" : "Tunnisteet",
        "FilterByTag" : "Suodata tunnisteen mukaan",
        "ErrorTagExists" : "Tunniste on jo olemassa!   ",
        "MyOrganization" : "Minun organisaationi",
        "OtherOrganizations" : "Muut organisaatiot",
        "OwnedBy" : "Omistuksessa:",
        "Update" : "Päivitä",
        "Edit" : "Muokkaa",
        "Save" : "Tallenna",
        "SavedTemplates" : "Valitse kutsupohjat",
        "CreateTemplateTitle" : "Tallenna kutsupohja",
        "TemplateCreated" : "Kutsupohja tallennettu.",
        "DeleteTemplateTitle" : "Poista jaettu kutsupohja",
        "DeleteTemplateConfirm" : "Haluatko varmasti poistaa sähköpostikutsupohjan? Se vaikuttaa koko organisaatioon.",
        "ExplainTemplate1" : "Organisaatiolla ei ole tallennettuja kutsupohjia. Kutsupohjat voidaan tallentaa niiden muokkaamisen jälkeen.",
        "ExplainTemplate2" : "Voit tallentaa - mutta ei tarvitse! ",
        "ExplainTemplate3" : "Nykyistä allekirjoituskutsua voidaan käyttää tallentamatta.",
        "ExplainTemplate4" : "Voit tallentaa usein käytettyjä kutsupohjia. Luodut pohjat näkyvät koko organisaatiolle ja kuka tahansa organisaatiossa voi muokata tai poistaa niitä.\nNykyistä allekirjoituskutsua voidaan käyttää tallentamatta.",
        "UpdateTemplate" : "Kutsupohja on kaikkien organisaation jäsenten nähtävissä, muokattavissa ja poistettavissa.\n\nPohjan nimi: ",
        "DeleteTemplate" : "Kutsupohja poistettu.",
        "TemplateNoname" : "noname",
        "Error!TemplateNameExist": "Kutsupohjan nimi on jo olemassa, korvatanko se?",
        "Error!TemplateNameEmpty": "Kutsupohjan nimi puuttuu!",
        "ProcessesTabName1" : "Omat prosessit",
        "ProcessesTabName2" : "Allekirjoituskutsuni",
        "ShowOnly12MonthInfo" : "Näytä vain prosessit viimeisen 12 kuukauden ajalta",
        "MaxSigners" : "Prosessin allekirjoittajien enimmäismäärä on ",
        "MaxSigners1" : "Poista ",
        "MaxSigners2" : " allekirjoittajaa.",
        "MaxSigners3" : "Enemmän allekirjoittajia ei voida lisätä.",
        "SelectSignatureTypes" : "Valitse allekirjoitustyypit",
        "AllowedSignatureTypes" : "Sallitut allekirjoitustyypit",
        "AllSigners" : "Kaikki allekirjoittajat",
        "SwitchAdvancedSignature" : "Vaihda vahvaan allekirjoitukseen",
        "SwitchBasicSignature" : "Siirry sähköpostipohjaiseen SignHero-allekirjoitukseen",
        "BasicLevel" : "Sähköpostipohjainen SignHero-allekirjoitus",
        "AdvancedLevel" : "Vahva allekirjoitus",
        "BasicLevelInfo" : "Kun sähköpostipohjainen SignHero-allekirjoitus on valittu, kaikki allekirjoittajat vaihdetaan käyttämään tätä allekirjoitustyyppiä.",
        "AdvancedLevelInfo" : "Kun vahva allekirjoitus on valittu, kaikki allekirjoittajat päivitetään valituilla allekirjoitustyypeillä.",
        "Apply" : "Ota käyttöön"
    },
    "message": {
        "IForgotMyPassword" : "Vaihda salasana",
        "TermsOfUse": "Käyttöehdot",
        "ThisLocale": "FI",
        "locale!fi": "Suomi - FI",
        "locale!en": "English - EN",
        "locale!pl": "Polski - PL",
        "locale!de" : "Deutsch - DE",
        "locale!sv" : "Svenska - SV",
        "locale!es" : "Español - ES",
        "locale!fr" : "Français - FR",
        "locale!nl" : "Nederlands - NL",
        "locale!th" : "ภาษาไทย - TH",
        "ReportProblem": "Raportoi onglemasta",
        "NameAndSurname": "Nimesi",
        "SignIn": "Kirjaudu",
        "MustBeEmail": "Syötä e-mail-osoite.",
        "MustBeValidFullName": "Anna kelvollinen nimi.",
        "HyperlinksNotAllowed": "Hyperlinkit eivät ole sallittuja.",
        "MustBeUniqueEmail": "Tämä sähköpostiosoite on jo lisätty.",
        "EnterValidCharacters": "Syötä vain tuettuja merkkejä.",
        "UserSignedUp": "Tervetuloa SignHeroon! Lähetimme sinulle sähköpostin, jossa on ohjeet tilisi aktivointiin. Jos sinulla on jo tili, voit vaihtaa salasanasi kirjautumissivulla.",
        "Create": "Luo",
        "YouHaveBeenSignedOut": "Sinut on kirjattu ulos.",
        "Error!UserExists": "Valitsemasi sähköposti tai käyttäjätunnus on jo käytössä. Jos olet unohtanut salasanasi, klikkaa \"Kirjaudu\" ja käytä \"Vaihda salasana\" -linkkiä.",
        "Save": "Tallenna",
        "ThirdParty": "Muut osapuolet",
        "SessionType!password": "Salasana",
        "SessionType!auth_code": "Sovellus",
        "Error!Unexpected": "Tapahtui odottamaton virhe. Yritä myöhemmin uudestaan.",
        "Sign": "Allekirjoita",
        "Welcome": "Tervetuloa",
        "CompleteProcessInfo": "Et voi allekirjoittaa asiakirjoja, koska allekirjoituspyyntö on suljettu. Olet saattanut allekirjoittaa asiakirjat jo, tai allekirjoituskutsun lähettäjä on peruuttanut pyynnön.",
        "InvalidLoginUrl": "Sivua ei löydy. Ole hyvä ja tarkista sisäänkirjautumisosoitteesi.<br><br>Jos saat tämän viestin uudelleen, hakemasi sivu on saattanut vanhentua tai se on poistettu.",
        "ReasonTitle": "YLEISIMMÄT SYYT",
        "Reason1": "Olet jo allekirjoittanut tämän asiakirjan",
        "Reason2": "Yksi allekirjoittajista on kieltäytynyt allekirjoittamasta",
        "Reason3": "Lähettäjä perui kutsun",
        "Reason4": "Kutsu vanhentui vuoden kuluessa",
        "Reason5": "Syötit väärinkirjoitetun osoitteen",
        "Reason6": "Sait muistutusviestin, joten aiempi kutsulinkki vanhentui",
        "SeeYouSoon" : "Näkemiin",
        "HowToOpenExpiredSession" : "Voit avata istunnon uudelleen klikkaamalla kutsun linkkiä.",
        "ClearCookieWarning": "Jos käytät julkista tai jaettua laitetta, niin muista poistaa selaimesi keksit, historia ja välimuisti.",
        "JustASec": "Hetki",
        "SorryUnexpectedError": "Sivua ei löydy!",
        "InvitationInvalidatedReason": "Linkki voi olla rikki tai kutsu on voitu poistaa.",
        "You": "sinä",
        "DownloadPageTitle": "Näytä ja lataa allekirjoitetut asiakirjat",
        "DownloadPageSignatureValidationFilesDesc" : "Käytä näitä tiedostoja tarvittaessa todistaaksesi, että sinulla oli aikomus allekirjoittaa",
        "AuthInfo" : "Todennustiedot",
        "DownloadLinkInfo" : "Tämä linkki pysyy aktiivisena vuoden allekirjoitusprosessin valmistumisesta.",
        "Download": "Lataa",
        "OpenAuditTrail": "Avaa allekirjoitusloki",
        "ProcessNotFound": "Allekirjoitusprosessia ei löytynyt. Onko prosessin tunnus varmasti oikein?",
        "SignatureEvents": "Allekirjoitukset",
        "SignatureProcessId": "Prosessin tunnus",
        "SignatureProcess": "Allekirjoitusprosessi",
        "SignatureFlowCreated": "Luotu",
        "SignatureFlowStatus": "Tila",
        "NumberOfDocuments": "Asiakirjojen määrä",
        "SignedBy": "Allekirjoittaja(t)",
        "SignTime": "Allekirjoitusaika",
        "AuthType": "Tunnistamistapa",
        "AuthMethod!verification_code": "Varmistettu sähköposti",
        "AuthMethod!access_token": "SignHero-tili",
        "AuthMethod!strong_auth": "Vahva",
        "AuthMethod!otp_token": "Kolmas osapuoli",
        "AuthMethod!otac": "Sähköposti",
        "AuthMethod!signer_token": "Sähköposti",
        "AuthMethod!on_site": "Kasvotusten",
        "ProcessStatus!COMPLETED": "Valmis",
        "ProcessStatus!CANCELED": "Hylätty",
        "ProcessStatus!PENDING": "Odottaa",
        "ProcessStatus!UNKNOWN": "Tuntematon",
        "PrivacyPolicy": "Tietosuoja",
        "EnterYourDetailsBelow": "Syötä tietosi.",
        "SignInHere": "Kirjaudu sisään tästä",
        "SignUpButtonLabel": "Luo tili",
        "WelcomeToSignHero": "Tervetuloa SignHero-palveluun",
        "Complete": "Arkisto",
        "Email": "Sähköposti",
        "Company": "Lisää organisaatio",
        "ConfirmAccount": "Jatka",
        "Confirm": "Jatka",
        "ConfirmAccountTitle": "Aseta salasana <span class='elem-pink'> SignHero</span>-tilillesi",
        "ResetPasswordTitle": "Resetoi <span class='elem-pink'> SignHero</span>-tilisi salasana",
        "ResetPassword": "Aseta uusi salasana",
        "Reset": "Peru",
        "Organization": "Organisaatio",
        "WelcomeMessage": "Kirjaudu <span class='elem-pink'>SignHero</span>-tiliisi",
        "WelcomeText": "Täytä tiedot.",
        "DontHaveSignHeroAccount": "Tarvitsetko <span class='elem-pink'>SignHero</span>-tilin?",
        "GetStarted": "Luo tili",
        "AlreadyHaveSignHeroAccount": "Oletko jo luonut <span class='elem-pink'>SignHero</span>-tilin?",
        "CreateYourSignHeroAccount": "Luo <span class='elem-pink'>SignHero</span>-tili",
        "AcceptTermsOfUseAndPrivacyPolicy": "Jatkamalla hyväksyt SignHeron <a class='terms-disclaimer-link' href='terms-of-use.html' target='_blank'>käyttöehdot</a> ja <a class='terms-disclaimer-link' href='privacy-policy.html' target='_blank'>tietosuojaselosteen</a>.",
        "Docs": "Documentation",
        "YouFoundApi": "Löysit SignHeron APIn!",
        "CheckApiDocumentation": "Käy katsomassa viimeisin <a class=='elem-pink' href='${apiDocsLink}'>API dokumentaatio</a>.",
        "AuditTrail" : "Allekirjoitusloki",
        "EnterProcessId" : "Syötä allekirjoitusprosessin tunnus. Se löytyy asiakirjan allekirjoitussivulta:",
        "SubmitID" : "Lähetä",
        "NoProcessId" : "Syötä prosessin tunnus",
        "ConfirmError1" : "Pyydä uutta kutsua tai salasanan vaihtolinkkiä ",
        "ConfirmError2" : "-sivulta.",
        "SignHeroDotIo" : "SignHero.io",
        "ResetPWError" : "Tämä linkki on luultavasti vanhentunut.<br>Saat uuden, kun resetoit salasanan <a href='/#/signin' class='elem-pink'>kirjautumissivulta</a>.",
        "Error!tax_id_invalid" : "Virheellinen verotunnus.",
        "CreditCardSaved" : "Luottokortti on lisätty.",
        "BillingInformationSaved" : "Laskutustiedot on lisätty.",
        "Error!card_declined" : "Maksujärjestelmä hylkäsi luottokortin.",
        "Error!incorrect_cvc" : "Maksujärjestelmä hylkäsi luottokortin.",
        "Error!expired_card" : "Maksujärjestelmä hylkäsi luottokortin.",
        "Error!processing_error" : "Maksujärjestelmä hylkäsi luottokortin.",
        "Contact" : "Yhteystiedot",
        "name" : "Nimi",
        "email" : "Sähköposti",
        "LoginEmail" : "Sähköposti",
        "EnterYourEmail" : "Sähköpostiosoitteesi:",
        "Password" : "Salasana",
        "ForgotYourPassword" : "Unohtuiko salasana?",
        "RequestConfirmToken" : "Yritä uudestaan",
        "HelloThere" : "Morjens,",
        "ChangePassword" : "Vaihda salasana",
        "CurrentPassword" : "Vanha salasana",
        "NewPassword" : "Uusi salasana",
        "RepeatPassword" : "Toista salasana",
        "PasswordSet" : "Salasana asetettu.",
        "MessageSent" : "Viesti lähetetty. Otamme yhteyttä tarvittaessa.",
        "AuthCodeCreated" : "Kirjautumislinkki postitettu. Seuraa sitä kirjautuaksesi sisään.",
        "SignHeroSentPasswordResetRequest" : "Salasanan vaihtopyyntö lähetetty jos olet rekisteröity käyttäjä. Seuraa sähköpostiasi!",
        "SignHeroSentConfirmTokenRequest" : "Tilin vahvistuspyyntö lähetetty. Seuraa sähköpostiasi!",
        "DefaultDialogTitle" : "",
        "SelectImage" : "Valitse kuva",
        "ImageMaxSizeIs" : "Kuva on liian suuri. Tiedoston maksimikoko on ",
        "PreferredLanguage" : "Kielivalinta",
        "Action!SetPassword" : "Aseta salasana",
        "Action!ConfirmAccount" : "Vahvista tili",
        "TooltipSetPassword" : "Tarkista sähköpostiosoite ja varmista salasanojen täsmäävän.",
        "Action!EditProfile" : "Muokkaa tietoja",
        "Update" : "Tallenna",
        "Delete" : "Poista",
        "Cancel" : "Peru",
        "Accept" : "Hyväksy",
        "Submit" : "Lähetä",
        "Action!SignIn" : "Kirjaudu sisään",
        "Action!SignOut" : "Kirjaudu ulos",
        "Error!PasswordChangeFailedInvalidPassword" : "Vanha salasana oli virheellinen. Ole hyvä ja yritä uudestaan.",
        "Error!InvalidEmail" : "Syötä sähköpostiosoitteesi.",
        "Error!InvalidFullName" : "Anna kelvollinen nimi ja sukunimi",
        "Error!InvalidPasswordReset" : "Salasanan vaihtopyyntö on virheellinen tai vanhentunut. Yritä uudestaan \"Vaihda salasana\" -linkin kautta.",
        "Error!InvalidCredentials" : "Virheellinen sähköposti tai salasana.",
        "Error!InvalidScopes" : "Käyttöoikeutesi eivät salli tämän palvelun käyttöä. Ota yhteyttä ylläpitoon.",
        "Error!TemporarilyLockedOut" : "Sisäänkirjautuminen on tilapäisesti estetty tästä laitteesta johtuen lukuisista epäonnistuneista yrityksistä. Jos olet unohtanut salasanasi, vaihda se \"Vaihda salasana\" -linkistä yllä. Sulku poistuu ${minutes} minuutin kuluttua.",
        "Error!TokenCheckFailed" : "Tämä linkki on luultavasti vanhentunut.<br>Saat uuden, kun resetoit salasanan kirjautumissivulta.",
        "NavigationContactButton" : "Yhteystiedot",
        "NavigationAccountButton" : "Tili",
        "NavigationMenuButton" : "Valikko",
        "NavItemIntroduction": "Esittely",
        "NavItemChooseEasy": "Valitse Helppous",
        "NavItemAccount": "Tili",
        "NavItemAPIsIntegrations": "APIt & Integraatiot",
        "NavItemDoubtsWorries": "Tietoja SignHerosta",
        "NavItemThisIsUs": "Meistä",
        "NavItemContactUs": "Ota meihin yhteyttä",
        "MainSlogan": "SignHero - Ei voisi olla tämän helpompaa!",
        "SubSloganPart1": "Jätä taaksesi monimutkaisuus, ",
        "SubSloganPart2": "tehottomuus ja vanhentuneet tavat.",
        "GoToRegister": "Aloita ilmainen kokeilu",
        "TitleLazy": "Valitse helppous",
        "TextLazyLine1": "Valmiit pohjat ja raapustukset ovat turhia;",
        "TextLazyLine2": "ne edustavat ylisuunniteltuja prosesseja, jotka hukkaavat aikaa ja rahaa.",
        "TextLazyLine3": "SignHeron fanit haluavat vain keskittyä siihen millä on väliä, joten me annamme teille siihen työvälineet.",
        // New localisation for en, fi, sv
        "NEWTitleLazy": "Valitse helppous",
        "NEWTextLazyLine1": "SignHero pitää asiat yksinkertaisena ja poistaa tarpeettomat vaiheet, jotta voit keskittyä olennaiseen.",
        "MetricsTitle": "Keskimääräiset viikottaiset luvut:",
        "Metrics1" : "6000+ SignHeron käyttäjää",
        "Metrics2" : "2000+ allekirjoitettua dokumenttia",
        "Metrics3" : "100+ uutta organisaatiotiliä",
        "TitleAccount": "Tili",
        "TextAccount": "SignHero sisältää kaiken, mitä todella tarvitset e-allekirjoittamiseen:",
        "Feature1": "Lähetä & allekirjoita PDF:iä",
        "Feature2": "Seuraa prosesseja",
        "Feature3": "Muistuta allekirjoittajia",
        "Feature4": "Katsele kirjaustietoja",
        "Feature5": "Työskentele tiimeissä",
        "Feature6": "Arkistoi dokumentteja",
        "UnlimitedSigners": "Rajaton määrä allekirjoittajia ja kutsuja.",
        "EasyRegister": "Rekisteröidy nimelläsi, sähköpostillasi ja organisaatiollasi.",
        "Register": "Luo tilisi",
        "TitlePricing": "Hinnoittelu",
        "MonthlyPrice": "5€ / käyttäjä / kuukausi",
        "MonthlyBill": "(Laskutetaan kuukausittain. Hinta ei sisällä arvonlisäveroa/alv. Hintoihin lisätään kulloinkin voimassa oleva arvonlisävero.)",
        "Benefits1": "Rajaton määrä elektronisia allekirjoituksia",
        "Benefits2": "Arkisto dokumenteillesi",
        "Benefits3": "Organisaatiotili useammalle käyttäjälle",
        "Benefits4": "Allekirjoittajat eivät tarvitse omaa tiliä",
        // New localisation for en, fi, sv
        "NEWTitlePricing": "Hinnoittelu",
        "NEWMonthlyPrice1": "Sähköpostipohjaiset allekirjoitukset",
        "NEWMonthlyPrice2": "5€ / käyttäjä / kuukausi",
        "NEWMonthlyBill": "(Laskutetaan kuukausittain. Hinta ei sisällä arvonlisäveroa/alv. Hintoihin lisätään kulloinkin voimassa oleva arvonlisävero/alv.)",
        "NEWBenefits1": "Rajaton määrä elektronisia allekirjoituksia",
        "NEWBenefits2": "Arkisto dokumenteillesi",
        "NEWBenefits3": "Organisaatiotili useammalle käyttäjälle",
        "NEWBenefits4": "Allekirjoittajat eivät tarvitse omaa tiliä",
        "NEWAdvancedPrice1": "Suomalaiset vahvat allekirjoitukset",
        "NEWAdvancedPrice2": "1€ / allekirjoitus",
        "NEWAdvancedBill": "(Hinta ei sisällä arvonlisäveroa/alv. Hintoihin lisätään kulloinkin voimassa oleva arvonlisävero/alv.)",
        "NEWBenefits5": "Tällä hetkellä saatavilla käyttäjille, joilla on käytössä suomalaiset pankkitunnukset tai mobiilivarmenteet",
        "NEWBenefits6": "Lisää rahaa SignHero-lompakkoosi ja maksa käytön mukaan",
        "ButtonFreeTrial": "Aloita 14 päivän ilmainen kokeilu",
        "CreditCardInfo": "Luottokorttia ei tarvita",
        "TitleAPI": "APIt & integraatiot",
        "IntegrateSignHero": "Integroi allekirjoitusratkaisumme juuri sinne, missä se parhaiten sujuvoittaa työskentelyäsi.",
        "ToIntroBTN": "Esittely",
        "ToDocsBTN": "API-ohjeet",
        "ToGmailBTN": "Gmail",
        "ToZapierBTN": "Zapier",
        "TitleDoubts": "Tietoja SignHerosta",
        "DoubtsLegal": "Onko e-allekirjoittaminen edes laillista?",
        "TitleShortAnswer": "Lyhyt vastaus:",
        "AnswerLegalParagraph1": "Tottakai on. Se on ollut olemassa jo yli vuosikymmenen.",
        "AnswerLegalParagraph2": "Paperin allekirjoittaminen kynällä ei ole erityisen turvallinen tapa allekirjoittaa. Laki on puolellamme; lue lisää",
        "eIDAS": "eIDAS",
        "ESIGN": "ESIGN",
        "And": "- sekä ",
        "Acts": "-säädöksistä.",
        "TitleLongAnswer": "Pitkä vastaus:",
        "LegalLongAnswerParagraph1": "Paperin allekirjoittaminen ei ole kovin taattu tapa tehdä sopimus. Käsinkirjoitetut allekirjoitukset voidaan väärentää,\n"
                                     + "kiistanalaistaa ja ne voivat jopa hävitä. Itse asiassa, jos teet allekirjoituksia käsin edelleen, et voi olla periaatteessa varma mistään.",
        "LegalLongAnswerParagraph2": "EU:ssa on sopimusten teon vapaus- säädös, jonka mukaan voit valita miten sopimuksesi vahvistetaan.\n"
                                     + "Tarkista, ettei yritykselläsi ole erityisvaatimuksia (esim. rahanpesulaki), ja sitten vain e-allekirjoittamaan.",
        "LegalLongAnswerParagraph3": "Todennus on kuin vakuutus. Esititkö esimerkiksi henkilöllisyystodistuksesi, kun allekirjoitit nykyisen työsopimuksesi?\n"
                                     + "Jotkut sopimukset ovat vähäriskisiä ja vaativat siksi vain kevyen vakuutuksen; tässä tapauksessa sähköpostitodennus riittää.",
        "LegalLongAnswerParagraph4": "SignHeroa käytetään tällä hetkellä: kaupallisiin sopimuksiin / työsopimuksiin / NDA-sopimuksiin / kokouspöytäkirjoihin / vuokrasopimuksiin",
        "LegalLongAnswerParagraph5": "SignHeron standardeihin perustuvat allekirjoitukset ovat UETA:n, Esign Actin ja\n"
                                     + "eIDAS:ksen mukaiset ja noudattavat näitä säädöksiä.",
        // New localisation for en, fi, sv
        "NEWDoubtsLegal": "Sähköinen allekirjoitus – helppoa ja turvallista",
        "NEWLegalLongAnswerParagraph1": "Sähköiset allekirjoitukset on tunnustettu laillisesti jo lähes kahden vuosikymmenen ajan ja säädökset kuten eIDAS ja ESIGN Act takaavat niiden pätevyyden.",
        "NEWLegalLongAnswerParagraph2": "Todellisuudessa kynällä ja paperilla allekirjoittaminen on paljon vähemmän turvallista – väärennökset, kiistat ja asiakirjojen katoaminen ovat yleisiä riskejä. Sähköiset allekirjoitukset tarjoavat useita todennuskerroksia, jotka varmistavat jokaisen sopimuksen luotettavuuden ja turvallisuuden.",
        "NEWLegalLongAnswerParagraph3": "Ajattele sitä kuin vakuutusta: jotkut sopimukset vaativat vain perustason varmennusta, kuten sähköpostitodennusta, kun taas toiset edellyttävät tiukempia henkilöllisyystarkistuksia.",
        "NEWLegalLongAnswerParagraph4": "SignHero on luotettava ratkaisu kaikenlaisten sopimusten tekemiseen tehden allekirjoittamisesta vaivatonta, luotettavaa ja turvallista.",
        "NEWLegalLongAnswerParagraph5": "SignHero noudattaa UETA-, <a href=\"https://www.gpo.gov/fdsys/pkg/PLAW-106publ229/pdf/PLAW-106publ229.pdf\"\n"
                                        + "                             target=\"_blank\" rel=\"noopener\">ESIGN</a> Act- ja <a href=\"https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32014R0910\"\n"
                                        + "                             target=\"_blank\" rel=\"noopener\">eIDAS</a>-standardeja, joten voit allekirjoittaa huoletta ja varmuudella.",
        "DoubtsPrice": "Kuinka se toimii? Vain 5€?",
        "PriceShortAnswerParagraph1": "Voit allekirjoituttaa dokumentteja ja arkistoida ne vain muutamalla klikkauksella. Allekirjoituskutsut lähetetään sähköpostitse, eikä allekirjoittaminen vaadi käyttäjätiliä.\n"
                                      + "Hinta on 5€/käyttäjä/kk. Se laskutetaan automaattisesti kuukausittain, kun olet lisännyt luottokorttitietosi.\n"
                                      + "Voit peruuttaa milloin tahansa, joten maksat vain kuukaudesta kerrallaan.",
        "PriceLongAnswerParagraph1": "SignHero mahdollistaa PDF-dokumenttien allekirjoittamisen tietokoneilla ja mobiililaitteilla ja se voidaan integroida mihin tahansa CRM-järjestelmään, digitaaliarkistoon tai muuhun asiakirjanhallintajärjestelmään julkisella API:llamme. Voit jopa kirjautua suoraan Gmail-tilillesi SignHero-laajennuksella poistumatta saapuneet-kansiostasi.",
        "PriceLongAnswerParagraph2": "Voit luoda tilin ilman sitoutumista ja saat 14 päivän ilmaisen kokeilujakson. Luottokorttitietoja ei tarvita rekisteröitymisen yhteydessä.\n"
                                     + "Ilmainen kokeilujakso sisältää kaikki ominaisuudet, joten saat palvelusta ja sen käytöstä todenmukaisen kuvan. Ja älä huoli, emme koskaan lähetä roskapostia tai uutiskirjeitä.\n",
        "PriceLongAnswerParagraph3": "<span class='doubts-break1'>Hinta on 5€/käyttäjä/kk. Maksavana käyttäjänä voit lähettää allekirjoituspyyntöjä, seurata allekirjoitustapahtumia, tallentaa asiakirjasi SignHero-arkistoon ja työskennellä tiiminä organisaatiotilillä.\n"
                                     + "Voit lisätä tilillesi lähes rajattoman määrän tiimin jäseniä ja jokainen</span> <span class='doubts-break2'>uusi käyttäjä lisää 5€ kuukausitilaukseesi.</span>",
        // New localisation for en, fi, sv
        "NEWDoubtsPrice": "Miten SignHero toimii?",
        "NEWPriceLongAnswerParagraph1": "SignHeron avulla asiakirjojen allekirjoittaminen on vaivatonta. Lähetä allekirjoituskutsuja sähköpostitse ja vastaanottajat voivat allekirjoittaa ilman käyttäjätiliä. Kaikki on tehty helpoksi – allekirjoitukset, seuranta ja asiakirjojen turvallinen säilytys hoituvat vain muutamalla klikkauksella.",
        "NEWPriceLongAnswerParagraph2": "Aloita ilmaisella 14 päivän kokeilujaksolla ilman luottokorttia. Tutustu palveluun ja katso, kuinka se sopii työskentelytapaasi. Kun olet valmis, joustavat kuukausilaskutteiset tilaukset ilman pitkäaikaisia sitoumuksia tekevät käyttöönotosta helppoa. Voit peruuttaa tilauksen milloin tahansa.",
        "NEWPriceLongAnswerParagraph3": "SignHerolla pystyt hoitamaan kaikki allekirjoitustarpeesi. Palvelu tarjoaa sähköpostipohjaiset allekirjoitukset kaikkiin niihin tapauksiin joissa lain mukaan ei vaadita vahvaa tunnistautumista. Näihin muihin käyttötapauksiin voit allekirjoituttaa dokumenttisi vahvasti suomalaisen luottamusverkoston ja mobiilivarmenteen avulla.<br/><br/>SignHero toimii saumattomasti eri laitteilla ja integroituu CRM-järjestelmiin, arkistoihin ja asiakirjojen hallintajärjestelmiin julkisen API:n avulla. Gmail-käyttäjille on myös tarjolla SignHero-laajennus, jonka avulla voit allekirjoittaa suoraan sähköpostista.",
        "NEWPriceLongAnswerParagraph4": "Olipa kyseessä allekirjoituspyyntöjen lähettäminen, tiimityöskentely tai arkistojen hallinta, SignHero tekee sähköisestä allekirjoittamisesta helposti saavutettavaa, edullista ja helppokäyttöistä.",
        "TitleThisIsUs": "Meistä",
        "TitleMoreQuestion": "Lisäkysymyksiä?",
        "ApiDocsLink": "API-dokumentaatio",
        "TermOfUseLink": "Käyttöehdot",
        "PrivacyPolicyLink": "Tietosuojakäytäntö",
        "RegulationNotice": "Sähköisillä allekirjoituksilla on sama oikeudellinen asema kuin käsinkirjoitetuilla allekirjoituksilla Yhdysvalloissa ja EU:ssa",
        "ESignAct": "ESIGN-lain",
        "eIDASRegulations": "eIDAS-säännösten myötä",
        "History": "Historia",
        "Error!too_many_incorrect_email": "Liian monta väärää sähköpostiosoitetta, yritä huomenna uudelleen.",
        "PasswordRequirement" : "Salasanasi tulee olla vähintään 10 merkkiä pitkä ja sisältää numeron, ison kirjaimen, pienen kirjaimen sekä erikoismerkin. Vaihtoehtoisesti voit käyttää vähintään 15 merkkiä. Älä käytä enempää kuin kahta peräkkäistä identtistä merkkiä.",
        "EmailRequired" : "Sähköpostiosoite tarvitaan",
        "EmailMustValid" : "Sähköpostiosoitteen tulee olla kelpo",
        "PasswordMustValid" : "Salasanan on täytettävä monimutkaisuusvaatimukset",
        "RepeatPasswordMustSame" : "Salasanojen tulee olla identtisiä",
        "Youtube": "SignHero Youtube",
        "TitleNews" : "Uutiset",
        "ReadMoreLink" : "Lue lisää",
        "OtherNewsBtn" : "Muut uutiset",
        "NewsListBg1" : "Tätä olemme tehneet",
        "NewsListBg2" : "Lue uutisia saavutuksistamme, julkaisuistamme sekä maininnoista mediassa.",
        "Share" : "Jaa",
        "NewsTitleElectronicSignature" : "Näin toimii sähköinen allekirjoitus – testissä 6 ratkaisua",
        "NewsTitlePartnerStory" : "SignHero-kumppanitarina: Ennakoiva yhteistyö mahdollistaa win-win-win-tilanteen",
        "PartnerStoryP1" : "SaaShopilla ja SignHerolla on pitkä kumppanuus, joka tarjoaa etuja molemmille yrityksille ja ennen kaikkea asiakkaille. SignHero on ollut saatavilla SaaShopin markkinapaikalla vuodesta 2020 lähtien ja markkinapaikan kautta SignHero on kerännyt yli 1100 käyttäjää. Tässä artikkelissa puhumme kahden suomalaisyrityksen onnistuneesta kumppanuudesta, jotka nyt kansainvälistyvät ja tarjoavat SaaS-ratkaisuja pienille ja keskisuurille yrityksille maailmanlaajuisesti.",
        "PartnerStoryP2" : "Suomalaisen ohjelmistoyritys <a href=\"https://www.avaintec.com/\" target=\"_blank\" rel=\"noopener\">Avaintec Oy</a>:n kehittämä ja omistama <a href=\"https://signhero.io/\" target=\"_blank\" rel=\"noopener\">SignHero</a> mahdollistaa PDF-dokumenttien helpon allekirjoittamisen muutamalla klikkauksella tietokoneilla ja mobiililaitteilla. Käyttäjäystävällisyys ja tehokkuus ovat SignHeron vahvuuksia: allekirjoittaminen tapahtuu helposti eikä vaadi SignHero-tiliä ja allekirjoitetut dokumentit tallentuvat SignHeroon. Voit lisätä tiimin jäseniä organisaatiotilillesi, seurata prosesseja ja lähettää muistutuksia ja allekirjoituskutsuja sähköpostitse.",
        "PartnerStoryP3" : "SignHeron käytöstä on monia etuja, sillä se auttaa pääsemään eroon kynällä ja paperilla allekirjoittamisesta ja fyysisestä arkistointista, mikä on myös ympäristöystävällistä. SignHero auttaa virtaviivaistamaan prosesseja ja alentamaan kustannuksia. Sujuva viestintäprosessi parantaa asiakaskokemusta. SignHero täyttää myös alan standardit ja vaatimukset sähköisille allekirjoituksille.",
        "SubtitleHelpCustomers" : "Asiakkaiden auttaminen on kumppanuuden tärkein tavoite",
        "PartnerStoryP4" : "Tammikuussa 2022 SignHero julkaisi maksullisen version uusilla toiminnoilla, jotka tukevat palvelun sujuvaa ja helppoa käyttöä. Tämän kehityksen myötä yhteistyön intensiteetti on lisääntynyt, kun SignHero käyttää SaaShopin SaaS-kumppaneille tarjoamaa markkinointipakettia.",
        "PartnerStoryP5" : "\"Yhteistyö on ollut hedelmällistä alusta asti ja maksullisen version lanseerauksen myötä olemme tehneet entistä tiiviimpää yhteistyötä SaaShopin kanssa\", Avaintecin toimitusjohtaja Pekka Kuosmanen kuvailee. Hän jatkaa:",
        "PartnerStoryP6" : "”Yhteistyössä sellaisten yritysten kanssa, joilla on samanlainen asiakaskunta, mutta jotka eivät kilpaile suoraan, on omat etunsa. He sanovat, että win-win-yhteistyö on parasta yhteistyötä. SaaShop houkuttelee meille asiakkaita, joita emme ehkä voi tavoittaa, ja SaaShop toimii digitaalisen allekirjoituspalvelumme SignHeron jälleenmyyjänä. Uskomme puolestaan, että voimme tuoda lisäarvoa SaaShopille, koska he voivat palvella asiakkaitaan paremmin palvelullamme, ja kumppanuus auttaa edelleen kasvattamaan heidän liiketoimintaansa.\"",
        "PartnerStoryP7" : "\"Sanotaan, että win-win-yhteistyö on parasta yhteistyötä.\"",
        "PartnerStoryP8" : "Sekä SaaShop että SignHero kasvattavat myös kansainvälistä asiakaskuntaansa. Molemmilla yrityksillä on myös samanlaiset arvot, sillä käyttäjäkokemus ja asiakaspalvelu ovat prioriteetteja, joihin molemmat pyrkivät.",
        "PartnerStoryP9" : "\"SignHero on ainutlaatuinen, koska asetamme käytettävyyden etusijalle monimutkaisten ominaisuuksien sijaan. Meillä on kaksi etua: uskollinen käyttäjäkunta ja helppo integrointi muihin palveluihin”, Avaintec Oy:n henkilöstöpäällikkö Fatbardhe Hetemaj sanoo.",
        "PartnerStoryP10" : "”Olemme olleet erittäin tyytyväisiä yhteistyöhön kaiken kaikkiaan, erityisesti SaaShopin ennakoivaan asenteeseen ja positiiviseen, mahdollistavaan asenteeseen asiakaspalvelussa. Asiakkaat odottavat nopeita vastauksia ja ongelmanratkaisua ja olemme aina iloisia nähdessämme tyytyväisen asiakkaan. Olemme molemmat sitoutuneet auttamaan asiakkaita kaikin mahdollisin tavoin”, Hetemaj jatkaa.",
        "PartnerStoryP11" : "”Olemme aina iloisia nähdessämme tyytyväisen asiakkaan. Olemme molemmat sitoutuneet auttamaan asiakkaita kaikin mahdollisin tavoin.”",
        "PartnerStoryP12" : "Avaintec suosittelee mielellään SaaShopin palvelua ja kumppanuutta muille:",
        "PartnerStoryP13" : "”Näemme SaaShopin yhtenä tärkeimmistä jälleenmyyjäkanavista SignHerolle tulevaisuudessa ja odotamme innolla yhteistyön laajentamista myös kansainvälisille markkinoille. SaaShopilla työskentelee mahtava porukka, joka tekee yhteistyöstä erittäin sujuvaa, Kuosmanen päättää.",
        "PartnerStoryP14" : "SaaShop tarjoaa kaikki SaaS-tuotteet yhdestä paikasta yhdellä laskulla. Asiakkaat voivat luoda profiilin SignHerolle suoraan SaaShopin kauppapaikalta. Lue lisää SaaShop-kumppaniksi tulemisen eduista täältä.",
        "NewsSaveTimeP1" : "Kaksivaiheista varmennusta käytetään nykyään usein. On myös paljon tilanteita, joissa sopimukset allekirjoitetaan paperilla. Mutta tiesitkö, että on olemassa monia sopimuksia, jotka voidaan vahvistaa elektronisella allekirjoituksella johon ei vaadita lisätodennusta? Esititkö esimerkiksi henkilöllisyystodistuksesi, kun allekirjoitit nykyisen työsopimuksesi? Jotkut sopimukset ovat vähäriskisiä ja vaativat siksi vain kevyen tunnistautumisen; tässä tapauksessa sähköpostitodennus riittää.",
        "NewsSaveTimeP2" : "Kaupalliset sopimukset, työsopimukset, NDA-sopimukset, kokouspöytäkirjat ja vuokrasopimukset ovat esimerkkejä tilanteista, joissa voit käyttää <a href=\"https://shop.saashop.com/products/1693/signhero-signhero\" target=\"_blank\" rel=\"noopener\">SignHeroa</a> monimutkaisten ja aikaa vievien vaihtoehtojen sijaan. SignHeron avulla saat asiakirjat allekirjoitettua muutamalla klikkauksella, voit tallentaa allekirjoitettuja asiakirjoja SignHero-arkistoon, lisätä tiimin jäseniä organisaatiotiliisi sekä seurata prosesseja ja lähettää muistutuksia. Allekirjoituskutsut lähetetään sähköpostitse, eikä allekirjoitus vaadi omaa SignHero-tiliä. Ylimääräistä todennusta ei tarvita, koska SignHero keskittyy vain tapauksiin, jotka kuuluvat \"sopimusten tekemisen vapauden\" piiriin.",
        "NewsSaveTimeP3" : "<a href=\"https://signhero.io\" target=\"_blank\" rel=\"noopener\">SignHero</a> on suomalaisen ohjelmistoyhtiö <a href=\"https://www.avaintec.com\" target=\"_blank\" rel=\"noopener\">Avaintec Oy</a>:n omistama ja se tarjoaa nykyaikaisen ratkaisun, joka helpottaa sinun ja asiakkaittesi elämää. Käytät vähemmän aikaa tuottamattomaan työhön, sinulla on vähemmän paperia pyörimässä nurkissa ja voit tarjota turvallisemman tavan allekirjoittaa sopimuksia. Kun allekirjoittaminen on helppoa, prosessisi ja asiakasmääräsi paranevat.",
        "SubtitleBenefitsForOrganisations" : "Sähköinen allekirjoitus tarjoaa monia etuja organisaatioille",
        "NewsSaveTimeP4" : "Sähköiset allekirjoitukset ovat auttaneet organisaatioita tehostamaan liiketoimintaansa jo lähes kolmen vuosikymmenen ajan. Nykyään sähköinen allekirjoittaminen voi olla yhtä yksinkertaista kuin sähköpostin lähettäminen ja se tarjoaa myös monia etuja.",
        "NewsSaveTimeP5" : "<a href=\"https://shop.saashop.com/products/1693/signhero-signhero\" target=\"_blank\" rel=\"noopener\">SignHero</a> auttaa pääsemään eroon kynällä ja paperilla allekirjoittamisesta ja fyysisestä arkistoinnista (ympäristöhyöty), virtaviivaistaa prosesseja ja alentaa kustannuksia sekä laajentaa verkkoviestinnän sujuvuutta. Automatisoimalla dokumenttien työnkulkua eri organisaatioissa, myynti-, markkinointi-, logistiikka-, HR- ja lakiasiaintoiminnot saavuttavat huomattavaa tehokkuutta auttamalla kaikenkokoisia yrityksiä poistamaan paperityön pullonkauloja. CRM:n, ERP:n, digitaalisen arkistoinnin ja muiden pilvipohjaisten SaaS-sovellusten ohella sähköinen allekirjoittaminen digitalisoi globaalia liiketoimintaa.",
        "NewsSaveTimeP6" : "Eezy:n liiketoimintajohtaja Johannes Setänen on pitänyt SignHeron hyödyistä ja yksinkertaisuudesta:",
        "NewsSaveTimeP7" : "”Signhero oli nopea ja helppo ottaa käyttöön Eezylle henkilöstövuokrauksen alalla. Ei monimutkaisia integraatioita, ei ylimääräisiä kuluja, vain yksinkertaiset kuukausimaksut. Niin eezyä!”",
        "SubtitleFeatures" : "Mitkä ovat hyvän sähköisen allekirjoitusratkaisun ominaisuudet?",
        "NewsSaveTimeP8" : "<a href=\"https://shop.saashop.com/products/1693/signhero-signhero\" target=\"_blank\" rel=\"noopener\">SignHero</a> on jo tarjonnut nykyaikaisen ratkaisun kauppojen sinetöimiseen ja sopimusten allekirjoittamiseen. Jatkuva kehitys on kuitenkin menestyksen avain ja SignHero on parantanut ominaisuuksiaan. Uusien päivitysten myötä organisaatioilla on entistä enemmän työkaluja kapinoidakseen monimutkaisuutta, ylisuunniteltuja prosesseja ja vanhaa tapaa tehdä asioita vastaan. SignHeroon on lisätty seuraavat ominaisuudet:",
        "NewsSaveTimeFeaturesBullet1" : "Parannettu arkistohaku",
        "NewsSaveTimeFeaturesBullet2" : "Tunnisteen lisääminen allekirjoitusprosessiin ja tunnistehaku",
        "NewsSaveTimeFeaturesBullet3" : "Allekirjoituksen uudelleen käynnistäminen",
        "NewsSaveTimeFeaturesBullet4" : "Mukautetut sähköpostikutsupohjat",
        "NewsSaveTimeFeaturesBullet5" : "Käyttäjätilastot",
        "NewsSaveTimeFeaturesBullet6" : "Tilien yhdistäminen",
        "NewsSaveTimeP9" : "SignHero haluaa tarjota palveluaan useilla kielillä. Tällä hetkellä SignHero on saatavilla englanniksi, suomeksi, ruotsiksi, puolaksi ja saksaksi. Myös ranskan, espanjan ja hollannin kieliversiot ovat tulossa.",
        "NewsSaveTimeP10" : "SignHero voidaan integroida mihin tahansa CRM-, HRM-, digitaaliseen arkistoon tai muuhun asiakirjaanhallintajärjestelmään, viestintätyökaluun tai markkinapaikkasovellukseen. Tällä tavalla käyttäjä saa \"lähetä allekirjoitus\" -painikkeen juuri sinne, missä hän luo ja tallentaa asiakirjoja. Voit jopa kirjautua suoraan Gmail-tilillesi SignHero-laajennuksella ilman että lähdet saapuneet-kansiostasi.",
        "NewsSaveTimeP11" : "Jos haluat hyvän käyttökokemuksen sähköisestä allekirjoituspalvelusta ja haluat lisätä sopimustesi määrää, suosittelemme lämpimästi kokeilemaan <a href=\"https://shop.saashop.com/products/1693/signhero-signhero\" target=\"_blank\" rel=\"noopener\">SignHeroa</a>!",
        "NewsTitleSaveTime" : "Signhero: Turvallisempi tapa allekirjoittaa säästää aikaa ja rahaa",
        "NewsTitleSahihiHaki" : "Sahihi Haki & SignHero solmivat yhteistyösopimuksen",
        "NewsSahihiHakiP1" : "SignHero ja Sahihi Haki solmivat yhteistyökumppanuuden helmikuussa 2023. Yhteistyö mahdollistaa SignHeron sähköisen allekirjoittamisen palveluiden tarjoamisen Itä-Afrikan ja Saharan eteläpuolisille maille. Kyseessä on siis laaja alue joka digitalisoituu vauhdilla ja jossa on kysyntää tämän tyyppisille palveluille.",
        "NewsSahihiHakiP2" : "SignHero on suomalaisen ohjelmistoyritys Avaintecin kehittämä nopea ja helppo sähköisen allekirjoittamisen palvelu. SignHero lanseerattiin vuonna 2017 ja on kasvanut  kansainväliseksi palveluksi jota tarjotaan tällä hetkellä kahdeksalla kielellä kymmenissä eri maissa. SignHeron vahvuuksia ovat sen helppokäyttöisyys, kansainvälisyys sekä erittäin kilpailukykyinen hinta.",
        "NewsSahihiHakiP3" : "SignHeron uusi kumppani Sahihi Haki on kenialainen järjestelmäintegraattoriyritys, joka tasoittaa tietä tietotekniikan innovatiivisille ja luotettaville ratkaisuille hallituksille, pankeille ja yksityiselle sektorille. He tarjoavat laajan valikoiman vertaansa vailla olevia ratkaisuja erittäin turvallisista asiakirjojen myöntämis- ja personointiratkaisuista saumattomasti integroituihin e.Government -ratkaisuihin.",
        "NewsSahihiHakiP4" : "Nyt solmittu yhteystyökumppanuus mahdollistaa SignHeron osallistumisen Itä-Afrikan alueen nopeaan digitalisoitumiseen ja tutustuttaa uudet käyttäjät sähköisen allekirjoittamisen tuomiin moniin etuihin.  Yritysten välisten menojen Afrikan valmistussektorilla ennustetaan nousevan 1 biljoonaan dollariin vuoteen 2050 mennessä. Tämän myötä digitalisaatiolla on valtava mahdollisuus parantaa alan kilpailukykyä ja vahvistaa alueellisia talouksia.",
        "NewsSahihiHakiP5" : "Alueen organisaatioiden tulisi yksinkertaistaa päätöksentekoa ja ottaa askeleita kohti digitalisaatiota, vaikka vain pienin askelin. SignHero e-allekirjoituspalvelu on juuri tällainen pieni askel digitalisaation joka tulee huomattavasti helpottamaan Itä-Afrikan alueen organisaatioiden liiketoimintaa.",
        "NewsSahihiHakiQuotePekka" : "Pekka Kuosmanen, Avaintecin toimitusjohtaja sanoo:",
        "NewsSahihiHakiP6" : "“Olen todella tyytyväinen siihen, että SignHeron sähköinen allekirjoituspalvelu saadaan laajennettua Afrikkaan yhteistyössä Sahihi Hakin kanssa. Yhteistyö mahdollistaa Avaintecille sähköisen allekirjoituksen blue ocean -markkinamahdollisuuden.”",
        "NewsSahihiHakiQuoteBertrand" : "Bertrand Ngaywa Sahihi Hakilta kertoo:",
        "NewsSahihiHakiP7" : "\"Me Sahihi Hakilla olemme iloisia voidessamme työskennellä Avaintecin kanssa sähköisten allekirjoitusten käyttöönottamiseksi Afrikan alueella, koska olemme motivoituneita ajamaan tietotekniikan innovaatioita nykyisten globaalien trendien mukaisesti.\"",
        "UnsubscribeQuestion" : "Oletko varma, että haluat peruuttaa SignHero-markkinointiviestien tilauksen?",
        "Resubscribe" : "Tilaa uudelleen",
        "ResubscribedTitle" : "Kiva kun tulit takaisin!",
        "ResubscribedMessage" : "Olet tilannut markkinointiviestimme uudelleen onnistuneesti, voit nyt poistua sivulta.",
        "NewsTitlePrivyPartner" : "Privy Partner & Avaintec tuovat SignHeron Kaakkois-Aasian markkinoille",
        "NewsPrivyPartnerP1" : "Avaintec ja thaimaalainen Privy Partner solmivat yhteistyökumppanuuden syyskuussa 2023. Yhteistyö mahdollistaa Avaintecin SignHeron sähköisen allekirjoittamisen palveluiden tarjoamisen Kaakkois-Aasian alueella (Brunei, Burma (Myanmar), Kambodža, Itä-Timor, Indonesia, Laos, Malesia, Filippiinit, Singapore, Thaimaa ja Vietnam). Kaakkois-Aasian nopea digitalisoituminen avaa joukon mahdollisuuksia sen asukkaille ja yrityksille.",
        "NewsPrivyPartnerP2" : "SignHero on suomalaisen ohjelmistoyritys Avaintecin kehittämä nopea ja helppo sähköisen allekirjoittamisen palvelu. SignHero lanseerattiin vuonna 2017 ja on kasvanut  kansainväliseksi palveluksi jota tarjotaan tällä hetkellä kahdeksalla kielellä kymmenissä eri maissa. SignHeron vahvuuksia ovat sen helppokäyttöisyys, kansainvälisyys sekä erittäin kilpailukykyinen hinta.",
        "NewsPrivyPartnerP3" : "SignHeron uusi yhteistyökumppani thaimaalainen Privy Partner on alun perin perustettu liikkeenjohdon konsulttiyritykseksi vuonna 2005, joka tarjosi C-tason liikkeenjohdon konsultointipalveluita strategisen liikkeenjohdon keskeisillä alueilla (sekä taktisella että operatiivisella tasolla), kansainvälisen liiketoiminnan kehittämistä ja perustamista sekä yritysverkoston hallintaa lokalisointia varten. Nyt Privy Partner toimii yrityskonsultoijana sekä tarjoaa digitaalisia palveluja henkilötietojen yksityisyyden hallintaan ja tietosuojapalveluja. Tämän lisäksi yritys tukee asiakkaitaan digitaalisessa transformaatiossa, joka auttaa yrityksiä ja valtion yksiköitä pärjäämään muutosprosessissa tinkimättä liiketoiminnan kasvusta.",
        "NewsPrivyPartnerP4" : "Yhteistyön myötä SignHero pääsee osallistumaan alueen digitalisaatioon. Useammat yritykset ympäri maailmaa muokkaavat toimintaansa sopeutumaan digitaaliseen kansainväliseen maailmaan ja ottavat sen myötä käyttöön uusia tapoja tehdä töitä ja hyödyntävät teknologioita kehityksen mahdollistamiseksi.",
        "NewsPrivyPartnerP5" : "Kaakkois-Aasian toimijat ovat jo hyötyneet digitalisaatiosta parantuneen toiminnan tehokkuuden, parantuneiden myyntiprosessien ja asiakaskokemuksen kautta sekä parantuneen tuottavuuden myötä. Uusi tekniikka antaa mahdollisuuden kommunikoida ennennäkemättömällä tavalla ja antaa yrityksille aikaa keskittyä heidän ydinosaamiseensa.",
        "NewsPrivyPartnerQuotePekka" : "Pekka Kuosmanen, Avaintecin toimitusjohtaja sanoo:",
        "NewsPrivyPartnerP6" : '"Kaakkois-Aasian yhteiskunnat digitalisoituvat nopeaa vauhtia. Muun muassa Thaimaassa tuli kesäkuussa voimaan laki, joka velvoittaa viranomaisia digitalisoimaan palvelunsa. Sähköiset allekirjoitukset ovat olennainen osa digitalisoitavia prosesseja."',
        "NewsPrivyPartnerP7" : '"Sopimus ja yhteistyö Privy Partnerin kanssa avaa SignHerolle ison potentiaalin Kaakkois-Aasian markkinoilla tulla keskeiseksi sähköisten allekirjoitusten toimittajaksi."',
        "NewsPrivyPartnerQuoteWanawit" : "Wanawit Ahkuputra, Privy Partnerin perustaja ja pääkumppani sanoo:",
        "NewsPrivyPartnerP8" : '"Suomalaiselle digitaalisen allekirjoituksen teknologialle on ominaista turvallisuus, innovatiivisuus ja eurooppalaisten standardien noudattaminen. Avaintec on  työskennellyt pitkään thai kielen lokalisoinnin integroimiseksi, mikä on ensisijainen kriittinen menestystekijä digitaalisen allekirjoituksen oikeellisuuden ja aitouden varmistamisessa Thaimaan alueen paikallisessa kontekstissa."',
        "Locales": "Kieli",
    },
    "client": {
        "ClientSecretInfo": "Säilytä salaisuus huolellisesti. Voit vaihtaa sen myöhemmin mutta sitä ei voida näyttää sen jälkeen kun suljet tämän session.",
        "DomainValidationDownloadInfo1": "Kuinka vahvistaa:",
        "DomainValidationDownloadInfo2": "Lataa tiedosto:",
        "DomainValidationDownloadInfo3": "Lisää tiedosto domainisi juurikansioon:",
        "DomainValidationDownloadInfo4": "Vahvista että tiedosta on saatavilla osoitteessa:",
        "DomainValidationDownloadInfo5": "Palaa tälle sivulle ja klikkaa 'Vahvista domain'",
        "OAuthApplications": "OAuth2-sovellukset",
        "Application": "OAuth2-sovellus",
        "IntegrateWithOAuth": "Integroi OAuth2:lla",
        "NewOAuthApplication": "Uusi OAuth2-sovellus",
        "ApplicationName": "Sovelluksen nimi *",
        "AddOAuthApplication": "Lisää sovellus",
        "AddUri": "Lisää URI",
        "CreationDate": "Luontipäivämäärä",
        "CreatedBy": "Luoja",
        "AllowedGrantTypes": "Hyväksytyt valtuutustavat",
        "OAuthRedirectURIs": "Uudelleenohjauksen URIt *",
        "OAuthClientsDomainTitle": "Sovelluksen domain *",
        "OAuthClientsDomainURI": "Domain",
        "AppDescription": "Sovelluksen kuvaus",
        "VerifyDomain": "Vahvista domain",
        "DomainVerified": "Domain on vahvistettu!",
        "WhyValidateDomain": "Sinun on vahvistettava domainin omistajuus ennen kuin sovellusta voidaan käyttää. Voit lukea <a class='paragraph-links' href='/docs/api' target='_blank'>API-dokumentaatiosta</a> lisätietoja.",
        "FailedDomainValidation": "Domainin vahvistus epäonnistui. Seurasitko ohjeita?",
        "ClientSecret": "Salasana",
        "ClientId": "Tunniste",
        "ClientCredentials": "Tunnukset",
        "VerifiedApp": "Aktiivinen",
        "UnverifiedApp": "Inaktiivinen",
        "ResetClientSecret": "Vaihda salasana",
        "LastClientSecretReset": "Viimeksi vaihdettu",
        "ConfirmDeleteApiClient": "Haluatko varmasti poistaa sovelluksen?\nJatkaaksesi syötä luku ${rndCode}:",
        "ConfirmResetClientSecret": "Haluatko varmasti vaihtaa salasanan?\nJatkaaksesi syötä luku ${rndCode}:",
        "AppHasBeenDeleted": "Sovellus ${appName} on poistettu!",
        "AppHasBeenCreated": "Sovellus ${appName} on luotu!",
        "YourChangesHaveBeenSaved": "Muutokset on tallennettu!",
        "ClientSecretChanged": "Salaisuus vaihdettu!",
        "Error!InvalidDomain": "Virheellinen domain",
        "CheckApiDocs": "Sinun on vahvistettava domainin omistajuus ennen kuin sovellusta voidaan käyttää. Vahvistus vaatii sen, että sinulla on admin-oikeudet domainin juurikansioon. Tämä on tehtävä vain kerran. Voit lukea <a href='/docs/api' target='_blank'>API-dokumentaatiosta</a> lisätietoja.",
    },
    "oauth2consent": {
        "AllowAccess": "Anna suostumus",
        "OAuthAccessDescriptionHeader": "<span class='elem-pink'>${appName}</span> " +
                                        "tarvitsee suostumuksesi ennen kuin voit sen avulla",
        "OAuthBasicPermissions": "  <li>Lähetä allekirjoituskutsuja ja muistutuksia</li>" +
                                 "  <li>Katsella, peruuttaa tai poistaa sillä lähetettyjä kutsuja</li>" +
                                 "  <li>Katsella sillä lähetettyjä allekirjoitettuja dokumentteja</li>",
        "OAuthSigningUrlPermissions": " <li>Mennä allekirjoittamaan sillä ladattuja dokumentteja</li>",
        "OAuthProfilePermissions": " <li>Katsella henkilökohtaisia tietojasi, kuten nimeä, sähköpostia, yrityksen nimeä, " +
                                   "organisaation nimeä ja valittua kieltä sekä hallinnoida tilin admin-oikeuksia</li>",
        "OAuthAllDataPermissions": " <li>Katsella, peruuttaa ja poistaa allekirjoituskutsuja</li>" +
                                   " <li>Katsella arkistosi kaikkia dokumentteja</li>",
        "OAuthAccessWarning": "Näin myös hyväksyt heidän käyttöehdot ja tietosuojan.",
        "QuestionToAllowAccess": "Anna pääsy SignHero-tiliisi <span class='elem-bold'>${userEmail}</span>?<br><br>" +
                                 "Voit aina muuttaa mieltäsi SignHero-tilisi asetuksista.",
        "Cancel": "Peru",
        "Allow": "Hyväksy",
        "NotFound": "Sovellusta ei löydy",
        "OAuthResourceNotFound": "Valitettavasti sinut tänne ohjanneen sovelluksen on korjattava virhe."
    }
}
