import { util, globalEvents } from "@cloudlace/client";
import { format } from "date-fns";
const orgRe = /^shio.([^\.]+)\.(.+)/;
const locales = [ "en", "fi", "sv", "pl", "de", "nl", "fr", "es", "th" ];

export default {
    /**
     * Regular expression used to sanitise characters to used fonts.
     *
     * @public {RegExp}
     */
    goodRegExp : /^[\u0020-\u007e\u00a0-\u017f\u0192\u01fa-\u01ff\u02c6-\u02c7\u02c9\u02d8-\u02dd\u037e\u0384-\u038a\u038c\u038e-\u03a1\u03a3-\u03ce\u0401-\u040c\u040e-\u044f\u0451-\u045c\u045e-\u045f\u0490-\u0491\u1e80-\u1e85\u1ef2-\u1ef3\u2013-\u2015\u2017-\u201e\u2020-\u2022\u2026\u2030\u2032-\u2033\u2039-\u203a\u203c\u203e\u2044\u207f\u20a3-\u20a4\u20a7\u20ac\u2105\u2113\u2116\u2122\u2126\u212e\u215b-\u215e\u2190-\u2195\u21a8\u2202\u2206\u220f\u2211-\u2212\u2215\u2219-\u221a\u221e-\u221f\u2229\u222b\u2248\u2260-\u2261\u2264-\u2265\u2302\u2310\u2320-\u2321\u2500\u2502\u250c\u2510\u2514\u2518\u251c\u2524\u252c\u2534\u253c\u2550-\u256c\u2580\u2584\u2588\u258c\u2590-\u2593\u25a0-\u25a1\u25aa-\u25ac\u25b2\u25ba\u25bc\u25c4\u25ca-\u25cb\u25cf\u25d8-\u25d9\u25e6\u263a-\u263c\u2640\u2642\u2660\u2663\u2665-\u2666\u266a-\u266b\uf001-\uf002\uf005\ufb01-\ufb02\u0E00-\u0E7F]+$/i,
    /**
     * Regular expression used to sanitise characters in LiberationSans font.
     *
     * @public {RegExp}
     */
    liberationSansRegExp : /^[\u0020-\u007e\u00a0-\u017f\u0192\u01fa-\u01ff\u02c6-\u02c7\u02c9\u02d8-\u02dd\u037e\u0384-\u038a\u038c\u038e-\u03a1\u03a3-\u03ce\u0401-\u040c\u040e-\u044f\u0451-\u045c\u045e-\u045f\u0490-\u0491\u1e80-\u1e85\u1ef2-\u1ef3\u2013-\u2015\u2017-\u201e\u2020-\u2022\u2026\u2030\u2032-\u2033\u2039-\u203a\u203c\u203e\u2044\u207f\u20a3-\u20a4\u20a7\u20ac\u2105\u2113\u2116\u2122\u2126\u212e\u215b-\u215e\u2190-\u2195\u21a8\u2202\u2206\u220f\u2211-\u2212\u2215\u2219-\u221a\u221e-\u221f\u2229\u222b\u2248\u2260-\u2261\u2264-\u2265\u2302\u2310\u2320-\u2321\u2500\u2502\u250c\u2510\u2514\u2518\u251c\u2524\u252c\u2534\u253c\u2550-\u256c\u2580\u2584\u2588\u258c\u2590-\u2593\u25a0-\u25a1\u25aa-\u25ac\u25b2\u25ba\u25bc\u25c4\u25ca-\u25cb\u25cf\u25d8-\u25d9\u25e6\u263a-\u263c\u2640\u2642\u2660\u2663\u2665-\u2666\u266a-\u266b\uf001-\uf002\uf005\ufb01-\ufb02]+$/i,
    /**
     * Vat Coefficient for FI customers.
     */
    walletVatCoefficient : process.env.VUE_APP_WALLET_VAT ? ( parseFloat(  process.env.VUE_APP_WALLET_VAT ) + 1 ) :  1,
    /**
     * Email validator.
     */
    validateEmail( value )
    {
        const re = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
        if( !value || ( re.test( value ) && ( value.length < 320 ) && ( !value.split( /\.|@/ ).some( piece => piece.length > 62 ) ) ) )
        {
            return true;
        }
        else
        {
            return this.$t( 'message.MustBeEmail' );
        }
    },
    /**
     * Full name validator.
     *
     * @param value
     * @returns {boolean|*}
     */
    validateFullName( value )
    {
        const re = /(?!<a[^>]*>[^<])(((([A-Za-z]{3,9}:(?:\/){2,})(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?))(?![^<]*<\/a>)/gi;
        if( !value || !( re.test( value ) ) )
        {
            return true;
        }
        else
        {
            return this.$t( 'message.MustBeValidFullName' );
        }
    },
    /**
     * Check hyperlink.
     *
     * @param value
     * @returns {boolean|*}
     */
    checkHyperlink( value )
    {
        const re = /((([A-Za-z]{3,9}:(?:\/){2,})[A-Za-z0-9.-]+[A-Za-z0-9.-]+))/gi;
        if( !value || !( re.test( value ) ) )
        {
            return true;
        }
        else
        {
            return this.$t( 'message.HyperlinksNotAllowed' );
        }
    },
    /**
     * Returns pseudo-random four digits for use in interlocked confirm dialogs.
     *
     * @return {string}
     */
    getRandomCode()
    {
        let rndCode = Math.round( Math.random() * 9999 ) + "";
        while( rndCode.length < 4 )
        {
            rndCode = "0" + rndCode;
        }
        return rndCode;
    },
    /**
     * Returns UUID of current group, read from user scope.
     *
     * @return {string}
     */
    getOrganizationId()
    {
        return orgRe.exec( $cc_user_info.scope )[ 1 ];
    },
    /**
     * Returns true if current user is admin of current group.
     *
     * @return {boolean}
     */
    getIsAdmin()
    {
        return $cc_user_info.scope.indexOf( this.getOrganizationId() + ".admin" ) !== -1;
    },
    /**
     * Returns true if current user is subscription admin of current group.
     *
     * @return {boolean}
     */
    getIsSubscriptionAdmin()
    {
        return $cc_user_info.scope.indexOf( this.getOrganizationId() + ".subadmin" ) !== -1;
    },
    /**
     * Get locale from query string or cookie, or fallback to "en".
     *
     * @returns {string}
     */
    getLocale()
    {
        let locale = util.parseQuery( window.location.search ).locale || util.parseCookies( document.cookie ).locale;
        if( !locale || locales.indexOf( locale ) === -1 )
        {
            locale = "en";
        }
        document.cookie = "locale=" + locale + "; expires=Fri, 31 Dec 9999 23:59:59 GMT;path=/;secure";
        return locale;
    },
    /**
     * Reload page with newLoc in cookie, and strip locale from query string if present.
     *
     * @param newLoc
     */
    setLocale( newLoc )
    {
        if( !newLoc || locales.indexOf( newLoc ) === -1 )
        {
            return;
        }
        const query = util.parseQuery( window.location.search );
        delete query.locale;
        let qs = util.serializeQuery( query );
        if( qs )
        {
            qs = '?' + qs;
        }
        document.cookie = "locale=" + newLoc + "; expires=Fri, 31 Dec 9999 23:59:59 GMT;path=/;secure";
        if( qs === window.location.search )
        {
            window.location.reload();
        }
        else
        {
           window.location.assign( window.location.pathname + qs + window.location.hash );
        }
    },
    /**
     * Return copy of obj including only members listed in whiteList. Not recursive.
     *
     * @param obj {Object}
     * @param whiteList {string[]}
     * @returns {Object}
     */
    whiteList( obj, whiteList )
    {
        const out = {};
        for( let i = 0; i < whiteList.length; i++ )
        {
            if( obj[ whiteList[ i ] ] )
            {
                out[ whiteList[ i ] ] = obj[ whiteList[ i ] ];
            }
        }
        return out;
    },
    /**
     * Returns two arrays, first of which passes the filter, second fails the filter
     * @param array {[]}
     * @param filter {function}
     * @return {[ [], [] ]}
     */
    partition( array, filter )
    {
        let pass = [], fail = [];
        array.forEach( element => ( filter( element ) ? pass : fail ).push( element ) );
        return [ pass, fail ];
    },
    /**
     * Retrieves user subscription and update status.
     *
     * @param shioCoreClient
     * @param organization_name
     * @returns {Promise<any>}
     */
    retrieveSubscription( shioCoreClient, organization_name )
    {
        return new Promise( ( resolve, reject ) =>
        {
            shioCoreClient.retrieveSubscription( this.getOrganizationId(), organization_name ).then( r =>
            {
                if( r._new_customer_created === true && this.getIsAdmin() )
                {
                    shioCoreClient.retrieveHandshake().then( r =>
                    {
                        globalEvents.emit( "scope-changed" );
                    } ).catch( e => {} );
                }
                window.$cc_user_info.subscription = {
                    status : r.status,
                    trial_days_left : r.trial_end ? this.getDaysUtilNow( r.trial_end ) : "",
                    trial_end : r.trial_end ? this.getDateFromTimestamp( r.trial_end ) : "",
                    current_period_end : r.current_period_end ? this.getDateFromTimestamp( r.current_period_end ) : "",
                    current_period_start : r.current_period_start ? this.getDateFromTimestamp( r.current_period_start ) : "",
                    has_trial_period :  ( r.status === 'trialing' ),
                    has_payment_past_due :  ( r.status === 'past_due' ),
                    has_valid_subscription :  ( r.status === 'active' ),
                    incomplete_subscription :  ( r.status === 'incomplete' ),
                    pending_subscription :  ( r.status === 'incomplete' || r.status === 'past_due' ),
                    default_pm :  r.cusObj?.invoice_settings?.default_payment_method,
                    quantity : r.quantity,
                    email : r.cusObj?.email,
                    address : r.cusObj?.address,
                    preferred_locales : r.cusObj?.preferred_locales
                };
                resolve();
            } ).catch( e =>
            {
                reject( e );
            } );
        } );
    },
    /**
     * Returns date from timestamp.
     *
     * @param timestamp
     * @returns {string | *}
     */
    getDateFromTimestamp : function( timestamp )
    {
        return format( new Date( timestamp * 1000 ), "dd.MM.yyyy" )
    },
    /**
     * Returns period string.
     *
     * @param start
     * @param end
     * @returns {string}
     */
    getDatePeriod : function( start, end )
    {
        let startDate = new Date( start * 1000 );
        let endDate = new Date( end * 1000 );
        return format( startDate, "dd.MM.yyyy" ) + "-" + format( endDate, "dd.MM.yyyy" );
    },
    /**
     * Returns days util now.
     *
     * @param timestamp
     * @returns {number}
     */
    getDaysUtilNow : function( timestamp )
    {
        let currentTime = new Date().getTime();
        let diffTime =  timestamp * 1000 - currentTime;
        return Math.ceil( diffTime / (1000 * 3600 * 24 ) );
    },
    /**
     * Retrieve my email checking error times.
     *
     * @param shioCoreClient
     * @returns {Promise<unknown>}
     */
    retrieveMeErrorTimes : function( shioCoreClient )
    {
        return new Promise( ( resolve, reject ) =>
        {
            shioCoreClient.retrieveUser( this.getOrganizationId(), $cc_user_info.email ).then( r =>
            {
                let today = new Date().setHours(0, 0, 0, 0);
                let thatDay = new Date( r.user.entity.errorTs ).setHours(0, 0, 0, 0);
                resolve( ( today === thatDay ) ? r.user.entity.errorTimes : 0 );
            } ).catch( e=> reject ( e ) );
        } );
    },
    /**
     * Check whether there is enough balance in the wallet.
     *
     * @param shioCoreClient
     * @param cost
     * @param country
     * @returns {Promise<unknown>}
     */
    checkWallet : function( shioCoreClient, cost, country )
    {
        return new Promise( ( resolve, reject ) =>
        {
            shioCoreClient.retrieveWallet( this.getOrganizationId() ).then( r =>
            {
                this.group_balance = r.entity.balance;
                if( country === "FI" )
                {
                    resolve( r.entity.balance >= ( cost * this.walletVatCoefficient ) );
                }
                else
                {
                    resolve( r.entity.balance >= cost );
                }
            } ).catch( e=> reject ( e ) );
        } );
    },
    /**
     * Retrieve wallet for the group.
     *
     * @param shioCoreClient
     * @returns {Promise<unknown>}
     */
    retrieveWallet : function( shioCoreClient )
    {
        return new Promise( ( resolve, reject ) =>
        {
            shioCoreClient.retrieveWallet( this.getOrganizationId() ).then( r =>
            {
                resolve( r );
            } ).catch( e=> reject ( e ) );
        } );
    },
    /**
     * Log out current session for the signed in user.
     *
     * @param shioCoreClient
     */
    logoutSessions : function( shioCoreClient )
    {
        shioCoreClient.deleteAccessTokens( this.getOrganizationId(), false ).then( result =>
        {
            globalEvents.emit( "signed-out", result );
        } ).catch( err =>
        {
            console.error( err );
            globalEvents.emit( "signed-out", err );
        } );
    },
    /**
     * Log out all session for the signed in user.
     *
     * @param shioCoreClient
     */
    logoutAllSessions : function( shioCoreClient )
    {
        shioCoreClient.deleteAccessTokens( this.getOrganizationId(), true ).then( result =>
        {
            globalEvents.emit( "signed-out", result );
        } ).catch( err =>
        {
            console.error( err );
            globalEvents.emit( "signed-out", err );
        } );
    }
};
