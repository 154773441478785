var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content text-left ma-auto mr-0"},[_c('div',{staticClass:"Authentication sectionContainer",attrs:{"id":"section7"}},[_c('h1',{staticClass:"section-title"},[_vm._v("Create an application")]),_c('hr',{staticClass:"line-break"}),_c('p',[_vm._v("Before using authorisation code grant, you need to create an OAuth2 application.")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('p',[_vm._v(" Fill in application name and optionally description both of which will be present on the user consent page. If you choose to provide a description, explain what your application does and how it is going to use the access granted by the user. ")]),_vm._m(4),_c('p',[_vm._v(" In the following section provide your servers's domain name and at least one redirect URI to which users will be redirected after giving consent. Authorisation code will be added as a query parameter in the redirect URL. ")]),_vm._m(5),_c('v-alert',{staticClass:"api-info"},[_c('span',[_vm._v(" It won't be possible to change your application's domain name after creating the app. To use a different domain name you will have to create a new app. ")])]),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_c('p',[_vm._v(" Congratulations! Now your OAuth2 application can be used to create access tokens using authorisation code grant. ")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Log into SignHero portal, open the side menu and select "),_c('b',[_vm._v("Integrations")]),_vm._v(".")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenshotContainer"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"/api-document-images/createApp1.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("In the integrations list, select "),_c('b',[_vm._v("Add application")]),_vm._v(".")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenshotContainer"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"/api-document-images/createApp2.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenshotContainer"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"/api-document-images/createApp3.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenshotContainer"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"/api-document-images/createApp4.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("After creating an application you'll see it in the integrations list. The status of the app will be "),_c('b',[_vm._v("Inactive")]),_vm._v(".")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenshotContainer"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"/api-document-images/createApp5.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Inactive")]),_vm._v(" means that you haven't yet verified the ownership of app's domain. Select your app in the list to proceed.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" On the bottom of the application page you will find app's client ID and secret. This is the moment when you should copy the secret and safely store it somewhere. After refreshing the page, the secret won't be visible anymore. If you lost your secret, click on "),_c('b',[_vm._v("Change secret")]),_vm._v(" to generate a new one. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenshotContainer"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"/api-document-images/createApp6.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" In the "),_c('b',[_vm._v("Manage redirect URI")]),_vm._v(" section you should see your domain name with a warning sign, which means that your domain is not verified. Below the domain name text field you will see instructions on how to verify the domain. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenshotContainer"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"/api-document-images/createApp7.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" After you've successfully verified your domain, the waring will disappear. In the integrations list, your application will become "),_c('b',[_vm._v("Active")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screenshotContainer"},[_c('img',{staticClass:"img-fluid",attrs:{"src":"/api-document-images/createApp8.png"}})])
}]

export { render, staticRenderFns }