<template>
  <v-container fluid class="fill-height pa-0">
    <div class="content text-left ma-auto mr-0">
      <div id="section4" class="ClientCredentials sectionContainer">
        <h1 class="section-title">Authorisation grants - Authorisation code</h1>
        <hr class="line-break">
        <p>Use this grant when your application wants to obtain limited access to SignHero API on behalf of users.
          You'll need to create an OAuth2 application in your SignHero integration settings before getting started.</p>
      </div>
    </div>
  </v-container>
</template>

<script>
    export default {
        name : "AuthorisationCode"
    }
</script>

<style lang="sass" scoped>

</style>
