<template>
  <v-app>
    <v-app-bar class="subpages-header-api" app>
      <v-toolbar-title>
        <a :href="root" target="_blank">
          <img src="/signherologoW.svg" class="hidden-xs-only" height="50"/>
          <img src="/SHicon.svg" class="hidden-sm-and-up" height="50"/>
        </a>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn color="white" class="shio-language-button" :ripple="false" text @click="showLanguageSetting">
            <v-icon>
              mdi-earth
            </v-icon>
            <span class="hidden-sm-and-down">&nbsp;{{ $t( 'message.ThisLocale' ) }}</span>
          </v-btn>
        </template>
      </v-menu>
    </v-app-bar>
      <v-layout align-center justify-center>
        <div class="maxWidth-1100 ma-auto py-3">
          <v-card flat class="pa-2" >
            <div>
              <h2 class="text-center">{{ $t( "message.YouFoundApi" ) }}</h2>
            </div>
            <div class="pt-2">
              <div v-html="getStartedLinkText" v-if="!choose_language_setting"></div>
              <v-row v-if="choose_language_setting" class="fill-height" no-gutters>
                <v-col>
                  <div class="ma-auto mt-1 maxWidth-1100 pa-6">
                    <h1 class="shio-section-title text-left">{{ $t( 'message.Locales' ) }}</h1>
                    <v-radio-group v-model="locale" class="mx-3 mt-6">
                      <v-radio v-for="locale in locales" :key="locale" :label="$t( 'message.locale!' + locale )" :value="locale"></v-radio>
                    </v-radio-group>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <div class="text-left mt-2 mx-3">
                          <v-btn class="primary-btn choose-language-btn" @click="setLocale( locale )">{{ $t( 'signflow.Ok' ) }}</v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </div>
      </v-layout>
    <v-footer
            color="grey darken-4"
            class="white--text generic-footer-api"
            app
    >
      <v-row
              align="center"
              justify="center">
        <v-col cols="12" class="col-sm-4 col-lg-4 pt-5 pt-sm-3">
          <a :href="root + '/terms-of-use.html'" target="_blank">{{ $t( 'message.TermsOfUse' ) }}</a>
        </v-col>
        <v-col cols="12" class="col-sm-4 col-lg-4 py-0">
            <img class="footer-logo" src="/signherologoW.svg" height="50"/>
        </v-col>
        <v-col cols="12" class="col-sm-4 col-lg-4 pb-5 pb-sm-3">
          <a :href="root + '/privacy-policy.html'" target="_blank">{{ $t( 'message.PrivacyPolicy' ) }}</a>
        </v-col>
      </v-row>
    </v-footer>

  </v-app>
</template>

<script>
  import util from "../../util/util";
  const locales = [ "en", "fi", "sv", "pl", "de", "nl", "fr", "es", "th" ];
  export default {
      name : "ApiRootCard",
      data()
      {
          const root = process.env.VUE_APP_MAIN_DOMAIN_ADDRESS;
          const apiDocsLink = root + "/docs/api";
          const getStartedLinkText = eval( "`" + this.$t( "message.CheckApiDocumentation" ) + "`" );
          return {
              locales,
              root,
              getStartedLinkText,
              choose_language_setting : false,
              locale : util.getLocale(),
          }
      },
      methods : {
          setLocale( newLoc )
          {
              util.setLocale( newLoc );
          },
          showLanguageSetting()
          {
            this.locale = util.getLocale();
            this.choose_language_setting = !this.choose_language_setting;
          },
      },
      mounted()
      {
          document.documentElement.setAttribute( 'lang', util.getLocale() );
      }
  }
</script>

<style scoped lang="sass">
@import "../../styles/style"

.subpages-header-api
  background: black !important

.generic-footer-api
  width: 100%
  height: 70px
  position: sticky

@media (max-width: 599px)
  .generic-footer-api
    height: auto


</style>
