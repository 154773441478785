export default {
    "pdfsign": {
        "Error!Unexpected": "Se ha producido un error inesperado. Vuelve a intentarlo más tarde.",
        "SelectSigner": "Elegir firmante",
        "SignatureTimestampFormat": "Firmado el dd-mm_aaaa",
        "DeclinationPlaceHolder": "Motivos para rechazar (opcional)",
        "SignerGreetings": "Hola ",
        "WhoSigns": "Quién firma:",
        "Sign": "Firma",
        "WhatSigned": "Qué se ha firmado: <span class='sh-nowOrNeverText'>(haz clic en el documento para revisarlo: ¡es ahora o nunca!)</span>",
        "SignConfirmationMsg": "He revisado el o los documentos y tengo derecho legal a firmar.",
        "UsagePolicyInfoMsg": "Al utilizar SignHero, aceptas nuestras <a href='terms-of-use.html' target='_blank' style='color: #ff507a;'>Condiciones de uso</a> y <a href='privacy-policy.html' target='_blank' style='color: #ff507a;'>Política de privacidad</a>.",
        "Decline": "Rechazar",
        "Cancel": "Cancelar",
        "ThankUsingService": "¡Gracias de todas formas!",
        "FindMoreInformation": "Apuesta por la facilidad en <a class='elem-pink' href='https://www.signhero.io'>SignHero.io</a>",
        "SignEventCancelled": "El proceso de firma se ha cancelado",
        "CompleteProcessInfo": "No puedes firmar el o los documentos porque esta solicitud de firma se ha cerrado. Quizá hayas firmado ya estos documentos o la persona remitente los haya cancelado.",
        "DeclineToSignTitle": "Rechazar firmar",
        "SignedAndSent": "El o los documentos se han firmado correctamente.",
        "SorryUnexpectedError": "Lo sentimos: algo ha salido mal",
        "SessionNeedRefreshError": "La sesión necesita ser actualizada.  Por favor inténtalo de nuevo.",
        "ReasonExpiredSession": "Es posible que el enlace que has seguido esté roto o que la invitación se haya eliminado.",
        "InvalidLoginUrl": "Por favor, comprueba que la URL introducida coincide con el enlace de tu correo electrónico e inténtalo de nuevo.",
        "You": "tú"
    },
    "signedin": {
        "locale!fi": "Suomi",
        "locale!en": "English",
        "locale!pl": "Polski",
        "locale!de" : "Deutsch",
        "locale!sv" : "Svenska",
        "locale!es" : "Español",
        "locale!fr" : "Français",
        "locale!nl" : "Nederlands",
        "locale!th" : "ภาษาไทย",
        "Applications": "Aplicaciones",
        "SearchUsers": "Buscar usuarios",
        "UserDeleted" : "El/la usuario/a se ha deshabilitado y se eliminara para siempre transcurridos 30 días",
        "UserRestored" : "El/la usuario/a se ha restablecido",
        "UserAlreadyActive" : "El/la usuario/a ya se ha activado.",
        "OrganizationHasBeenDeleted": "Un/a administrador/a acaba de eliminar tu organización.\n¡Sentimos que te vayas y esperamos que vuelvas!",
        "SendReminder": "Enviar recordatorio de activación",
        "EmailReminderSuccess": "Se ha enviado un recordatorio por correo electrónico.",
        "AdminStatusChanged": "Usuario/a actualizado/a.",
        "DateTimeFormat": "dd.MM.yyyy, HH:mm:ss",
        "SessionType!password": "Contraseña",
        "SessionType!auth_code": "Aplicación",
        "Start": "Iniciar nuevo",
        "TheService": "Administración",
        "Save": "Guardar",
        "MyOrganization": "Mi organización",
        "OrganizationName": "Nombre de la organización",
        "ManageAccounts": "Administrar cuentas",
        "MyIntegrations": "Integraciones",
        "Statistics" : "Estadísticas",
        "isAdmin": "Administrador",
        "DeleteUser": "Eliminar cuenta de usuario",
        "RestoreAccount": "Restaurar cuenta de usuario",
        "Error!Unexpected": "Se ha producido un error inesperado. Vuelve a intentarlo más tarde.",
        "Error!InvitedUserExists": "El/la usuario/a ya se ha incorporado a esta organización o pertenece a otra. En este último caso, contacta con ${email}",
        "InviteUser": "Invitar",
        "UserInvited" : "Usuario/a invitado/a",
        "Create": "Crear",
        "ConfirmDeleteEverything": "¿Quieres realmente eliminar esta cuenta de organización (${groupTitle})?\n",
        "ConfirmCancelOngoingProcesses" : "Tu organización tiene ${processNum} procesos de firma en curso. También hay que cancelarlos antes de proceder a la eliminación de la cuenta de organización.\n "
                                          + "\n¿Quieres realmente cancelar todos los procesos en curso y eliminar esta cuenta de organización (${groupTitle})?\n"
                                          + "\nPara continuar, introduce el código:  ${rndCode}",
        "IncorrectConfirmationCode" : "Código de confirmación incorrecto.",
        "DownloadOrganizationDataMessage": "Descarga los datos de usuario y proceso de firma como archivo JSON.",
        "DownloadOrganizationDataLink": "Descargar datos de organización",
        "Subscription": "Suscripción",
        "InviteUserHeader": "Invitar a un/a nuevo/a usuario/a a tu organización",
        "AccountStatus" : "Estado de la cuenta:",
        "OwnAccount" : "Tu propia cuenta",
        "StatusActive" : "Activa, el/la usuario/a ha aceptado la invitación.",
        "StatusInactive" : "Inactiva, el/la usuario/a no ha aceptado aún la invitación.",
        "StatusDeleted" : "Marcada para borrado. La suscripción está inactiva y no se renovará para este usuario.",
        "AccountRights" : "Derechos:",
        "DownloadOrganizationData": "Descargar JSON",
        "DeleteOrganizationMessage": "Elimina esta organización, tu cuenta y todas las demás cuentas de administrador y de usuario",
        "DeleteEverything": "Eliminar todo",
        "OrganizationHasActiveProcesses" : "No se ha podido eliminar la organización porque tiene procesos de firma o solicitudes de fusión activos.",
        "MergeOrganizationMessage": "La organización puede solicitar la fusión con otra.",
        "RequestMergeDetails": "El/la administrador/a de la organización puede solicitar la fusión de la organización con otra. La solicitud será válida durante 24 horas.\n\n"
            + "Tras la aceptación de la solicitud, los procesos de firma, incluidos los documentos y los usuarios, se migrarán a la nueva organización, y la organización actual desaparecerá. "
            + "Los usuarios de la organización actual no tendrán derechos de administrador después de la fusión. El/la administrador/a de la nueva organización debe otorgar estos derechos. Si había "
            + "una suscripción activa, el periodo de suscripción restante se cancelará, y los usuarios fusionados se contarán en la suscripción de la nueva organización.",
        "MergeOrganizationDetails": "La organización ${groupName} ha solicitado la fusión de la cuenta de la organización "
            + "con tu cuenta de organización. La solicitud es válida durante 24 horas.\n\n"
            + "Tras la aceptación de la solicitud, los procesos de firma, incluidos los documentos y los usuarios, se migrarán a tu organización, "
            + "y la organización migrada desaparecerá. Los usuarios de la organización migrada no tendrán derechos de administrador después de la fusión. "
            + "El/la administrador/a de tu organización debe otorgar estos derechos. Si existía una suscripción activa, "
            + "los usuarios migrados se contarán como pertenecientes a la suscripción de tu organización.",
        "RequestMerge": "Solicitar fusión",
        "ConfirmMergeOrganisation": "¿Quieres realmente fusionar esta cuenta de organización? No se permite volver a dividir cuentas.",
        "EnterAdminEmail": "Introduce la dirección de correo electrónico de un/a administrador/a de otra organización:",
        "EnterPassword": "Para continuar, introduce tu contraseña:",
        "InvalidPassword" : "Tu contraseña no coincide. Por favor, inténtalo de nuevo.",
        "MergeRequestSent": "Solicitud de fusión de organización enviada",
        "MergeRequestCanceled": "Solicitud de fusión de organización cancelada",
        "MergeRequestRejected": "Solicitud de fusión de organización rechazada",
        "ReceivedMergeRequests": "Solicitudes de fusión recibidas",
        "SentMergeRequest": "Enviada la solicitud de fusión",
        "Recipient": "Destinatario",
        "Reject": "Rechazar",
        "Merge": "Fusionar",
        "OrganisationMerged": "Se inició la fusión.",
        "UserNotDifferentOrganizationAdmin": "El/la usuario/a no es administrador/a de otra diferente",
        "UserNotFound": "Lo sentimos: no encontramos ese/a usuario/a",
        "OrganizationHasActiveMergeRequest": "La organización ya tiene una solicitud de fusión activa",
        "InvalidMergeRequest": "No se ha encontrado la solicitud de fusión o ha caducado",
        "Complete": "Archivar",
        "Locales": "Idioma",
        "Sessions": "Sesiones",
        "LogOutAllDevicesMessage": "Si quieres, puedes cerrar la sesión en todos tus dispositivos.",
        "NoApplicationsMessage": "Todavía no has otorgado el acceso a ninguna aplicación.",
        "Domain" : "Dominio",
        "AccessGiven" : "Acceso dado en",
        "LastAccess" : "Último acceso",
        "RemoveAccess": "Eliminar acceso",
        "Ongoing": "En curso",
        "MyAccount": "Cuenta",
        "UserName": "Nombre",
        "Email": "Correo electrónico",
        "Logout": "Cerrar sesión",
        "SignOut": "Cerrar todas las sesiones",
        "Confirm": "Confirmar",
        "Cancel": "Cancelar",
        "Reset": "Restablecer",
        "Profile": "Mi perfil",
        "Organization": "Organización",
        "Device": "Dispositivo",
        "Update" : "Guardar",
        "Delete" : "Eliminar",
        "AcceptApiTerms" : "Al utilizar la API de SignHero, aceptas nuestras <a href='/terms/api' target='_blank'>Condiciones de la API</a>.",
        "CannotDeleteActiveFlows" : "Tu organización tiene procesos de firma en curso. Tienes que cancelarlos para poder proceder a la eliminación de la cuenta de organización.",
        "MySubscription" : "Mi suscripción",
        "FreeTrialLeft" : "Tiempo restante de la prueba gratuita: ",
        "Days" : "días.",
        "SubscriptionPeriod" : "Periodo de suscripción actual:",
        "PleaseActivateSubscription" : "Por favor, activa tu suscripción.",
        "Tab1" : "Información de pagos",
        "Tab2" : "Historial de facturación",
        "Tab3" : "Acerca de",
        "NoPaymentNotification" : "Nota: En este momento, no tienes ningún método de pago válido.",
        "AddCreditCard" : "Añade una tarjeta de crédito para la facturación mensual de SignHero",
        "CreditCardEmail" : "Correo electrónico",
        "BillingDate" : "Fecha de factura",
        "Amount" : "Importe",
        "UsersAmount" : "Usuarios",
        "Duration" : "Duración",
        "ReferNumber" : "Número de referencia",
        "Invoice" : "Factura",
        "Action" : "Acción",
        "LoadMore" : "Cargar más",
        "SalesChannel" : "Canal de ventas",
        "ResellerName" : "Distribuidor: ",
        "ResellerContact" : "Contacto: ",
        "ResellerMaxUsers" : "Usuarios máx.: ",
        "ResellerCurrentInvitedUsers" : "Usuarios invitados actualmente: ",
        "MaxResellerUsersWarning" : "Tu número de usuarios ha alcanzado tu límite máximo (${resellerMaxUsers}) de distribuidores.",
        "StartOfSubscription" : "Fecha de inicio de la suscripción:",
        "EstimatedBilling" : "Próxima factura el ${nextBillingDate} por un importe de ${nextBillingValue} €",
        "SubInactive1" : "La suscripción está inactiva",
        "SubInactive2" : ", no se renovará para este usuario eliminado",
        "isSubscriptionAdmin" : "Administración de la suscripción",
        "CardInfo" : "Información de tarjeta",
        "NameOnCard" : "Titular de la tarjeta",
        "SelectCountry" : "País o región",
        "SelectLocale" : "Selecciona un idioma",
        "BusinessAccount" : "Cuenta de empresa",
        "OnlyBusinessAccountsSupported" : "Solo se admiten cuentas de empresa para el país seleccionado.",
        "TaxIdExplanation" : "Por favor, elige tu región fiscal e introduce tu número de identificación fiscal (NIF) a efectos del IVA.",
        "SelectIdType" : "Selecciona el tipo de NIF",
        "TaxId" : "Número de identificación fiscal",
        "AddCardTooltip1" : "Por favor, indica primero datos de facturación válidos.",
        "AddCardTooltip2" : "Por favor, indica la información de una tarjeta de crédito en vigor. Todos los campos son obligatorios.",
        "AddCardTooltip3" : "Los datos de la tarjeta de crédito son válidos.",
        "BillingInformation" : "Información de facturación",
        "Edit" : "Editar",
        "EditBillingInformation" : "Editar la información de facturación.",
        "AddBillingTooltip" : "Por favor, completa todos los campos obligatorios y comprueba que el NIF coincida con la selección de país.",
        "Street" : "Dirección",
        "Street2" : "Dirección, línea 2",
        "City" : "Población",
        "State" : "Provincia/Estado",
        "Zip" : "Código postal",
        "SaveBillingInfo" : "Guardar datos de facturación",
        "SaveCard" : "Guardar datos de tarjeta",
        "Expires" : "Válido hasta",
        "DeleteCardOnly" : "Eliminar tarjeta de crédito",
        "DeletePaymentInfo" : "Borra los datos de facturación y de tarjeta de crédito.",
        "RemoveWholePaymentMethod" : "Borrar información de pagos",
        "PayOnline" : "Confirmar pago",
        "PaymentStatusPaid" : "Pagado",
        "PaymentStatusPending" : "Pendiente",
        "PaymentInfo" : "Acerca de",
        "PaymentExplanation1" : "SignHero cuesta 5 € al mes por usuario activo (el precio no incluye el impuesto sobre el valor añadido/impuesto sobre las ventas). Al precio se le sumará el impuesto sobre el valor añadido/impuesto sobre las ventas vigente en ese momento. Los usuarios inactivos no cuentan en tu factura. Si añades o eliminas usuarios durante un ciclo de facturación, incrementaremos o reduciremos automáticamente el importe de la factura siguiente basándonos en el periodo restante del ciclo de facturación en el momento del cambio. Al registrarte, te ofrecemos una prueba gratuita de 14 días, tras la cual se te pide que registres una tarjeta de crédito para realizar pagos mensuales recurrentes si quieres seguir utilizando el servicio. Cada mes, se hará un cargo automático en tu tarjeta de crédito en función del número de usuarios que hayas registrado en el servicio. Recibirás una factura de tu pago mensual en la dirección de correo electrónico que hayas especificado si registras tu tarjeta de crédito.",
        "PaymentExplanation2" : "Debes introducir el número de identificación fiscal de tu empresa, ya que SignHero recauda IVA de los clientes de conformidad con la legislación fiscal finlandesa y de la UE. En el caso de las empresas de la UE, es necesario indicar un número de identificación fiscal válido a efectos del IVA. Si tu país no aparece en la selección de países o regiones, puedes contactar con nosotros para conocer otros métodos de pago alternativos. Por favor, dinos al menos si no encontraste tu país y decidiste no utilizar el servicio por ese motivo. ¡Quizá lo añadamos en el futuro y te lo hagamos saber!",
        "PaymentExplanation3" : "Si no tienes la posibilidad de añadir una tarjeta de crédito para la facturación automática, contacta con SignHero en <a class=='elem-pink' href='mailto:hello@signhero.io'>hello@signhero.io</a>.",
        "PaymentExplanation4" : "¿Tienes algún problema con tu factura? ¿Tienes preguntas sobre cómo funciona todo? Por favor, contacta con nosotros en <a class=='elem-pink' href='mailto:hello@signhero.io'>hello@signhero.io</a> para cualquier pregunta.",
        "CountersPending" : "Se están calculando las estadísticas. Por favor, inténtalo de nuevo transcurridos unos minutos.",
        "WholeOrganisation" : "Toda la organización",
        "OrganisationHas" : "Tu organización tiene actualmente",
        "ActiveUsers" : "usuarios activos y",
        "InactiveUsers" : "usuarios inactivos.",
        "ChartDescriptionOrg" : "El siguiente gráfico muestra los procesos de firma creados y los documentos de toda la organización.",
        "ChartDescriptionUser" : "El siguiente gráfico muestra los procesos de firma creados por los usuarios seleccionados.",
        "SelectUsers" : "Seleccionar usuarios",
        "From" : "De",
        "To" : "A",
        "AmountOfDocuments" : "En el periodo seleccionado, el número de documentos nuevos es de",
        "AmountOfSignatureProcesses" : "y el número de nuevos procesos de firma es de",
        "Processes" : "Procesos",
        "Documents" : "Documentos",
        "Users" : "Usuarios",
        "UserActivity" : "Actividad de usuarios",
        "Signatures": "Firmas",
        "User" : "Usuario",
        "Inactive" : "Inactivo",
        "SignatureProcesses" : "Procesos de firma del periodo",
        "AllSignatureProcesses" : "Todos los procesos de firma",
        "FreeTrialAlert1" : "¡Tu organización tiene aún",
        "FreeTrialAlert2" : "días de prueba gratis!",
        "ContactAdmin" : "No hay ninguna suscripción activa. ¡Por favor, contacta con tu administrador!",
        "PaymentOverdueAlert" : "Pago vencido. Por favor, añade una tarjeta válida",
        "PaymentOverdueAlertIgnore" : "Si ya has añadido una tarjeta válida, no hagas caso a este mensaje",
        "PleaseAddValidCPayment" : "No hay ninguna suscripción activa. ¡Por favor, añade un método de pago válido!",
        "PleaseConfirmPayment" : "Se requiere autenticación de la tarjeta. ¡Por favor, consulta el \"Historial de facturación\"!",
        "PleaseConfirmPaymentMainPage" : "No se requiere ningún método de pago válido ni autenticación de la tarjeta. ¡Por favor, consulta \"Mi suscripción\"!",
        "ConfirmDeletePaymentMethod": "¿Quieres realmente eliminar los datos de facturación y de tarjeta de crédito?",
        "ConsentDialogTitle": "¿Quieres suscribirte a SignHero con pagos recurrentes?",
        "ConsentText1": "SignHero es la forma rápida y sencilla de firmar electrónicamente. Envía y firma documentos en PDF, haz un seguimiento de los procesos, envía recordatorios a los firmantes, consulta pistas de auditoría, trabaja en equipo y archiva los documentos firmados.",
        "ConsentText2": "SignHero cuesta 5 € + IVA al mes por usuario activo. Los usuarios inactivos no cuentan en tu factura. Si añades o eliminas usuarios durante un ciclo de facturación, incrementaremos o reduciremos automáticamente el importe de la factura siguiente basándonos en el periodo restante del ciclo de facturación en el momento del cambio. Al registrarte, te ofrecemos una prueba gratuita de 14 días, tras la cual se te pide que registres una tarjeta de crédito para realizar pagos mensuales recurrentes si quieres seguir utilizando el servicio. En la misma fecha de cada mes, se hará un cargo automático en tu tarjeta de crédito en función del número de usuarios que hayas registrado en el servicio.  Después de registrar tu tarjeta de crédito, tu suscripción estará vigente tan pronto como se realice un cargo correcto en la tarjeta y se mantendrá mientras no haya ningún problema de pago. En relación con tu suscripción a SignHero, recibirás estos correos electrónicos:",
        "ConsentText3": "Debes introducir el número de identificación fiscal de tu empresa, ya que SignHero recauda IVA de los clientes de conformidad con la legislación fiscal finlandesa y de la UE. En el caso de las empresas de la UE, es necesario indicar un número de identificación fiscal válido a efectos del IVA. Si tu país no aparece en la selección de países o regiones, puedes contactar con nosotros en hello@signhero.io para conocer otros métodos de pago alternativos. En tu mensaje, indícanos por favor si no has encontrado tu país y has decidido no utilizar el servicio por ese motivo. ¡Quizá lo añadamos en el futuro y te lo hagamos saber!",
        "ConsentText4": "Para cancelar la suscripción, puedes eliminar los datos de tu tarjeta de crédito y la suscripción actual se cancelará al final del periodo de suscripción. También puedes hacerlo pulsando el botón \"Cancelar suscripción\".",
        "ConsentEmailBullet1": "un recordatorio antes del final del periodo de prueba",
        "ConsentEmailBullet2": "una factura de tu pago mensual en la dirección de correo electrónico que hayas especificado",
        "ConsentEmailBullet3": "una notificación por correo electrónico 7 días antes de cada transacción",
        "ConsentCheckBoxLabel1": "He leído la información anterior",
        "ConsentCheckBoxLabel2": "Acepto suscribirme al servicio de pagos recurrentes de SignHero",
        "Agree": "Acepto",
        "CurrentPlan" : "Suscripción actual",
        "SignHeroSubscription" : "Suscripción a SignHero",
        "CancelPlan" : "Cancelar suscripción",
        "CreatePlan" : "Crear suscripción",
        "PriceInfo" : "5 € al mes por usuario activo",
        "TaxTooltip" : "Recuerda que el precio no incluye el impuesto sobre el valor añadido/el impuesto sobre las ventas.",
        "PleaseAddValidCard" : "Por favor, añade un método de pago válido",
        "CancelPlanConfirm" : "¿Quieres realmente cancelar la suscripción actual?\nPerderás el periodo de prueba o el tiempo restante de tu suscripción de pago. El tiempo restante no se reembolsa.",
        "CreatePlanConfirm" : "¿Quieres realmente crear una nueva suscripción?",
        "numbersDidNotMatch" : "Los códigos no coinciden. Por favor, inténtalo de nuevo.",
        "toProceedEnterCode" : "Para continuar, introduce el código: ",
        "subStatus!trialing" : "Fin del periodo de prueba ",
        "subStatus!active" : "Activo",
        "subStatus!canceled" : "Cancelado",
        "subStatus!incomplete" : "Incompleto",
        "subStatus!incomplete_expired" : "Caducado",
        "subStatus!past_due" : "Vencido",
        "ConfirmCreateSubNow" : "Puedes crear una nueva suscripción ahora o más adelante desde la página Mi suscripción.",
        "CreateSubscriptionNow" : "Crear ahora",
        "CreateSubscriptionLater" : "Más adelante",
        "Country!Australia" : "Australia",
        "Country!Austria" : "Austria",
        "Country!Belgium" : "Bélgica",
        "Country!Brazil" : "Brasil",
        "Country!Bulgaria" : "Bulgaria",
        "Country!Canada" : "Canadá",
        "Country!Chile" : "Chile",
        "Country!Croatia" : "Croacia",
        "Country!Cyprus" : "Chipre",
        "Country!CzechRepublic" : "República Checa",
        "Country!Denmark" : "Dinamarca",
        "Country!Estonia" : "Estonia",
        "Country!Finland" : "Finlandia",
        "Country!France" : "Francia",
        "Country!Germany" : "Alemania",
        "Country!Greece" : "Grecia",
        "Country!HongKong" : "Hong Kong",
        "Country!Hungary" : "Hungría",
        "Country!India" : "India",
        "Country!Indonesia" : "Indonesia",
        "Country!Ireland" : "Irlanda",
        "Country!Israel" : "Israel",
        "Country!Italy" : "Italia",
        "Country!Japan" : "Japón",
        "Country!Korea" : "Corea",
        "Country!Latvia" : "Letonia",
        "Country!Liechtenstein" : "Liechtenstein",
        "Country!Lithuania" : "Lituania",
        "Country!Luxembourg" : "Luxemburgo",
        "Country!Malaysia" : "Malasia",
        "Country!Malta" : "Malta",
        "Country!Mexico" : "México",
        "Country!Netherlands" : "Países Bajos",
        "Country!NewZealand" : "Nueva Zelanda",
        "Country!Norway" : "Noruega",
        "Country!Poland" : "Polonia",
        "Country!Portugal" : "Portugal",
        "Country!Romania" : "Rumanía",
        "Country!RussianFederation" : "Federación Rusa",
        "Country!SaudiArabia" : "Arabia Saudí",
        "Country!Singapore" : "Singapur",
        "Country!Slovakia" : "Eslovaquia",
        "Country!Slovenia" : "Eslovenia",
        "Country!SouthAfrica" : "Sudáfrica",
        "Country!Spain" : "España",
        "Country!Sweden" : "Suecia",
        "Country!Switzerland" : "Suiza",
        "Country!Taiwan" : "Taiwán",
        "Country!Thailand" : "Tailandia",
        "Country!UnitedArabEmirates" : "Emiratos Árabes Unidos",
        "Country!UnitedKingdom" : "Reino Unido",
        "Country!UnitedStates" : "Estados Unidos",
        "TaxType!au_abn" : "Número de empresa australiano (AU ABN)",
        "TaxType!au_arn" : "Número de referencia de la Oficina Fiscal Australiana",
        "TaxType!eu_vat" : "Número de IVA de Europa",
        "TaxType!br_cnpj" : "Número CNPJ de Brasil",
        "TaxType!br_cpf" : "Número CPF de Brasil",
        "TaxType!ca_bn" : "Número BN de Canadá",
        "TaxType!ca_gst_hst" : "Número GST/HST de Canadá",
        "TaxType!ca_pst_bc" : "Número PST de Canadá (Columbia Británica)",
        "TaxType!ca_pst_mb" : "Número PST de Canadá (Manitoba)",
        "TaxType!ca_pst_sk" : "Número PST de Canadá (Saskatchewan)",
        "TaxType!ca_qst" : "Número QST de Canadá (Québec)",
        "TaxType!cl_tin" : "Número TIN de Chile",
        "TaxType!hk_br" : "Número BR de Hong Kong",
        "TaxType!in_gst" : "Número GST de la India",
        "TaxType!id_npwp" : "Número NPWP de Indonesia",
        "TaxType!il_vat" : "Número de IVA de Israel",
        "TaxType!jp_cn" : "Número corporativo japonés",
        "TaxType!jp_rn" : "Número de registro de empresas extranjeras registradas en Japón",
        "TaxType!kr_brn" : "Número BRN de Corea",
        "TaxType!li_uid" : "Número UID de Liechtenstein",
        "TaxType!my_frp" : "Número FRP de Malasia",
        "TaxType!my_itn" : "Número ITN de Malasia",
        "TaxType!my_sst" : "Número SST de Malasia",
        "TaxType!mx_rfc" : "Número RFC de México",
        "TaxType!nz_gst" : "Número GST de Nueva Zelanda",
        "TaxType!no_vat" : "Número de IVA de Noruega",
        "TaxType!ru_inn" : "Número INN de Rusia",
        "TaxType!ru_kpp" : "Número KPP de Rusia",
        "TaxType!sa_vat" : "Número de IVA de Arabia Saudita",
        "TaxType!sg_gst" : "Número GST de Singapur",
        "TaxType!sg_uen" : "Número UEN de Singapur",
        "TaxType!za_vat" : "Número de IVA de Sudáfrica",
        "TaxType!es_cif" : "CIF de España",
        "TaxType!ch_vat" : "Número de IVA de Suiza",
        "TaxType!tw_vat" : "Número de IVA de Taiwán",
        "TaxType!th_vat" : "Número de IVA de Tailandia",
        "TaxType!ae_trn" : "Número TRN de Emiratos Árabes Unidos",
        "TaxType!gb_vat" : "Número de IVA del Reino Unido",
        "TaxType!us_ein" : "Número EIN de Estados Unidos"
    },
    "signflow": {
        "SendReminder": "Enviar recordatorio de activación",
        "SessionType!password": "Contraseña",
        "SessionType!auth_code": "Aplicación",
        "Start": "Iniciar nuevo",
        "Error!Unexpected": "Se ha producido un error inesperado. Vuelve a intentarlo más tarde.",
        "ButtonBack": "Atrás",
        "ButtonGoBack": "Volver",
        "ButtonNext": "Siguiente",
        "Create": "Crear",
        "Complete": "Archivar",
        "SearchSignFlows": "Buscar por firmante, título o documento",
        "AllFlows": "Todos",
        "PendingMeFlows": "Pendiente de mí",
        "PendingOthersFlows": "Pendiente de otros",
        "CanceledFlows": "Cancelado",
        "CompletedFlows": "Completado",
        "ShowGroupProcesses": "Todos los procesos de la organización",
        "CancellationReasons": "Motivo: ${cancellationReasons}",
        "SignedDocuments": "Documento(s):",
        "CreatedByTitle": "Creado por:",
        "CanceledByTitle": "Cancelado por:",
        "PendingSignaturesTitle": "Pendiente:",
        "PendingMeAlert": "<span class='elem-pink'>Tú</span> no has firmado.",
        "PendingOthersAlert": "Pendiente de la firma de <span class='elem-pink'>${count}</span> persona(s)",
        "SignerStatusTitle": "Estatus:",
        "ReminderHistory": "Historial de recordatorios",
        "SignerStatus1": "Pendiente. Invitación abierta por primera vez el ",
        "SignerStatus2": "Pendiente. Invitación jamás abierta.",
        "SignerStatus3": "Firmado el ",
        "SignerStatus4": "Pendiente del creador.",
        "SendReminders": "Recordar por correo electrónico",
        "NoProcessesFound": "No hay ningún proceso que coincida con tus filtros",
        "LastEmailReminder": "Recordatorio más reciente: <b>${timestamp}</b>",
        "NoRemindersYet": "Ningún recordatorio enviado",
        "EmailReminderSuccess": "Se han enviado recordatorios por correo electrónico.",
        "ReminderSentBy": "enviado por",
        "CancelProcessDialogTitle": "Cancelar proceso",
        "CancellationReasonsPlaceHolder": "Motivos de cancelación",
        "CancelProcess": "Cancelar proceso",
        "DeleteProcess": "Eliminar proceso",
        "RestartProcess": "Volver a empezar",
        "ConfirmDeleteProcess": "¿Quieres realmente eliminar el proceso?",
        "ResendDownloadInvitation": "Reenviar enlace de descarga",
        "DownloadInvitationEmailSuccess": "Correo electrónico enviado.",
        "DownloadInvitationEmailFail": "Envío fallido",
        "Me": "Yo",
        "SignFlowDocumentsHeading": "¿Qué se firma?",
        "FlowSettings": "Opciones",
        "FlowTitlePlaceholder": "Da un nombre al caso para archivarlo fácilmente",
        "Signers": "Firmantes *",
        "AddSigner": "Añadir más firmantes",
        "AddSignerGroup": "Añadir firmantes desde un archivo",
        "AddressBookChooseFile": "Elegir archivo",
        "AddressBookInfoHeader": "Cómo añadir firmantes desde un archivo",
        "AddressBookInfo": "Debe ser un archivo con extensión .txt/.csv, con cada firmante en su propia línea y comas entre los campos.",
        "AddressBookInfoExampleHeader": "Por ejemplo, un archivo llamado MiembrosConsejoAdmin.txt con el siguiente contenido:",
        "AddressBookInfoExampleContent": "Jaime Suárez, jaime.suarez@ejemplo.com<br>Marta Jaraba, marta.jaraba@ejemplo.com<br>Juan Quijano, juan.quijano@ejemplo.com<br>Lucía Tabardo, lucia.tabardo@ejemplo.com",
        "NotAnAddressBook": "El archivo no contenía nombres ni direcciones de destinatarios.",
        "CheckFileEncoding": "El archivo contiene caracteres no admitidos. Por favor, asegúrate de que la codificación del archivo sea la UTF-8.",
        "SignersName": "Nombre y apellidos",
        "SignersEmail": "Correo electrónico",
        "EmailSubject": "Título del correo electrónico:",
        "EmailMessage": "Mensaje:",
        "EditInvitation": "Editar invitación de firma",
        "ChooseInvitation": "Elegir plantilla de invitación",
        "UseDragAndDropSignature": "Definir posiciones de firma",
        "NoPrintedEmail": "Ocultar direcciones de correo electrónico",
        "StartNow": "Firmar ahora",
        "StartSigningNow": "Puedes firmar ahora o más tarde desde la pestaña En curso.",
        "RequestEmailSendSigners": "El correo electrónico de solicitud de firma se envía a los firmantes. ",
        "InformProgressNotifications": "Se te notificará por correo electrónico acerca del progreso.",
        "Later": "Más adelante",
        "Ok" : "Aceptar",
        "DefaultEmailSubject": "${$cc_user_info.name} te invita a firmar",
        "DefaultEmailMessage": "${$cc_user_info.name} de ${$cc_user_info.organization.display_name} (${$cc_user_info.email}) te ha invitado a firmar electrónicamente con SignHero.",
        "Signer": "Firmante",
        "Created": "Creado",
        "Canceled": "Cancelado",
        "Sign": "Firma",
        "Send": "Enviar",
        "GoToSign": "Ir a la firma",
        "Download": "Descargar",
        "SignedBy": "Firmado por:",
        "WhoIsSigning": "¿Quién firma?",
        "OnlyMe": "Solo yo",
        "OnlyOthers": "Solo otros",
        "MeAndOthers": "Yo y otros",
        "Email": "Correo electrónico",
        "Add": "Añadir",
        "numbersDidNotMatch": "Los códigos no coinciden. Por favor, inténtalo de nuevo.",
        "toProceedEnterCode": "Para continuar, introduce el código: ",
        "Confirm": "Confirmar",
        "Continue": "Continuar",
        "Cancel": "Cancelar",
        "Reset": "Restablecer",
        "selectFromDisk": "Local",
        "UploadFile": "Subir",
        "fileTooLarge": "El tamaño máximo de cada archivo es de ${maxSize}.",
        "allFilesTooLarge": "El tamaño máximo total de todos los archivos es de ${maxSize}. Ahora estás intentando subir ${uploadedSize}.",
        "fileNumberTooLarge": "Sube como máximo ${maxFileNumber} archivos.",
        "uploadError": "Lo sentimos: hay un problema con un archivo. Quizá el formato sea incorrecto o ya esté firmado.",
        "select": "Seleccionar",
        "OngoingProcess" : "Procesos en curso",
        "GoogleDrive": "Google Drive",
        "DownloadFailed": "Fallo al descargar archivos",
        "Error!FilesOutdated" : "Error de tiempo de espera de sesión. Por favor, sube los archivos y rellena los datos de nuevo si es necesario",
        "Error!DocumentError" : "Lo sentimos: la sesión ha caducado o hay algún problema con un archivo. Quizá el formato sea incorrecto. Por favor, sube los archivos y rellena los datos de nuevo si es necesario.",
        "OnlyPdf" : "Los tipos de archivo aceptados son PDF",
        "OnlyCancelledProcessAllowed": "Solo puedes reiniciar el proceso cancelado.",
        "CreateNewTags" : "Crear nueva etiqueta",
        "CreateNewTagsHint" : "Pulsa Intro para añadir una nueva etiqueta: máximo 20 caracteres",
        "ManageTags" : "Administrar etiquetas",
        "Tags" : "Etiquetas",
        "FilterByTag" : "Filtrar por etiqueta",
        "ErrorTagExists" : "¡La etiqueta ya existe!   ",
        "MyOrganization" : "Mi organización",
        "OtherOrganizations" : "Otras organizaciones",
        "OwnedBy" : "Propiedad de:",
        "SavedTemplates" : "Elegir plantilla de invitación",
        "CreateTemplateTitle" : "Guardar plantilla de invitación",
        "TemplateCreated" : "Plantilla de invitación guardada.",
        "DeleteTemplateTitle" : "Eliminar plantilla de invitación compartida",
        "DeleteTemplateConfirm" : "¿Quieres realmente eliminar la plantilla de invitación? Afectará a toda la organización.",
        "Update" : "Actualizar",
        "Edit" : "Editar",
        "Save" : "Guardar",
        "ExplainTemplate1" : "La organización no tiene plantillas de invitación guardadas. Las plantillas de invitación se pueden guardar después de editarlas.",
        "ExplainTemplate2" : "Puede guardar, ¡pero no tiene que hacerlo! ",
        "ExplainTemplate3" : "La invitación de firma actual se puede usar sin guardar.",
        "ExplainTemplate4" : "Puede guardar las plantillas de invitación de uso frecuente. Las plantillas creadas son visibles para toda la organización y cualquier persona de la organización puede editarlas o eliminarlas.\nLa invitación de firma actual se puede usar sin guardar.",
        "UpdateTemplate" : "La plantilla será visible, editable y eliminable para todos en la organización.\n\nNombre de plantilla: ",
        "DeleteTemplate" : "Plantilla eliminada.",
        "TemplateNoname" : "noname",
        "Error!TemplateNameExist": "La plantilla ya existe, ¿debería sobrescribirse?",
        "Error!TemplateNameEmpty": "¡Falta el nombre de la plantilla!",
        "ProcessesTabName1" : "Mis procesos",
        "ProcessesTabName2" : "Mis invitaciones de firma",
        "ShowOnly12MonthInfo" : "Mostrar procesos de los últimos 12 meses",
        "MaxSigners" : "El máximo de firmantes para un proceso es ",
        "MaxSigners1" : "Elimine ",
        "MaxSigners2" : " firmantes.",
        "MaxSigners3" : "No se pueden agregar más firmantes."
    },
    "message": {
        "ReportProblem" : "Informar de un problema",
        "IForgotMyPassword" : "Restablecer contraseña",
        "NavigationMenuButton": "Menú",
        "NavigationAccountButton": "Cuenta",
        "NavigationContactButton": "Contacto",
        "NavItemIntroduction": "Introducción",
        "NavItemChooseEasy": "Apuesta por la facilidad",
        "NavItemAccount": "Cuenta",
        "NavItemAPIsIntegrations": "API & Integraciones",
        "NavItemDoubtsWorries": "Dudas y preocupaciones",
        "NavItemThisIsUs": "Así somos",
        "NavItemContactUs": "Contactar con nosotros",
        "MainSlogan": "SignHero - ¡Más fácil imposible!",
        "SubSloganPart1": "Es toda una revolución frente a la complejidad, los procesos recargados",
        "SubSloganPart2": "y la forma antigua de hacer las cosas.",
        "GoToRegister": "Empieza la prueba gratuita",
        "TitleLazy": "Apuesta por la facilidad",
        "TextLazyLine1": "Las identificaciones bancarias, las plantillas y los garabatos son irrelevantes;",
        "TextLazyLine2": "representan procesos recargados que hacen perder tiempo y dinero.",
        "TextLazyLine3": "Los fans de SignHero quieren centrarse solo en lo importante, así que te ofrecemos lo esencial.",
        "MetricsTitle": "Promedio de métricas semanales:",
        "Metrics1" : "Más de 6000 personas utilizan SignHero",
        "Metrics2" : "Más de 2000 contratos firmados",
        "Metrics3" : "Más de 100 nuevas cuentas de equipo",
        "TitleAccount": "Cuenta",
        "TextAccount": "La cuenta de SignHero contiene todo lo que necesitas para firmar electrónicamente:",
        "Feature1": "Enviar y firmar archivos PDF",
        "Feature2": "Seguir los procesos",
        "Feature3": "Enviar recordatorios a los firmantes",
        "Feature4": "Acceder a la pista de auditoría",
        "Feature5": "Trabajar en equipo",
        "Feature6": "Archivar documentos",
        "UnlimitedSigners": "Firmantes e invitaciones ilimitados.",
        "EasyRegister": "Regístrate con tu nombre, correo electrónico y organización.",
        "Register": "Crear tu cuenta",
        "TitlePricing": "Precios",
        "MonthlyPrice": "5 € usuario/mes",
        "MonthlyBill": "(Se factura mensualmente. El precio no incluye el impuesto sobre el valor añadido/impuesto sobre las ventas. Al precio se le sumará el impuesto sobre el valor añadido/impuesto sobre las ventas vigente en ese momento).",
        "Benefits1": "Firmas electrónicas ilimitadas",
        "Benefits2": "Archivo para tus documentos",
        "Benefits3": "Cuenta de equipo para varios usuarios",
        "Benefits4": "Los firmantes no necesitan una cuenta",
        "ButtonFreeTrial": "Inicia una prueba de 14 días",
        "CreditCardInfo": "No se requiere ninguna tarjeta de crédito",
        "TitleAPI": "API & Integraciones",
        "IntegrateSignHero": "Integra nuestro botón de firma donde lo necesites.",
        "ToIntroBTN": "Introducción",
        "ToDocsBTN": "Documentos",
        "ToGmailBTN": "Gmail",
        "ToZapierBTN": "Zapier",
        "QuoteOlli": "\"La integración de la generación y firma de contratos electrónicos en un entorno de WordPress le ahorra decenas de horas a nuestros clientes.\"",
        "AuthorOlli": "Olli Nyqvist",
        "CompanyOlli": "CEO de WebAula",
        "TitleDoubts": "Dudas y preocupaciones",
        "DoubtsLegal": "¿Es legal la firma electrónica?",
        "TitleShortAnswer": "La respuesta corta:",
        "AnswerLegalParagraph1": "¡Por supuesto! Existe desde hace ya una década.",
        "AnswerLegalParagraph2": "Firmar un papel con un bolígrafo no es una forma especialmente segura de firmar. La legislación nos da la razón; consulta la información de",
        "eIDAS": "eIDAS",
        "ESIGN": "ESIGN",
        "And": "y",
        "Acts": "las leyes.",
        "TitleLongAnswer": "La respuesta larga:",
        "LegalLongAnswerParagraph1": "Ya sabes que firmar un papel no ofrece demasiadas garantías a la hora de plasmar un acuerdo.\nLas firmas manuscritas se pueden falsificar, pueden suscitar controversias e incluso se pueden perder. De hecho, si sigues\ngarabateando, básicamente no tienes nada garantizado.",
        "LegalLongAnswerParagraph2": "Además, la UE tiene en vigor legislación relativa a la libertad de contratación por la cual puedes\nelegir cómo se confirman tus contratos. Comprueba que tu empresa no esté sujeta a requisitos especiales (por ejemplo,\nlegislación de blanqueo de capitales) y luego firma electrónicamente a placer.",
        "LegalLongAnswerParagraph3": "La autenticación es como un seguro. Por ejemplo, ¿enseñaste algún documento de identidad cuando firmaste\ntu contrato de empleo actual? Algunos contratos son de bajo riesgo, por lo que solo requieren unas coberturas mínimas; en\neste caso, basta con la autenticación por correo electrónico. ",
        "LegalLongAnswerParagraph4": "SignHero se utiliza actualmente para: Contratos comerciales / Contratos de empleo / Acuerdos de confidencialidad / Actas de reuniones\n/ Contratos de alquiler",
        "LegalLongAnswerParagraph5": "Las firmas basadas en estándares de SignHero cumplen los requisitos de la Ley estadounidense de transacciones electrónicas uniformes (UETA), la Ley estadounidense de firmas electrónicas en el comercio mundial y nacional (ley ESIGN) y\n"
                                     + "de la firma electrónica de tipo eIDAS.",
        "DoubtsPrice": "¿Cómo funciona? ¿Solo 5 €?",
        "PriceShortAnswerParagraph1": "Puedes firmar y archivar tus documentos con unos pocos clics. Las invitaciones de firma se envían por correo electrónico, y la firma no requiere ninguna cuenta. Su precio es de 5 € por usuario al mes. Además, se factura automáticamente cada mes si introduces los datos de tu tarjeta de crédito. Puedes cancelarlo en cualquier momento, por lo que solo pagas de mes en mes.",
        "PriceLongAnswerParagraph1": "<span class='doubts-break1'>SignHero te permite firmar documentos PDF con ordenadores y dispositivos móviles, y se puede integrar en cualquier CRM, archivo digital u otro sistema de gestión documental con nuestra API pública. Incluso puedes firmar directamente en tu cuenta de Gmail a través del complemento SignHero sin salir de tu bandeja de entrada.</span>",
        "PriceLongAnswerParagraph2": "Puedes crear una cuenta sin ningún compromiso y disfrutar de una prueba gratuita de 14 días. No necesitamos ningún dato de la tarjeta de crédito cuando te registras. El periodo de prueba gratuito te ofrece todas las funciones para que puedas probarlo a fondo. Y no te preocupes: no te enviaremos spam ni te suscribimos a ningún boletín informativo.",
        "PriceLongAnswerParagraph3": "Su precio es de 5 € por usuario al mes. Como usuario/a de pago, puedes enviar solicitudes de firma, supervisar eventos de firma, almacenar tus documentos en el archivo de SignHero y trabajar en equipo con una cuenta de organización. Puedes añadir casi un número infinito de miembros del equipo a tu cuenta y cada nuevo usuario suma otros 5 € a tu plan de suscripción mensual.",
        "PriceReasons1": "En algún momento, SignHero necesitará sostenerse con recursos propios sin tener que recurrir para siempre a la ayuda de su atenta empresa matriz (Avaintec).",
        "PriceReasons2": "No podremos crecer y crear una mejor experiencia para nuestros cómodos usuarios a menos que consigamos fondos para cubrir nuestros gastos.",
        "PriceReasons3": "Todas las semanas nos plantean la misma pregunta: cómo explotamos sus datos y cómo les estamos engañando camuflados con tanta maestría detrás de un servicio gratuito. Pues bien: esa pregunta ya no vale. Estamos absolutamente seguros de que cuando empieces a pagar 5 € al mes, confiarás más en nosotros. ¿Quién no confiaría en un proyecto capitalista?",
        "DoubtsSaveTime": "¿Qué puedo hacer con todo el tiempo que ahorro?",
        "SaveTimeAnswer": "Tómate un café, sal a pescar o déjalo para otro día, trabaja un poco, llama a tu madre, presenta tu declaración de\nimpuestos, apunta tus horas de trabajo, recicla, salva el mundo, juega con el perro, juega con el perro de tu\namiga, aprende a tocar un instrumento, escribe un poema, haz una tarta, cómete un trozo de tarta, córtate\nel pelo, da volteretas por el césped, viaja por el mundo, lee un libro, diseña tu propio destino, funda\nuna religión, rompe un mito, autoempléate, ve a un evento de una nueva empresa, recauda para una ONG, fracasa rápido, échate\nuna siesta, regálate un descanso, vuelve a empezar.",
        "TitleThisIsUs": "Así somos",
        "LinkedIn": "SignHero en LinkedIn",
        "Youtube": "SignHero en Youtube",
        "History": "Historia",
        "CompanyName": "Avaintec",
        "CompanyAddress": "Kansakoulukuja 3, 00100 Helsinki",
        "CompanyWeb": "www.avaintec.com",
        "TitleMoreQuestion": "¿Alguna otra pregunta?",
        "SignHeroEmail": "hello@signhero.io",
        "ApiDocsLink": "Documentos de la API",
        "TermOfUseLink": "Condiciones de uso",
        "PrivacyPolicyLink": "Política de privacidad",
        "CopyrightYear": "© " + new Date().getFullYear() + " ",
        "CompanyOyName": "Avaintec Oy",
        "RegulationNotice": "La firma electrónica tiene el mismo estatus legal que la firma manuscrita en EE. UU. y la UE gracias a legislación como:",
        "ESignAct": "Ley ESIGN",
        "eIDASRegulations": "Normativa eIDAS",
        "Docs": "Documentación",
        "SignIn": "Iniciar sesión",
        "TermsOfUse": "Condiciones de uso",
        "ThisLocale": "ES",
        "WelcomeToSignHero": "Te damos la bienvenida a SignHero. Por favor, introduce tu correo electrónico y haz clic en \"Continuar\". Recibirás un correo electrónico con el que podrás establecer tu primera contraseña e iniciar sesión.",
        "ConfirmAccountInfo": "Por favor, mira en tu correo electrónico y sigue las instrucciones que encontrarás en él para establecer tu contraseña e iniciar sesión.",
        "MustBeEmail": "Introduce una dirección de correo electrónico.",
        "MustBeValidFullName": "Introduce un nombre y unos apellidos válidos.",
        "MustBeValidSignatureInvitation": "No se permiten los hipervínculos.",
        "MustBeUniqueEmail": "Este correo electrónico ya se ha añadido anteriormente.",
        "EnterValidCharacters": "Por favor, introduce solo caracteres admitidos.",
        "UserSignedUp": "¡Te damos la bienvenida a SignHero! Te hemos enviado un correo electrónico con instrucciones para activar tu cuenta. Si ya tienes una cuenta, puedes restablecer tu contraseña desde la pantalla \"Iniciar sesión\".",
        "locale!fi": "Suomi - FI",
        "locale!en": "English - EN",
        "locale!pl": "Polski - PL",
        "locale!de" : "Deutsch - DE",
        "locale!sv" : "Svenska - SV",
        "locale!es" : "Español - ES",
        "locale!fr" : "Français - FR",
        "locale!nl" : "Nederlands - NL",
        "locale!th" : "ภาษาไทย - TH",
        "SessionType!password": "Contraseña",
        "SessionType!auth_code": "Aplicación",
        "YouHaveBeenSignedOut": "Tu sesión se ha cerrado.",
        "Error!UserExists": "Ya existe un/a usuario/a que tiene el correo electrónico o el nombre de usuario que indicaste. Si has olvidado tu contraseña, haz clic en \"Iniciar sesión\" y cámbiala con el enlace \"Restablecer contraseña\".",
        "Save": "Guardar",
        "Error!Unexpected": "Se ha producido un error inesperado. Vuelve a intentarlo más tarde.",
        "Create": "Crear",
        "Complete": "Archivar",
        "Sign": "Firma",
        "Welcome": "Te damos la bienvenida",
        "CompleteProcessInfo": "No puedes firmar el o los documentos porque esta solicitud de firma se ha cerrado. Quizá hayas firmado ya estos documentos o la persona remitente los haya cancelado.",
        "SignInHere": "Iniciar sesión",
        "ReasonTitle": "CAUSAS PROBABLES",
        "Reason1": "Ya habías firmado este documento",
        "Reason2": "Uno de los firmantes ha rechazado firmar",
        "Reason3": "La invitación fue cancelada por la persona remitente",
        "Reason4": "La invitación caducó al haber transcurrido un año",
        "Reason5": "Has introducido una dirección errónea",
        "Reason6": "Has recibido un recordatorio y el enlace anterior ha caducado automáticamente",
        "SeeYouSoon" : "Hasta pronto",
        "HowToOpenExpiredSession" : "Para reabrir la sesión, haz clic en el enlace de tu correo electrónico.",
        "ClearCookieWarning": "Si utilizas un dispositivo público o compartido, recuerda borrar las cookies, el historial y la memoria caché.",
        "JustASec": "Un momentito",
        "SorryUnexpectedError": "Lo sentimos: ¡página no encontrada!",
        "InvalidLoginUrl": "Es posible que el enlace que has seguido esté roto o que la invitación se haya eliminado.",
        "InvitationInvalidatedReason": "Es posible que el enlace que has seguido esté roto o que la invitación se haya eliminado.",
        "You": "tú",
        "DownloadPageTitle": "Ver y descargar documentos firmados",
        "DownloadLinkInfo" : "Este enlace permanecerá activo durante un año desde la finalización del proceso de firma.",
        "Download": "Descargar",
        "OpenAuditTrail": "Ver la pista de auditoría",
        "ProcessNotFound": "No se ha encontrado el proceso de firma. Por favor, verifica la ID del proceso de firma.",
        "SignatureEvents": "Firmas",
        "ProcessStatus!COMPLETED": "Completado",
        "ProcessStatus!CANCELED": "Rechazado",
        "ProcessStatus!PENDING": "Pendiente",
        "ProcessStatus!UNKNOWN": "Desconocido",
        "SignatureProcessId": "ID",
        "SignatureProcess": "Proceso de firma",
        "SignatureFlowCreated": "Creado",
        "SignatureFlowStatus": "Estatus",
        "NumberOfDocuments": "Documentos",
        "SignedBy": "Firmado por:",
        "SignTime": "Hora de la firma",
        "AuthType": "Tipo de autenticación",
        "AuthMethod!verification_code": "Verificación por correo electrónico",
        "AuthMethod!access_token": "Cuenta de SignHero",
        "AuthMethod!strong_auth": "Fuerte",
        "AuthMethod!otp_token": "Tercero",
        "AuthMethod!otac": "Correo electrónico",
        "AuthMethod!signer_token": "Correo electrónico",
        "AuthMethod!on_site": "En persona",
        "PrivacyPolicy": "Política de privacidad",
        "EnterYourDetailsBelow": "Introduce tus datos a continuación.",
        "ThirdParty": "Tercero",
        "SignUpButtonLabel": "Continuar",
        "NameAndSurname": "Tu nombre y apellidos",
        "Email": "Correo electrónico",
        "Company": "Organización",
        "ConfirmAccount": "Continuar",
        "Confirm": "Confirmar",
        "ConfirmAccountTitle": "Establece la contraseña para tu cuenta de <span class='elem-pink'>SignHero</span>",
        "ResetPasswordTitle": "Restablece la contraseña de tu cuenta de <span class='elem-pink'>SignHero</span>",
        "ResetPassword": "Establecer nueva contraseña",
        "Reset": "Restablecer",
        "Organization": "Organización",
        "WelcomeMessage": "Inicia sesión en tu cuenta de <span class='elem-pink'>SignHero</span>",
        "WelcomeText": "Introduce tus datos a continuación.",
        "DontHaveSignHeroAccount": "¿No tienes aún una cuenta de <span class='elem-pink'>SignHero</span>?",
        "GetStarted": "Empieza aquí",
        "AlreadyHaveSignHeroAccount": "¿Ya tienes una cuenta de <span class='elem-pink'>SignHero</span>?",
        "CreateYourSignHeroAccount": "Crea tu cuenta de <span class='elem-pink'>SignHero</span>",
        "AcceptTermsOfUseAndPrivacyPolicy": "Al hacer clic en Continuar, aceptas las <a class='terms-disclaimer-link' href='terms-of-use.html' target='_blank'>Condiciones de uso</a> y la <a class='terms-disclaimer-link' href='privacy-policy.html' target='_blank'>Política de privacidad</a> de SignHero.",
        "YouFoundApi": "¡Has encontrado la API de SignHero!",
        "CheckApiDocumentation": "Para empezar, consulta nuestra más reciente <a class=='elem-pink' href='${apiDocsLink}'>documentación de la API</a>.",
        "AuditTrail" : "Pista de auditoría",
        "EnterProcessId" : "Introduce la ID del proceso de firma, que aparece en la casilla de firma de cada documento:",
        "SubmitID" : "Enviar",
        "NoProcessId" : "Por favor, introduce la ID del proceso.",
        "ConfirmError1" : "Por favor, solicita una nueva invitación o un enlace para restablecer la contraseña desde la página de ",
        "ConfirmError2" : " .",
        "SignHeroDotIo" : "SignHero.io",
        "ResetPWError" : "Este enlace puede haber caducado.<br>Ve a restablecer tu contraseña en <a href='/#/signin' class='elem-pink'>la página de acceso</a>.",
        "Error!tax_id_invalid" : "Identificación fiscal no válida.",
        "CreditCardSaved" : "Se ha añadido la tarjeta de crédito.",
        "BillingInformationSaved" : "Información de facturación guardada.",
        "dateFormat" : "MMM dd, aaaa",
        "timeFormat" : "dd.MM, HH:mm",
        "Error!card_declined" : "La tarjeta de crédito ha sido rechazada por la pasarela de pagos.",
        "Error!incorrect_cvc" : "La tarjeta de crédito ha sido rechazada por la pasarela de pagos.",
        "Error!expired_card" : "La tarjeta de crédito ha sido rechazada por la pasarela de pagos.",
        "Error!processing_error" : "La tarjeta de crédito ha sido rechazada por la pasarela de pagos.",
        "Contact" :  "Contacto",
        "name" : "Nombre",
        "email" : "Correo electrónico",
        "LoginEmail" : "Correo electrónico",
        "EnterYourEmail" : "Por favor, introduce tu correo electrónico:",
        "Password" : "Contraseña",
        "ForgotYourPassword" : "¿Has olvidado tu contraseña?",
        "RequestConfirmToken" : "Intenta confirmar de nuevo",
        "HelloThere" : "Hola,",
        "ChangePassword" : "Cambiar contraseña",
        "CurrentPassword" : "Contraseña actual",
        "NewPassword" : "Nueva contraseña",
        "RepeatPassword" : "Repite la contraseña",
        "PasswordSet" : "Contraseña establecida.",
        "MessageSent" : "Mensaje enviado. Contactaremos contigo si es necesario.",
        "SignHeroSentPasswordResetRequest" : "Solicitud de restablecimiento de contraseña enviada si eres usuario/a registrado/a. Comprueba tu correo electrónico.",
        "SignHeroSentConfirmTokenRequest" : "Confirmación de nueva cuenta solicitada. Comprueba tu correo electrónico.",
        "AuthCodeCreated" : "Te hemos enviado un enlace. Síguelo para iniciar sesión.",
        "SentPasswordResetRequest" : "Solicitud de restablecimiento de contraseña enviada si eres usuario/a registrado/a. ¡Mira tu correo electrónico!",
        "SentConfirmTokenRequest" : "Confirmación de nueva cuenta solicitada. ¡Mira tu correo electrónico!",
        "DefaultDialogTitle" : "",
        "Action!SetPassword" : "Establecer contraseña",
        "Action!ConfirmAccount" : "Confirmar cuenta",
        "TooltipSetPassword" : "Correo electrónico incorrecto o las contraseñas no coinciden.",
        "Action!EditProfile" : "Editar",
        "Action!SignOut" : "Cerrar sesión",
        "Action!SignIn" : "Iniciar sesión",
        "Update" : "Actualizar",
        "Delete" : "Eliminar",
        "Cancel" : "Cancelar",
        "Accept" : "Acepto",
        "Submit" : "Enviar",
        "Error!PasswordChangeFailedInvalidPassword" : "Tu antigua contraseña no coincide. Por favor, inténtalo de nuevo.",
        "Error!InvalidEmail" : "Introduce tu dirección de correo electrónico.",
        "Error!InvalidFullName" : "Introduce un nombre y unos apellidos válidos",
        "Error!InvalidPasswordReset" : "Tu token de restablecimiento de contraseña no es válido o ha caducado. Solicita otro con el enlace \"Restablecer contraseña\" e inténtalo de nuevo.",
        "Error!InvalidCredentials" : "Correo electrónico o contraseña no válidos.",
        "Error!InvalidScopes" : "Tus derechos de acceso no permiten el uso de esta aplicación. Por favor, contacta con la administración.",
        "Error!TemporarilyLockedOut" : "El inicio de sesión está temporalmente bloqueado en este dispositivo debido a varios fallos consecutivos. Si has olvidado tu contraseña, restablécela con el enlace \"Restablecer contraseña\". El inicio de sesión se desbloqueará en ${minutes} minutos.",
        "Error!TokenCheckFailed" : "Este enlace puede haber caducado.<br>Ve a restablecer tu contraseña en la página de acceso.",
        "Error!too_many_incorrect_email": "Demasiadas direcciones de correo electrónico incorrectas; por favor, inténtalo de nuevo mañana.",
        "PasswordRequirement" : "Tu contraseña debe tener al menos 10 caracteres y contener un número, una letra mayúscula, una letra minúscula y un carácter especial. También puedes utilizar un mínimo de 15 caracteres. No utilices más de dos caracteres idénticos consecutivos.",
        "EmailRequired" : "Se requiere un correo electrónico",
        "EmailMustValid" : "El correo electrónico debe ser válido",
        "PasswordMustValid" : "La contraseña debe cumplir los requisitos de complejidad",
        "RepeatPasswordMustSame" : "Las contraseñas deben ser idénticas",
        "TitleNews" : "Noticias",
        "ReadMoreLink" : "Más información",
        "OtherNewsBtn" : "Otras noticias",
        "NewsListBg1" : "Aquí podrás ver qué hemos estado haciendo",
        "NewsListBg2" : "Lee noticias sobre nuestros logros, comunicados y menciones en los medios de comunicación.",
        "Share" : "Compartir",
        "Locales": "Idioma",
    },
    "client": {
        "ClientSecretInfo": "Guarda el secreto de cliente en un lugar seguro. Puedes cambiarlo, pero no podrás volver a verlo después de terminar esta sesión.",
        "Error!InvalidDomain": "Nombre de dominio no válido.",
        "DomainValidationDownloadInfo1": "Para verificar tu dominio:",
        "DomainValidationDownloadInfo2": "Descarga el siguiente archivo:",
        "DomainValidationDownloadInfo3": "Sube el archivo al directorio raíz de tu dominio:",
        "DomainValidationDownloadInfo4": "Comprueba que el archivo sea accesible desde el enlace:",
        "DomainValidationDownloadInfo5": "Vuelve a su página y haz clic en \"Verificar dominio\".",
        "LastClientSecretReset": "Último cambio",
        "FailedDomainValidation": "La validación de tu dominio no se ha superado. ¿Has seguido las instrucciones?",
        "OAuthApplications": "Aplicaciones OAuth2",
        "Application": "Aplicación OAuth2",
        "IntegrateWithOAuth": "Integrar con OAuth2",
        "NewOAuthApplication": "Nueva aplicación OAuth2",
        "ApplicationName": "Nombre de la aplicación *",
        "AddOAuthApplication": "Añadir aplicación",
        "AddUri": "Añadir más URI",
        "CreationDate": "Fecha de creación",
        "CreatedBy": "Creado por",
        "AllowedGrantTypes": "Tipos de permisos permitidos",
        "OAuthRedirectURIs": "Redirigir los URI *",
        "OAuthClientsDomainURI": "Dominio",
        "OAuthClientsDomainTitle": "Dominio de aplicación *",
        "AppDescription": "Descripción de la aplicación",
        "VerifyDomain": "Verificar dominio",
        "DomainVerified": "El dominio se ha verificado.",
        "WhyValidateDomain": "Tienes que verificar la propiedad de tu dominio. La aplicación no puede utilizarse antes de superar la verificación del dominio. Consulta la <a class='paragraph-links' href='/docs/api' target='_blank'>documentación de la API</a> para obtener más detalles.",
        "ClientSecret": "Secreto de cliente",
        "ClientId": "ID de cliente",
        "ClientCredentials": "Credenciales de cliente",
        "VerifiedApp": "Activo",
        "UnverifiedApp": "Inactivo",
        "ResetClientSecret": "Cambiar el secreto",
        "ConfirmDeleteApiClient": "¿Quieres realmente eliminar esta aplicación?\nPara continuar, introduce el código: ${rndCode}",
        "ConfirmResetClientSecret": "¿Quieres realmente restablecer el secreto de cliente?\nPara continuar, introduce el código: ${rndCode}",
        "AppHasBeenDeleted": "Se ha eliminado la aplicación ${appName}.",
        "AppHasBeenCreated": "Se ha creado la aplicación ${appName}.",
        "YourChangesHaveBeenSaved": "Tus cambios se han guardado.",
        "ClientSecretChanged": "El secreto de cliente se ha cambiado.",
        "CheckApiDocs": "Debes verificar la titularidad de tu dominio para poder utilizar la aplicación. Consulta la documentación <a href='/docs/api' target='_blank'>de la API</a> para obtener más detalles."
    },
    "oauth2consent": {
        "AllowAccess": "Permitir el acceso",
        "OAuthAccessDescriptionHeader": "<span class='elem-pink'>${appName}</span> necesita tu autorización antes de que puedas usarla para:",
        "OAuthBasicPermissions": "  <li>Enviar nuevas y recordatorios invitaciones de firma</li>" +
                                 "  <li>Acceder, cancelar y eliminar las invitaciones de firma enviadas con ella</li>" +
                                 "  <li>Acceder a documentos firmados enviados para su firma con ella</li>",
        "OAuthSigningUrlPermissions": " <li>Ir a firmar documentos subidos con ella</li>",
        "OAuthProfilePermissions": " <li>Acceder a tu nombre, correo electrónico, nombre de empresa, nombre de organización, idioma de comunicación seleccionado " +
                                    "y ver si tienes derechos de administrador</li>",
        "OAuthAllDataPermissions": " <li>Acceder, cancelar y eliminar todas tus invitaciones de firma</li>" +
                                   " <li>Acceder a todos tus documentos</li>",
        "OAuthAccessWarning": "También significa que aceptas las condiciones de servicio y la política de privacidad de ${appName}.",
        "QuestionToAllowAccess": "Permitir a <span class='elem-pink'>${appName}</span> acceder a tu cuenta de SignHero " +
                                 "<span class='elem-bold'>${userEmail}</span>?<br><br>Siempre puedes cambiar " +
                                 "de opinión en la configuración de tu cuenta de SignHero.",
        "Cancel": "Cancelar",
        "Allow": "Permitir",
        "NotFound": "Lo sentimos: no se ha encontrado la aplicación",
        "OAuthResourceNotFound": "Lo sentimos: la aplicación que te ha dirigido hasta aquí tiene que arreglar el problema."
    }
}
