<template>
  <div class="reasons-explanation">
    <v-simple-table class="reasons-table">
      <template>
        <tbody v-if="!hideReasons">
        <th class="reasons-title hidden-xs-only">
          {{ $t( 'message.ReasonTitle' ) }}
        </th>
        <tr class="text-left hidden-sm-and-up font-weight-bold">{{ $t( 'message.ReasonTitle' ) }}</tr>
        <td class="text-left pt-3 pt-sm-0 pt-md-0 pt-lg-0 px-2">
          <p>{{ $t( 'message.Reason1' ) }}</p>
          <p>{{ $t( 'message.Reason2' ) }}</p>
          <p>{{ $t( 'message.Reason3' ) }}</p>
          <p>{{ $t( 'message.Reason4' ) }}</p>
          <p>{{ $t( 'message.Reason5' ) }}</p>
          <p>{{ $t( 'message.Reason6' ) }}</p>
        </td>
        </tbody>
      </template>
    </v-simple-table>
    <v-divider class="mt-5"></v-divider>
    <p class="pt-6 warning-text">{{ $t( 'message.ClearCookieWarning' ) }}</p>
  </div>
</template>

<script>
    export default {
        name : "not-found-card",
        props : {
            hideReasons : Boolean
        }
    }
</script>

<style lang="sass" scoped>
.v-data-table.reasons-table
  border: none

.reasons-title
  vertical-align: top

.reasons-explanation
  margin: 35px auto
  text-align: center
  max-width: 800px
  font-size: 16px

.warning-text
  color: red
  font-size: 16px
</style>
